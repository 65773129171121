import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoleEnum } from 'store/@types/user';

import { Button } from 'react-bootstrap';

import EditButtonIcon from 'assets/images/iconset/edit-icon-button.svg';
import useAuth from 'hooks/useAuth';

import CardDefault from 'components/CardDefault';
import DataTable, { DataTableRow } from 'components/DataTable';
import MissingInfoModal from 'components/modals/MissingInfoModal';
import MainLayout from 'components/root/MainLayout';
import { ContentWrapper } from 'components/root/MainLayout/styles';
import SplashScreen from 'components/root/SplashScreen/index';
import TertiaryButton from 'components/TertiaryButton';

import * as S from './styles';

const CustomerPage = () => {
  const navigate = useNavigate();
  const {
    authLoading,
    customers,
    userHasRequiredInfoFilled,
    currentRole,
    reload,
  } = useAuth();
  const canEdit = currentRole === RoleEnum.SOLO;
  const [showVerify, setShowVerify] = useState(false);

  const newTemplate = () => {
    const { hasAllRequiredInfo } = userHasRequiredInfoFilled();
    if (hasAllRequiredInfo) {
      navigate(`/customer/new`);
    } else {
      setShowVerify(true);
    }
  };

  useEffect(() => {
    reload({ reloadCustomers: true });
  }, []);

  return (
    <>
      {authLoading && <SplashScreen />}
      {showVerify && <MissingInfoModal close={() => setShowVerify(false)} />}
      <MainLayout pageTitle="Customers">
        <ContentWrapper>
          <S.GridContainer>
            <S.GridHeader>
              <S.SubHeaderContainer>
                <span>Customers</span>
                {canEdit && (
                  <Button
                    className="button-primary"
                    type="button"
                    onClick={newTemplate}
                  >
                    New customer
                  </Button>
                )}
              </S.SubHeaderContainer>
            </S.GridHeader>
            <S.GridTable>
              <CardDefault>
                <DataTable
                  headers={[
                    { value: 'Name' },
                    { value: 'Main address' },
                    { value: 'Email' },
                    { value: 'Phone number' },
                    { value: '' },
                  ]}
                  emptyStateLabel="You don’t have customers on your list.
                  Start by creating a new customer!"
                  rows={customers?.flatMap(
                    (customer, index): DataTableRow[] => {
                      const address = customer.headquartersAddress;
                      const street = address.street
                        ? `${address.street}, `
                        : '';
                      const city = address.city ? `${address.city}, ` : '';

                      const postalCode = address.postalCode ?? '';
                      const mainAddress = `${street}${city}${postalCode}`;
                      return [
                        {
                          index,
                          columns: [
                            {
                              index: 0,
                              value: customer.name,
                              width: '20%',
                            },
                            {
                              index: 1,
                              value: mainAddress,
                              width: '35%',
                            },
                            {
                              index: 2,
                              value: customer.mainContact.email,
                              width: '20%',
                            },
                            {
                              index: 3,
                              value: customer.mainContact.phone,
                              width: '20%',
                            },
                            {
                              index: 4,
                              value: (
                                <TertiaryButton
                                  icon={EditButtonIcon}
                                  handleAction={() => {
                                    navigate(`/customer/edit/${customer._id}`);
                                  }}
                                />
                              ),
                              width: '5%',
                            },
                          ],
                        },
                      ];
                    }
                  )}
                />
              </CardDefault>
            </S.GridTable>
          </S.GridContainer>
        </ContentWrapper>
      </MainLayout>
    </>
  );
};

export default CustomerPage;
