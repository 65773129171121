import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';

import { Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/esm/Button';

import { userLoginRegister } from 'api/user';
import LogoIcon from 'assets/images/symbol.svg';
import withHelmet from 'hooks/withHelmet';
import { ApiError } from 'services/api';
import { defaultToast } from 'utils/toast';

import { TextField } from 'components/Form';
import LinkButton from 'components/LinkButton';
import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';

import * as S from '../styles';
import { defaultValues, schema } from './yupSchema';

interface UserRegisterData {
  email: string;
  password: string;
  passwordConfirm: string;
}

function Registration() {
  const [isLoading, setIsLoading] = useState(false);
  const [successOperation, setSuccessOperation] = useState(false);

  const navigate = useNavigate();
  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const invitedEmail = query.get('email');

  const [userData, setUserData] = useState({
    email: invitedEmail ?? '',
    password: '',
    passwordConfirm: '',
  } as UserRegisterData);

  useEffect(() => {
    if (invitedEmail) {
      setUserData({
        ...userData,
        email: invitedEmail,
      });
      setSuccessOperation(true);
    }
  }, [invitedEmail]);

  // React hook form instance
  const methods = useForm<UserRegisterData>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const onSubmit = async (payload: UserRegisterData) => {
    try {
      const userRegisterResponse = await userLoginRegister(payload);
      if (userRegisterResponse._id) {
        setUserData(payload);
        setSuccessOperation(true);
      }

      setIsLoading(false);
    } catch (err) {
      if (String((err as ApiError)?.statusCode)) {
        defaultToast('E', 'This email is already in use.');
      } else {
        defaultToast('E');
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <SplashScreen />}
      <NoAuthBasePage>
        {!successOperation && (
          <S.PublicContainerCol style={{ minHeight: 661 }}>
            <img src={LogoIcon} alt="logo" width={34} height={34} />
            <S.PublicTitle>Create your Fenix account</S.PublicTitle>
            <S.PublicSubTitle>
              Password must be at least 8 characters long and include lowercase
              and uppercase letters, a number, and a special character
            </S.PublicSubTitle>

            <FormProvider {...methods}>
              <Form
                id="user-register"
                onSubmit={methods.handleSubmit((data) => {
                  setIsLoading(true);
                  onSubmit(data);
                })}
              >
                <Row>
                  <TextField xs={12} fieldId="email" label="Email" />
                  <TextField
                    xs={12}
                    type="password"
                    fieldId="password"
                    label="Password"
                  />
                  <TextField
                    xs={12}
                    type="password"
                    fieldId="passwordConfirm"
                    label="Confirm password"
                  />
                </Row>
                <Button
                  style={{ width: '100%' }}
                  className="button-primary"
                  type="submit"
                >
                  Create account
                </Button>
                <hr style={{ background: '#B4CAE1' }} />
              </Form>
            </FormProvider>
            <S.PublicContainerRow>
              Already have an account?&nbsp;
              <LinkButton
                label="Login now"
                style={{ padding: 0, height: 'auto' }}
                handleAction={() => {
                  navigate('/login');
                }}
              />
            </S.PublicContainerRow>
            <S.PublicContainerRow style={{ marginTop: 16 }}>
              Need help?&nbsp;
              <LinkButton
                label="Contact us"
                style={{ padding: 0, height: 'auto' }}
                handleAction={() => {
                  navigate('/contact');
                }}
              />
            </S.PublicContainerRow>
          </S.PublicContainerCol>
        )}

        {successOperation && (
          <S.PublicContainerCol style={{ textAlign: 'center' }}>
            <img src={LogoIcon} alt="logo" width={34} height={34} />
            <S.PublicTitle>Verify your account</S.PublicTitle>

            <S.PublicSubTitle>
              <span>We have sent an email to &nbsp;</span>
              <strong>{methods.getValues('email')}</strong>
              <span> with instructions to verify your identity.</span>
            </S.PublicSubTitle>

            <S.PublicSubTitle>
              Check your inbox and follow the link to complete the process. If
              you do not see the email in your inbox, check your spam or junk
              folder.
            </S.PublicSubTitle>

            <Button
              style={{ width: '100%', marginTop: 16 }}
              className="button-primary"
              onClick={() => {
                navigate('/login');
              }}
            >
              Go to login
            </Button>
            <hr style={{ background: '#B4CAE1' }} />
            <S.PublicContainerRow>
              Need help?&nbsp;
              <LinkButton
                label="Contact us"
                style={{ padding: 0, height: 'auto' }}
                handleAction={() => {
                  navigate('/contact');
                }}
              />
            </S.PublicContainerRow>
          </S.PublicContainerCol>
        )}
      </NoAuthBasePage>
    </>
  );
}

export default withHelmet(Registration);
