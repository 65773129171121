import styled from 'styled-components';

import { TableStyles } from 'interfaces/Circuits';

export const FixedTableHeaders = styled.div<{ icon: string }>`
  display: flex;
  position: sticky;
  left: 0;
  margin-top: -1px;
  white-space: nowrap;
  background: #ffffff;
  z-index: 1;
  .arrow-icon {
    background: #f6f6f6 url(${({ icon }) => icon}) no-repeat right 15px center;
  }
`;

export const TableHeaders = styled.div`
  display: flex;
  top: auto;
  margin-top: -1px;
  white-space: nowrap;
`;

export const TableHeaderColumn = styled.div<TableStyles>`
  white-space: nowrap;
  height: 29px;
  width: ${({ column }) => column.width};
  background-color: #f6f6f6;
  border: 1px solid #ffffff;
  border-radius: 4px;
  color: #5488be;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  padding: 6px;
`;
