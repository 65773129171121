import React, { FC } from 'react';

import * as S from './styles';

export type TabItemProps = {
  selectedButton: boolean;
  onClick: () => Promise<void> | void;
  label?: string;
};

const TabItem: FC<TabItemProps> = ({ selectedButton, onClick, label }) => {
  return (
    <S.TabItemButton
      type={'button'}
      selectedButton={selectedButton}
      onClick={onClick}
    >
      <span>{label}</span>
      {selectedButton && <S.SelectedDecorator></S.SelectedDecorator>}
    </S.TabItemButton>
  );
};

export default TabItem;
