import styled from 'styled-components';

type MenuTabItemButtonProps = {
  selectedButton?: boolean;
  errorButton?: boolean;
};

export const MenuNavItemButton = styled.button<MenuTabItemButtonProps>`
  display: flex;
  width: 241px;
  height: 44px;
  padding-inline: 14px;
  margin-top: 8px;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;

  border: 0;
  border-radius: 15px;
  background: #fff;

  color: #c2c6d7;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  ${({ selectedButton }) => {
    if (selectedButton) {
      return `
      color:  #5488BE;
      border-radius: 15px;
      background:  #F3F4F8;
      `;
    }
  }}

  box-shadow: ${({ errorButton }) =>
    (errorButton && '0 0 0 1px #FF6F70') || '0 0 0 2px transparent'};
`;
