import styled from 'styled-components';

interface TableTHProps {
  columnSize?: string;
  lastColumn: boolean;
}

export const TableCertificateTableHeader = styled.div`
  background-color: #2b3d4f;
  border-radius: 12px 12px 0 0;
  min-height: 50px;
  display: flex;
  align-items: center;
`;

export const TableHeaderText = styled.span`
  width: 100%;
  display: flex;
  height: 100%;
`;

export const TableHeaderDivider = styled.div`
  min-width: 2px;
  background-color: white;
  margin-inline: 1px;
  margin-block: 10px;
`;

export const TableTH = styled.div<TableTHProps>`
  padding-block: 12px;
  display: flex;
  align-items: center;
  color: white;
  font-size: 17px;
  font-weight: 600;
  width: 100%;
  min-width: ${({ columnSize }) => columnSize ?? 'unset'};
  max-width: ${({ columnSize }) => columnSize ?? 'unset'};
  justify-content: ${({ columnSize }) =>
    columnSize ? 'center' : 'flex-start'};
  text-align: ${({ columnSize }) => (columnSize ? 'center' : 'flex-start')};
  margin-left: ${({ columnSize }) => {
    return columnSize ? '0' : '10px';
  }};
`;

export const TableBody = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-bottom: 40px;
  padding-left: 12px;
`;

export const TableRow = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 20px;
  gap: 5px;
`;

interface TableTDProps {
  columnSize?: string;
}

export const TableTD = styled.div<TableTDProps>`
  width: 100%;
  height: 100%;
  min-width: ${({ columnSize }) => columnSize ?? 'unset'};
  max-width: ${({ columnSize }) => columnSize ?? 'unset'};
  text-align: ${({ columnSize }) => (columnSize ? 'center' : 'left')};
`;

interface TableTDSubHeaderProps {
  columnSize?: string;
  hasSize?: string;
  firstColumn: boolean;
}

export const TableTDSubHeader = styled.div<TableTDSubHeaderProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: ${({ hasSize }) =>
    'calc(100% - ' + (((hasSize && +hasSize) || 0) + 15) + 'px)'};
  height: 100%;
  min-height: 50px;
  margin-top: 20px;
  min-width: ${({ columnSize }) => columnSize ?? 'unset'};
  max-width: ${({ columnSize }) => columnSize ?? 'unset'};
  margin-left: ${({ hasSize }) => (hasSize ? +hasSize + 'px' : 'unset')};
  text-align: ${({ columnSize }) => (columnSize ? 'center' : 'left')};
  position: ${({ firstColumn }) => (firstColumn ? 'relative' : 'absolute')};
  border-radius: ${({ hasSize }) =>
    hasSize ? '12px 0 0 12px' : '0 12px 12px 0'};
`;

interface TableTDSubHeaderSpanProps {
  roundedRight?: boolean;
  roundedLeft?: boolean;
  columnSize?: string;
  justifyContent: string;
}

export const TableTDSubHeaderSpan = styled.div<TableTDSubHeaderSpanProps>`
  background: #6663;
  min-width: ${({ columnSize }) => columnSize ?? 'unset'};
  max-width: ${({ columnSize }) => columnSize ?? 'unset'};
  justify-content: ${({ justifyContent }) => justifyContent};
  text-align: ${({ justifyContent }) => justifyContent};
  border-radius: ${({ roundedLeft, roundedRight }) => {
    if (roundedLeft) {
      return '12px 0 0 12px';
    }
    if (roundedRight) {
      return '0 12px 12px 0';
    }
    return '0';
  }};
`;

export const FixedTableAuxLabel = styled.span`
  color: #272727;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  margin-top: 10px;
  white-space: pre-line;
`;
