import styled from 'styled-components';

export const SettingsHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  width: 100%;

  > span {
    color: #272727;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap;
  }
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  gap: 18px;
  width: 100%;
  height: 147px;
  flex-shrink: 0;
  border-radius: 11px;
  background: #f9fbfc;

  > span {
    color: #8087a4;
    text-align: center;
    white-space: nowrap;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const SettingsContainer = styled.div`
  width: 100%;
  max-width: 1110px;
`;
