import { useEffect, useMemo, useState } from 'react';
import { RoleEnum } from 'store/@types/user';

import { CertificateState } from 'enum/certificateDataState.enum';
import useAuth from 'hooks/useAuth';
import { CertData } from 'interfaces/CertificateData';
import api from 'services/api';

import CardDefault from 'components/CardDefault';
import DropdownSelect, {
  DropdownSelectOption,
} from 'components/DropdownSelect';
import MainLayout from 'components/root/MainLayout';
import { ContentWrapper } from 'components/root/MainLayout/styles';
import SplashScreen from 'components/root/SplashScreen/index';
import Tab from 'components/Tab';
import TabItem from 'components/Tab/TabItem';

import IssuedCertificatesTable from './IssuedCertificatesTable/IssuedCertificatesTable';
import NonIssuedCertificatesTable from './NonIssuedCertificatesTable/NonIssuedCertificatesTable';
import * as S from './styles';

interface ICertificatesStates {
  state: CertificateState[];
  name: string;
}

const certificatesStates: Array<ICertificatesStates> = [
  { state: [CertificateState.DRAFT], name: 'Draft' },
  {
    state: [CertificateState.IN_REVIEW, CertificateState.PENDING_REVIEW],
    name: 'Reviewing',
  },
  { state: [CertificateState.REVIEWED], name: 'Pending corrections' },
  { state: [CertificateState.ISSUED], name: 'Issued' },
];

function Certificates() {
  const [isLoading, setIsLoading] = useState(false);
  const [customerId, setCustomerId] = useState('AllId');
  const [issuedCerts, setIssuedCerts] = useState<CertData[]>([]);
  const [certificates, setCertificates] = useState<CertData[]>([]);
  const [selectedPage, setSelectedPage] = useState<ICertificatesStates>({
    state: [CertificateState.DRAFT],
    name: 'Draft',
  });
  const { customers, currentRole } = useAuth();
  const canShowCustomers = [RoleEnum.SOLO, RoleEnum.LEAD].includes(currentRole);
  const canShowIssueds = [RoleEnum.SOLO, RoleEnum.LEAD].includes(currentRole);

  const customersOptions = useMemo(() => {
    return [
      { value: 'AllId', label: 'All' },
      ...(customers?.map(
        (customer): DropdownSelectOption => ({
          value: customer._id ?? customer.name,
          label: customer.name,
        })
      ) ?? []),
    ];
  }, [customers]);

  const getCertificateParams = () => {
    return customerId === 'AllId' ? '' : `?customer=${customerId}`;
  };

  useEffect(() => {
    const fetchData = () => {
      api
        .get(`/certificate-data${getCertificateParams()}`)
        .then(({ data }) => {
          data.reverse();
          setCertificates(data);
        })
        .finally(() => setIsLoading(false));
    };
    setIsLoading(true);
    fetchData();
  }, [customerId]);

  useEffect(() => {
    const fetchData = () => {
      api
        .get(`/issued-certificate-data${getCertificateParams()}`)
        .then(({ data }) => {
          data.reverse();
          setIssuedCerts(data);
        })
        .finally(() => setIsLoading(false));
    };

    if (canShowIssueds) {
      setIsLoading(true);
      fetchData();
    }
  }, [customerId]);

  return (
    <>
      {isLoading && <SplashScreen />}
      <MainLayout pageTitle="Certificates">
        <ContentWrapper>
          <S.GridContainer>
            <S.GridHeader>
              <S.SubHeaderContainer>
                <span>Certificates</span>
                {canShowCustomers && (
                  <DropdownSelect
                    options={customersOptions}
                    labelPrefix="Customer"
                    width={'242px'}
                    handleAction={function (option: string): void {
                      setCustomerId(option);
                    }}
                  />
                )}
              </S.SubHeaderContainer>
            </S.GridHeader>
            <S.GridTable>
              <CardDefault styles={{ paddingTop: 20 }}>
                <Tab>
                  {certificatesStates
                    .filter((item) => {
                      if (currentRole === RoleEnum.SOLO) {
                        return (
                          item.state.includes(CertificateState.DRAFT) ||
                          item.state.includes(CertificateState.ISSUED)
                        );
                      }
                      return true;
                    })
                    .map((item) => (
                      <TabItem
                        key={item.name + 'MenuTabItem'}
                        label={item.name}
                        selectedButton={item.state.includes(
                          selectedPage.state[0]
                        )}
                        onClick={() =>
                          setSelectedPage({
                            state: item.state,
                            name: item.name,
                          })
                        }
                      />
                    ))}
                </Tab>
                {selectedPage.state.includes(CertificateState.ISSUED) ? (
                  <IssuedCertificatesTable certificates={issuedCerts} />
                ) : (
                  <NonIssuedCertificatesTable certificates={certificates} />
                )}
              </CardDefault>
            </S.GridTable>
          </S.GridContainer>
        </ContentWrapper>
      </MainLayout>
    </>
  );
}

export default Certificates;
