import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'react-bootstrap';

import LogoIcon from 'assets/images/symbol.svg';
import withHelmet from 'hooks/withHelmet';
import { ApiError } from 'services/api';
import { defaultToast } from 'utils/toast';

import LinkButton from 'components/LinkButton';
import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';
import SecondaryButton from 'components/SecondaryButton';

import * as S from '../styles';
import { ReduxProps } from '.';

const UnverifiedPage: FC<ReduxProps> = ({
  logout,
  user: reduxUser,
  resendVerifyEmail,
}) => {
  const navigate = useNavigate();
  const [successOperation, setSuccessOperation] = useState(false);
  const { token, usermail } = useParams();
  const { loading } = reduxUser;

  const onSubmit = async () => {
    if (token) {
      resendVerifyEmail(token, (success?: boolean, error?: ApiError) => {
        if (success) {
          setSuccessOperation(true);
          defaultToast('S', 'The email was sent successfully');
        } else if (error) {
          defaultToast('E');
        }
      });
    }
  };

  return (
    <>
      {loading && <SplashScreen />}
      <NoAuthBasePage>
        {!successOperation && (
          <S.PublicContainerCol style={{ minHeight: 661 }}>
            <img src={LogoIcon} alt="logo" width={34} height={34} />
            <S.PublicTitle>Complete your registration</S.PublicTitle>
            <S.PublicSubTitle>
              It seems you haven&apos;t verified your Fenix account yet. To
              complete your registration and start using all the features Fenix
              has to offer, please verify your email address.
            </S.PublicSubTitle>
            <S.PublicSubTitle>
              To receive a new verification link, press the button below:
            </S.PublicSubTitle>
            <Button
              style={{ width: '100%', marginTop: 32 }}
              className="button-primary"
              onClick={() => {
                onSubmit();
              }}
            >
              Resend link
            </Button>
            <hr style={{ background: '#B4CAE1' }} />
            <SecondaryButton
              label="Go to login"
              style={{ width: '100%' }}
              center
              handleAction={() => {
                logout(true);
              }}
            />
            <S.PublicContainerRow style={{ marginTop: 16 }}>
              Need help?&nbsp;
              <LinkButton
                label="Contact us"
                style={{ padding: 0, height: 'auto' }}
                handleAction={() => {
                  navigate('/contact');
                }}
              />
            </S.PublicContainerRow>
          </S.PublicContainerCol>
        )}
        {successOperation && (
          <S.PublicContainerCol style={{ textAlign: 'center' }}>
            <img src={LogoIcon} alt="logo" width={34} height={34} />
            <S.PublicTitle>Verify your account</S.PublicTitle>

            <S.PublicSubTitle>
              <span>We have sent an email to &nbsp;</span>
              <strong>{usermail}</strong>
              <span> with instructions to verify your identity.</span>
            </S.PublicSubTitle>

            <S.PublicSubTitle>
              Check your inbox and follow the link to complete the process. If
              you do not see the email in your inbox, check your spam or junk
              folder.
            </S.PublicSubTitle>

            <Button
              style={{ width: '100%', marginTop: 16 }}
              className="button-primary"
              onClick={() => {
                logout(true);
              }}
            >
              Go to login
            </Button>
            <hr style={{ background: '#B4CAE1' }} />
            <S.PublicContainerRow>
              Need help?&nbsp;
              <LinkButton
                label="Contact us"
                style={{ padding: 0, height: 'auto' }}
                handleAction={() => {
                  navigate('/contact');
                }}
              />
            </S.PublicContainerRow>
          </S.PublicContainerCol>
        )}
      </NoAuthBasePage>
    </>
  );
};

export default withHelmet(UnverifiedPage);
