import styled from 'styled-components';

export const RangeContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: center;
  text-align: center;
`;

export const RangerColumn = styled.div`
  padding: 0 1.3rem 0 1.3rem;
`;

export const RangeButton = styled.button<{ icon: string }>`
  cursor: pointer;
  margin: 0 auto;
  border: 0;
  width: 26px;
  height: 26px;
  text-align: center;
  vertical-align: middle;
  background: url(${(props) => props.icon});
  background-repeat: no-repeat;
`;

export const RangeNumber = styled.div`
  color: var(--color-primary);
  font-size: 3rem;
  font-weight: normal;
  text-align: center;
`;
