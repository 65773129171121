import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoleEnum } from 'store/@types/user';

import Modal from 'react-bootstrap/Modal';

import searchIcon from 'assets/images/iconset/input_search.svg';
import addCustomer from 'assets/images/iconset/signature_add.svg';
import useAuth from 'hooks/useAuth';

import SquareCard from 'components/Carousel/Cards/SquareCard';

import './styles.css';

interface ModalCustomerProps {
  setOpenModal: (data: boolean) => void;
  handleSave: (customerId: string, branchAddressesId?: string) => void;
  onDashboard?: string;
}

const ModalCustomer: FC<ModalCustomerProps> = ({
  handleSave,
  setOpenModal,
  onDashboard,
}) => {
  const { customers, currentRole } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const canAddCustomer = [
    RoleEnum.SOLO,
    RoleEnum.ADMIN,
    RoleEnum.QS,
    RoleEnum.MANAGER,
  ].includes(currentRole);

  const navigate = useNavigate();

  return (
    <Modal
      show
      className="container-customer"
      key="modal-sig"
      backdrop="static"
    >
      <Modal.Header className="customer-header">
        <Modal.Title className="customer-head-title">
          Select customer
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ width: '600px' }}>
        <img className="search-icon" src={searchIcon} alt="search" />
        <input
          id="searchBar"
          className="input-customer"
          type="text"
          maxLength={30}
          placeholder="Search"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
          }}
        />

        <div className="modal-customer">
          {customers
            ?.filter((val) => {
              if (searchTerm === '') return true;
              return val.name.toLowerCase().includes(searchTerm.toLowerCase());
            })
            .map((customer) => {
              return (
                <SquareCard
                  key={customer._id as string}
                  id={customer._id as string}
                  onClick={() => {
                    handleSave(customer._id as string);
                    setOpenModal(false);
                    setSearchTerm('');
                  }}
                  image={customer.picture}
                  title={customer.name}
                />
              );
            })}
        </div>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between mr-4 ml-4 ">
        {canAddCustomer && (
          <div
            className="d-flex add-customer-footer"
            onClick={() => {
              const [, currentPath, certTemplateId = '', certificateId = ''] =
                window.location.pathname.split('/');
              const templateIdPath = certTemplateId ? `/${certTemplateId}` : '';
              const certificateIdPath = certificateId
                ? `/${certificateId}`
                : '';

              if (onDashboard) {
                navigate(`/customer/new/${onDashboard}?origin=home`);
              } else {
                navigate(
                  currentPath !== 'manager'
                    ? `/customer/new${templateIdPath}${certificateIdPath}`
                    : '/customer/new/back-to-home'
                );
              }
            }}
          >
            <img src={addCustomer} alt="add customer" />
            <p>Add new customer</p>
          </div>
        )}
        {!canAddCustomer && <span />}
        <p className="cancel-btn" onClick={() => setOpenModal(false)}>
          Cancel
        </p>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalCustomer;
