import { FC } from 'react';

import { FormControlProps } from 'react-bootstrap';

import * as FormStyles from 'components/Form/styles';

import { CommonProps } from '../Types';

interface TextFieldProps
  extends FormControlProps,
    Omit<CommonProps, 'fieldId'> {
  helperText?: string;
  [x: string]: unknown;
}

const InfoField: FC<TextFieldProps> = ({ xs, label, helperText, value }) => {
  return (
    <FormStyles.FormGroupWithErrorSpace
      xs={xs}
      className="InfoFieldComponentForm"
    >
      {!!label && (
        <FormStyles.FormLabel style={{ color: '#5488BE' }}>
          {label}
        </FormStyles.FormLabel>
      )}
      {value}
      <FormStyles.FormControlFeedback>
        {helperText}
      </FormStyles.FormControlFeedback>
    </FormStyles.FormGroupWithErrorSpace>
  );
};

InfoField.defaultProps = {
  xs: 12,
  helperText: undefined,
};

export { InfoField };
