import styled from 'styled-components';

export const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1110px;
`;

export const AboutTitle = styled.span`
  color: #272727;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const SupportFooterContainer = styled.div`
  display: flex;
  padding-top: 105px;
  justify-content: space-between;
`;

export const ExternalLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 32px;
`;
