import React, { FC } from 'react';

import CardDefault from 'components/CardDefault';

import * as S from './styles';

interface SettingsCardProps {
  title: string;
  show: boolean;
}

const SettingsCard: FC<SettingsCardProps> = ({ children, title, show }) => {
  if (!show) return null;

  return (
    <CardDefault styles={{ minWidth: 400 }}>
      <S.CardWrapper>
        <S.CardHeader>{title}</S.CardHeader>
        <S.CardBody>{children}</S.CardBody>
      </S.CardWrapper>
    </CardDefault>
  );
};

export default SettingsCard;
