import { FC } from 'react';

import useAuth from 'hooks/useAuth';
import {
  StripeSubscription,
  StripeSubscriptionPrices,
} from 'interfaces/Subscription';

import SubscriptionCard from 'components/SubscriptionCard';

import * as S from './styles';

interface ModalSubscriptionProps {
  onCloseModal: () => void;
  isNew: boolean;
  subscriptions: StripeSubscriptionPrices[];
  currentUserSubscription?: StripeSubscription;
  handleOnSave: (priceId: string, currency: string) => void;
}

export const ModalSubscription: FC<ModalSubscriptionProps> = ({
  onCloseModal,
  isNew,
  subscriptions,
  handleOnSave,
  currentUserSubscription,
}) => {
  const { user } = useAuth();
  const currentSubscription = currentUserSubscription || user?.subscription;

  return (
    <S.Modal show backdrop="static">
      <S.ModalHeader>
        <button onClick={onCloseModal}>Cancel</button>
        <h1>{isNew ? 'Subscription' : 'Manage subscription'}</h1>
      </S.ModalHeader>
      <S.ModalBody>
        {isNew && (
          <>
            <S.Subtitle>
              Subscribe to issue unlimited certificates. <br />
              Choose from our monthly or annual plans, and take advantage of the
              14-day free trial. <br />
              Once you select your plan, you will be redirected to our payment
              platform. <br />
            </S.Subtitle>
            <S.SubscriptionContainer>
              {subscriptions?.map((subscription) => {
                return (
                  <SubscriptionCard
                    key={subscription.priceId}
                    subscription={subscription}
                    isSelected={false}
                    onClick={() => {
                      handleOnSave(subscription.priceId, subscription.currency);
                    }}
                  />
                );
              })}
            </S.SubscriptionContainer>
          </>
        )}
        {!isNew && (
          <>
            <S.Subtitle>
              Select the plan that best fits your needs and budget. <br /> Once
              you&apos;ve made your selection, you&apos;ll be redirected to
              payment.
            </S.Subtitle>
            <S.SubscriptionContainer>
              {subscriptions?.map((subscription) => {
                return (
                  <SubscriptionCard
                    key={subscription.priceId}
                    subscription={subscription}
                    isSelected={
                      currentSubscription?.priceId === subscription.priceId
                    }
                    onClick={() => {
                      if (
                        currentSubscription?.priceId !== subscription.priceId
                      ) {
                        handleOnSave(
                          subscription.priceId,
                          subscription.currency
                        );
                      }
                    }}
                  />
                );
              })}
            </S.SubscriptionContainer>
          </>
        )}
      </S.ModalBody>
    </S.Modal>
  );
};
