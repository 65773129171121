import { AUTH_TOKEN_COOKIE_NAME } from 'constants/app.constants';
import Cookies from 'js-cookie';
import {
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
  Route,
  RouterProvider,
} from 'react-router-dom';

import PaymentOverdue from 'pages/Customer/PaymentOverdue';
import Certificate from 'pages/Manager/Certificate';
import ManageCertificates from 'pages/Manager/Certificate/ManageCertificates';
import Certificates from 'pages/Manager/Certificates';
import CustomerPage from 'pages/Manager/Customer/CustomerPage';
import ManageCustomers from 'pages/Manager/Customer/ManageCustomers';
import Home from 'pages/Manager/Home';
import Settings from 'pages/Manager/Settings';
import Contact from 'pages/Public/Contact';
import InviteExpired from 'pages/Public/InviteExpired';
import Login from 'pages/Public/Login';
import OnBoarding from 'pages/Public/OnBoarding';
import PasswordRecovery from 'pages/Public/PasswordRecovery';
import Registration from 'pages/Public/Registration';
import ResetPassword from 'pages/Public/ResetPassword';
import SetPassword from 'pages/Public/SetPassword';
import Unverified from 'pages/Public/Unverified';
import VerifyExpired from 'pages/Public/VerifyExpired';

import RequireAuth from './RequireAuth/RequireAuth';

async function landingPageLoader() {
  return redirect('/login');
}
function publicLoader() {
  const token = Cookies.get(AUTH_TOKEN_COOKIE_NAME);
  if (token) {
    return redirect('/');
  }
  return null;
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="/login" element={<Login />} loader={publicLoader} />
      <Route
        path="/register"
        element={<Registration />}
        loader={publicLoader}
      />
      <Route path="/onboarding" element={<OnBoarding />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/payment-overdue" element={<PaymentOverdue />} />
      <Route path="/password-recovery" element={<PasswordRecovery />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      {/* For enterprise use */}
      <Route path="/set-password" element={<SetPassword />} />
      {/* For enterprise use */}
      <Route path="/invite-expired" element={<InviteExpired />} />
      <Route path="/unverified/:token/:usermail" element={<Unverified />} />
      <Route path="/verify-expired/:token" element={<VerifyExpired />} />

      {/* User logged validation */}
      <Route element={<RequireAuth />}>
        {/* CERTIFICATES */}
        <Route index element={<Home />} />
        <Route path=":duplicatedId" element={<Home />} />

        <Route path="/certificates">
          <Route index element={<Certificates />} />
        </Route>

        {/* Start certificate-filling */}
        <Route path="/manage-certificate/:templateId">
          <Route index element={<Certificate />} />
          <Route path=":certId" element={<ManageCertificates />}></Route>
        </Route>
        {/* End certificate-filling */}

        {/* Start preset-filling */}
        <Route path="/manage-template/:templateId">
          <Route index element={<Certificate />} />
          <Route path=":certId" element={<ManageCertificates />}></Route>
        </Route>
        {/* End preset-filling */}

        {/* CUSTOMERS */}
        <Route path="/customers" element={<CustomerPage />} />
        <Route path="/customer">
          <Route path="new" element={<ManageCustomers />} />
          <Route
            path="new/:templateId/:certDataId"
            element={<ManageCustomers />}
          />
          <Route path="new/:templateId" element={<ManageCustomers />} />
          <Route path="new/:home" element={<ManageCustomers />} />
          <Route path="edit/:customerId" element={<ManageCustomers />} />
        </Route>

        {/* SETTINGS */}
        <Route path="/settings" element={<Settings />} />
      </Route>
      <Route path="/*" loader={landingPageLoader} />
    </Route>
  )
);

const Routes: React.FunctionComponent = () => (
  <RouterProvider router={router} />
);

export default Routes;
