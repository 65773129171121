import { FC } from 'react';

import { Button } from 'react-bootstrap';

import { ButtonContainer } from './styles';

interface SubmitBarProps {
  clickAction: () => void;
  canSubmit: boolean;
  label: string;
}

const SubmitBar: FC<SubmitBarProps> = ({ clickAction, label, canSubmit }) => {
  return (
    <ButtonContainer>
      <Button
        className="button-primary"
        disabled={!canSubmit}
        onClick={() => canSubmit && clickAction()}
      >
        {label}
      </Button>
    </ButtonContainer>
  );
};

export { SubmitBar };
