import React, { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { APP_NAME } from 'constants/app.constants';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import { TextField } from 'components/Form';
import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';

import { ReduxProps } from '.';
import { defaultValues, schema } from './yupSchema';

import './styles.css';

const SetPasswordPage: FC<ReduxProps> = ({
  logout,
  recoverPassword,
  user: reduxUser,
}) => {
  const navigate = useNavigate();
  const [match] = useSearchParams();
  const [recoverSuccess, setRecoverSuccess] = useState(false);
  const { loading } = reduxUser;
  const token = match.get('token');
  const email = match.get('email');
  const organizationName = match.get('organizationName');

  useEffect(() => {
    if (!token) navigate('/login');
  }, [token, navigate]);

  // React hook form instance
  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  useEffect(() => {
    methods.setFocus('password');
  }, []);

  const onSubmit = (token: string, newPassword: string) =>
    recoverPassword({ token, newPassword }, (successResponse?: boolean) => {
      if (successResponse) {
        setRecoverSuccess(successResponse);
      }
    });

  return (
    <>
      {loading && <SplashScreen />}
      {!recoverSuccess && (
        <NoAuthBasePage>
          <div className="set-password-explanation">
            <p>
              Welcome to {APP_NAME} Enterprise!{'\n'}You have been invited to
              join {organizationName}.
            </p>
            <p>
              To access your account you&apos;ll need to set a password. Please
              make sure it has at {'\n'}least 8 characters, containing uppercase
              and lowercase letters, numbers and{'\n'}special characters, such
              as punctuation.
            </p>
          </div>
          <FormProvider {...methods}>
            <Form
              onSubmit={methods.handleSubmit((data) => {
                token && onSubmit(token, data.password);
              })}
            >
              <Row>
                <TextField
                  xs={6}
                  type="password"
                  fieldId="password"
                  label="Set up a new password"
                  className="input-password"
                />
                <TextField
                  xs={6}
                  type="password"
                  fieldId="passwordConfirm"
                  label="Confirm your new password"
                  className="input-password"
                />
              </Row>
              <Col className="submit-container-button">
                <Button className="button-primary float-right" type="submit">
                  Sign up
                </Button>
              </Col>
            </Form>
          </FormProvider>
        </NoAuthBasePage>
      )}
      {recoverSuccess && (
        <NoAuthBasePage>
          <div id="set-password-explanation">
            <p>
              Your account has been created successfully. Now you can login into
              your account!
            </p>
          </div>
          <Col className="submit-container-button">
            <Button
              className="button-primary float-right"
              type="submit"
              onClick={() => {
                logout();
                navigate(`/login?email=${email}`);
              }}
            >
              Go to login
            </Button>
          </Col>
        </NoAuthBasePage>
      )}
    </>
  );
};

export default SetPasswordPage;
