import styled from 'styled-components';

interface TabItemButtonProps {
  selectedButton?: boolean;
}

export const TabItemButton = styled.button<TabItemButtonProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 0;
  background-color: unset;

  > span {
    font-size: 14px;
    font-style: normal;
    line-height: normal;
  }

  ${({ selectedButton }) => {
    if (selectedButton) {
      return `
        > span {
          color:  #133E6B;
          font-weight: 700;
        }
    `;
    } else {
      return `
      > span {
        color: #C1C1C1;
        font-weight: 400;
      }
    `;
    }
  }};
`;

export const SelectedDecorator = styled.div`
  width: 12px;
  height: 4px;
  flex-shrink: 0;
  background: #133e6b;
`;
