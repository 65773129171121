import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import ViewCertificateIcon from 'assets/images/iconset/icon-button-view-certificate.svg';
import { CertData } from 'interfaces/CertificateData';
import { certTemplateIds } from 'utils/certificates/helpers';
import { formatDateUtil } from 'utils/dateUtils';

import DataTable, { DataTableRow } from 'components/DataTable';
import TertiaryButton from 'components/TertiaryButton';

interface IssuedCertificatesTableProps {
  certificates?: CertData[];
}

const IssuedCertificatesTable: FC<IssuedCertificatesTableProps> = ({
  certificates,
}) => {
  const navigate = useNavigate();

  return (
    <DataTable
      headers={[
        { value: 'Certificate' },
        { value: 'Customer' },
        { value: 'Address' },
        { value: 'Issue date' },
        { value: '' },
      ]}
      rows={certificates?.flatMap((certificate, index): DataTableRow[] => {
        const { street, city, postalCode } =
          certificate?.customer?.headquartersAddress ?? {};
        const mainAddress = `${street}, ${city}, ${postalCode}`;
        return [
          {
            index,
            columns: [
              {
                index: 0,
                value: certificate.template?.certificateName,
                width: '15%',
              },
              {
                index: 1,
                value: certificate.customer?.name,
                width: '30%',
              },
              {
                index: 2,
                value: mainAddress,
                width: '30%',
              },
              {
                index: 3,
                value: formatDateUtil({
                  short: true,
                  dateString: String(certificate.issuedDate),
                  fullYear: true,
                }),
                width: '20%',
              },
              {
                index: 4,
                value: (
                  <TertiaryButton
                    icon={ViewCertificateIcon}
                    handleAction={() => {
                      navigate(
                        `/manage-certificate/${
                          certTemplateIds[
                            certificate.template?.certificateName ?? ''
                          ]
                        }/${certificate._id}`,
                        { state: { issued: true } }
                      );
                    }}
                  />
                ),
                width: '5%',
              },
            ],
          },
        ];
      })}
    />
  );
};

export default IssuedCertificatesTable;
