import React from 'react';
import { useFormContext } from 'react-hook-form';
import { OnboardingPage } from 'store/@types/user';

import Button from 'react-bootstrap/esm/Button';
import Row from 'react-bootstrap/Row';

import { FieldType } from 'enum/FieldType.enum';
import { CertTemplateField } from 'interfaces/CertificateTemplate';

import { Signature } from 'components/Form';
import {
  ComponentFieldSelector,
  convertWithInColumn,
} from 'components/Form/ComponentFieldSelector';
import SecondaryButton from 'components/SecondaryButton';

import * as S from '../styles';

interface TextImageProps {
  page: OnboardingPage;
  nextStep: () => void;
  prevStep: () => void;
  submit: () => void;
}

const FormPage: React.FC<TextImageProps> = ({
  page,
  prevStep,
  nextStep,
  submit,
}) => {
  const {
    description,
    fields,
    primaryButton,
    secondaryButton,
    title,
    subtitle,
  } = page;

  const { trigger, setValue, getValues } = useFormContext();

  const checkAndNextStep = async () => {
    const isValid = await trigger(
      fields?.map(({ id }) => String(id)),
      { shouldFocus: true }
    );

    if (isValid) {
      submit();
      nextStep();
    }
  };

  return (
    <>
      {title && <S.OnboardingTitle>{title}</S.OnboardingTitle>}
      {subtitle && <S.OnboardingSubTitle>{subtitle}</S.OnboardingSubTitle>}
      <S.OnboardingContainerCol style={{ textAlign: 'center' }}>
        {description && description}
      </S.OnboardingContainerCol>
      <Row style={{ marginTop: subtitle ? 0 : 16 }}>
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        {fields?.map(({ toFill, id, entity, ...field }, fieldOrder) => {
          const fieldKey =
            'onboarding-page-' +
            page.title.replaceAll(' ', '_') +
            fieldOrder +
            '-field';

          if (field.type === FieldType.Signature) {
            return (
              <Signature
                xs={convertWithInColumn(field.fieldWidth)}
                key={fieldKey}
                componentType={FieldType.Signature}
                fieldId={`${id}`}
                label={field.label}
                handleOnLoadNew={(file) => {
                  const currentValue = getValues(`${id}`);
                  setValue(`${id}`, {
                    ...currentValue,
                    signature: file,
                  });
                }}
              />
            );
          }
          return (
            <ComponentFieldSelector
              hiddenRequired
              key={fieldKey}
              field={
                {
                  ...field,
                  name: toFill,
                  fieldId: `${id}`,
                } as unknown as CertTemplateField
              }
            />
          );
        })}
      </Row>
      <S.OnboardingContainerRow
        style={{ gap: 23, width: '100%', justifyContent: 'flex-end' }}
      >
        {secondaryButton && (
          <SecondaryButton
            handleAction={prevStep}
            label={secondaryButton}
            center
          />
        )}
        {primaryButton && (
          <Button
            className="button-primary"
            style={{ minWidth: 'auto' }}
            onClick={checkAndNextStep}
          >
            {primaryButton}
          </Button>
        )}
      </S.OnboardingContainerRow>
    </>
  );
};

export default FormPage;
