/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';

export const ButtonContainer = styled.div`
  width: 800px;
  height: 64px;
  margin-left: 15px;
  left: calc((100% - 800px) / 2);
  display: flex;
  bottom: 60px;
  position: fixed;
  background-color: #ffffff;
  border-radius: 11px;
  box-shadow: 0px 4px 13.1px -8px #7fa8bc;
  justify-content: right;
  align-items: center;
  padding-right: 15px;
`;
