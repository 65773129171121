import styled from 'styled-components';

export const TertiaryButton = styled.button<{ backgroundColor?: string }>`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 12px;
  width: auto;
  height: 44px;
  border-radius: 15px;
  background-color: ${({ backgroundColor }) => {
    return backgroundColor ?? '#f3f4f8';
  }};

  transition: all 0.3s;

  img {
    width: 20px;
    height: 20px;
  }

  span {
    color: #5488be;
    text-align: center;

    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  :hover {
    background-color: #c2c6d7;
  }

  :active {
    background-color: #8087a4;
    span {
      color: #f9fbfc;
    }
  }

  :disabled {
    span {
      color: #c1c1c1;
    }
  }
`;
