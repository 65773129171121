import { FC, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SettingsUserInfo } from 'store/@types/user';

import { Button, Col, Form, Row } from 'react-bootstrap';

import { apiDeleteUserImage, apiSetUserImage } from 'api/user';
import useAuth from 'hooks/useAuth';
import api from 'services/api';
import { defaultToast } from 'utils/toast';

import { TextField, UploadPhoto } from 'components/Form';

import { ReduxProps } from '.';
import ModalDeleteUserAccount from './DeleteUserAccount';

interface YourInformationProps extends ReduxProps {
  setIsLoading: (isLoading: boolean) => void;
}

const YourInformation: FC<YourInformationProps> = ({
  setIsLoading,
  deleteUserAndLogout,
}) => {
  const methodsYourAccount = useFormContext<SettingsUserInfo>();
  const { user, loadMe } = useAuth();
  const [showDeleteUser, setShowDeleteUser] = useState(false);

  function saveUser(
    values: Omit<SettingsUserInfo, 'presetSignature' | 'picture'>
  ) {
    if (user?._id) {
      api
        .patch(`/user/me`, values)
        .then(() => {
          defaultToast('S');
        })
        .finally(() => {
          loadMe(true);
          setIsLoading(false);
        });
    }
  }

  return (
    <>
      <Form
        id="settings-your-account-form"
        style={{
          width: '100%',
          maxWidth: 1110,
        }}
        onSubmit={methodsYourAccount.handleSubmit((data) => {
          setIsLoading(true);
          saveUser(data);
        })}
      >
        <Row>
          <UploadPhoto
            lg={12}
            height={'111px'}
            inputSize={1}
            fieldId="picture"
            label="Profile photo"
            onload={(file, base64Data) => {
              apiSetUserImage(file);
              methodsYourAccount.setValue('picture', base64Data, {
                shouldValidate: true,
                shouldDirty: true,
                shouldTouch: true,
              });
            }}
            onDelete={() => {
              apiDeleteUserImage();
            }}
          />
        </Row>
        <Row sm={1} lg={2}>
          <TextField xs={6} required label="Display name" fieldId="name" />
          <TextField xs={6} disabled label="Email" fieldId="email" />
        </Row>

        <Row xs={1} md={2}>
          <Col md={12} lg={6}>
            <Button
              style={{ marginTop: 55 }}
              className="button-red"
              onClick={() => {
                setShowDeleteUser(true);
              }}
            >
              Delete user account
            </Button>
          </Col>

          <Col md={12} lg={6}>
            <Button
              style={{ marginTop: 55 }}
              id="settings-your-info-submit"
              className="button-primary float-right"
              type="submit"
              disabled={
                !methodsYourAccount.formState.isDirty ||
                Object.keys(methodsYourAccount.formState.errors).length > 0
              }
            >
              Save changes
            </Button>
          </Col>
        </Row>
      </Form>

      {/* DELETE USER ACCOUNT MODAL */}
      {showDeleteUser && user && (
        <ModalDeleteUserAccount
          actionCancel={() => {
            setShowDeleteUser(false);
          }}
          actionDeleteUser={() => {
            setIsLoading(true);
            deleteUserAndLogout();
          }}
          userName={user.name}
          userEmail={user.email}
        />
      )}
    </>
  );
};

export default YourInformation;
