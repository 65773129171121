import React, { FC } from 'react';

import customerNoPreview from 'assets/images/iconset/web_usermenu.svg';

import { CarouselItemProps } from 'components/Carousel';

import '../styles.css';

const CarouselCard: FC<CarouselItemProps> = ({
  link,
  image,
  title,
  onClick,
}) => {
  return (
    <div className="square-card">
      <a
        href={link || '#'}
        onClick={() => {
          onClick && onClick();
        }}
      >
        <div className="square-card-image">
          {image && <img src={image as string} alt="customer" />}
          {!image && (
            <div className="empty">
              <img
                src={customerNoPreview}
                alt="customer"
                width={40}
                height={40}
              />
            </div>
          )}
        </div>
        <p className="truncate">{title}</p>
      </a>
    </div>
  );
};

export default CarouselCard;
