import { FC } from 'react';

import * as S from './styles';

interface TertiaryButtonProps {
  handleAction: () => void;
  icon?: string;
  label?: string;
  backgroundColor?: string;
}

const TertiaryButton: FC<TertiaryButtonProps> = ({
  icon,
  label,
  handleAction,
  backgroundColor,
}) => {
  return (
    <S.TertiaryButton onClick={handleAction} backgroundColor={backgroundColor}>
      {icon && <img src={icon} alt="action icon" />}
      {label && <span>{label}</span>}
    </S.TertiaryButton>
  );
};

export default TertiaryButton;
