import styled from 'styled-components';

export const SelectedItemContainer = styled.div<{
  type: string;
  selected: boolean;
  fixed?: boolean;
}>`
  display: flex;
  input,
  select {
    border-radius: 4px !important;
    height: 36px !important;
    border-radius: 4px;
    font-size: 16px;
  }
  ${({ selected, fixed, type }) => {
    if (selected) {
      if (type === 'column') {
        return `
        border-radius: 4px !important;
        border: 1px solid #5488BE !important;
        height: 36px;

        input,
        .custom-select,
        select {
          height: 34px !important;
          background-color: #B4CAE1 !important;
          border: 0 !important;
          opacity: 1 !important;
          color: #272727;
        }
        input:focus {
          height: 34px !important;
          border: 0 !important;
        }
        `;
      } else if (type === 'row') {
        return `
        border-radius: ${fixed ? '4px 0px 0px 4px' : '0px 4px 4px 0px'};
        ${fixed ? 'border-left' : 'border-right'}: 1px solid #5488BE;
        border-top: 1px solid #5488BE;
        border-bottom: 1px solid #5488BE;

        input,
        .custom-select,
        select {
          border-radius: 0 !important;
          background-color: #B4CAE1 !important;
          border: 0 !important;
          opacity: 1 !important;
          color: #272727;
        }
        input:read-only {
          border: 0 !important;
        }
        input:hover{
          border-radius: 0px !important;
          background-color: #B4CAE1 !important;
          color: #272727;
        }
        `;
      }
    }
  }};
`;
