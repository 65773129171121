import React from 'react';

import IconMonthly from 'assets/images/iconset/subscription-monthly.svg';
import IconTrial from 'assets/images/iconset/subscription-trial.svg';
import IconYaerly from 'assets/images/iconset/subscription-yearly.svg';
import {
  StripeSubscription,
  StripeSubscriptionPrices,
} from 'interfaces/Subscription';
import { formatStringDecimalCurrency } from 'utils/currencyUtils';
import { formatDateShort } from 'utils/dateUtils';

import * as S from './styles';
interface SubscriptionCardProps {
  subscription: StripeSubscriptionPrices;
  currentUserSubscription?: StripeSubscription;
  isSelected?: boolean;
  onClick: () => void;
}

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  subscription,
  currentUserSubscription,
  isSelected,
  onClick,
}) => {
  const { product, price, currency } = subscription;
  const colorMap: Record<string, string> = {
    Yearly: '#2B3D4F',
    Monthly: '#ffffff',
  };
  const IconMap: Record<string, string> = {
    Yearly: IconYaerly,
    Monthly: IconMonthly,
  };
  const renewsAt = formatDateShort(currentUserSubscription?.renewsAt);
  const trialExpiresAt = formatDateShort(
    currentUserSubscription?.trialExpiresAt
  );
  const isCurrent = !!currentUserSubscription;
  const inTrial = !!currentUserSubscription?.inTrial;

  return (
    <S.CardContainer
      isSelected={isSelected}
      isCurrent={isCurrent || isSelected}
      onClick={onClick}
    >
      <S.Container>
        <S.IconContainer bgColor={inTrial ? '#F9FBFC' : colorMap[product]}>
          {inTrial && (
            <img
              src={IconTrial}
              width={47.8}
              height={48.6}
              alt="Subscription"
            />
          )}
          {!inTrial && (
            <img
              src={IconMap[product]}
              width={47.8}
              height={48.6}
              alt="Subscription"
            />
          )}
        </S.IconContainer>
        <S.ProductContainer>
          <span>{inTrial ? 'Free trial' : product}</span>
          {!inTrial && (
            <span>
              {!isCurrent
                ? formatStringDecimalCurrency(currency, price)
                : `${product} subscription - Renews on ${renewsAt}`}
            </span>
          )}
          {inTrial && <span>{`Expires on ${trialExpiresAt}`}</span>}
        </S.ProductContainer>
      </S.Container>
    </S.CardContainer>
  );
};
export default SubscriptionCard;
