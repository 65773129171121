import { FC } from 'react';

import * as S from './styles';

interface NoAuthBasePageProps {
  minWidth?: string;
}

const NoAuthBasePage: FC<NoAuthBasePageProps> = ({ children, minWidth }) => {
  return (
    <S.NoAuthContainer>
      <S.NoAuthFormContainer minWidth={minWidth}>
        {children}
      </S.NoAuthFormContainer>
    </S.NoAuthContainer>
  );
};

export default NoAuthBasePage;
