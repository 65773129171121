import styled from 'styled-components';

export const DraftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 16px;
  height: 100%;
`;

export const DraftItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
`;

export const DraftItem = styled.div`
  display: flex;
  justify-content: space-between;

  span {
    font-size: 14px;
    font-style: normal;
    line-height: normal;
  }

  span:nth-child(1) {
    color: #272727;
    text-align: center;
    font-weight: 600;
  }

  span:nth-child(2) {
    color: #c2c6d7;
    font-weight: 400;
  }
`;

export const DraftCardItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  height: 70px;
  transition: all 0.3s;

  :hover {
    background: #f3f4f8;
  }

  span {
    color: #2b3d4f;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;
