import { FC, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { CertificatePresetPayload } from 'store/@types/user';

import { Form, Row } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import { CertificatePreset } from 'interfaces/CertificatePreset';
import { CertTemplate } from 'interfaces/CertificateTemplate';

import { Combo, TextField } from 'components/Form';

import * as S from './styles';
import { defaultValues, yupSchema } from './yupSchema';

import './styles.css';

interface PresetForm {
  certificateType: string;
  name: string;
  isSetup: boolean;
}

interface ModalPresetProps {
  closeModal: () => void;
  handleSave: (data: CertificatePresetPayload, isSetup: boolean) => void;
  templates: CertTemplate[];
  currentPreset?: CertificatePreset;
}

const ModalPreset: FC<ModalPresetProps> = ({
  closeModal,
  handleSave,
  currentPreset,
  templates,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const isEdit = !!currentPreset;

  const methods = useForm<PresetForm>({
    mode: 'onTouched',
    resolver: yupResolver(yupSchema),
    defaultValues: currentPreset ?? defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  return (
    <Modal show className="container-preset" key="modal-sig" backdrop="static">
      <Modal.Header className="preset-header">
        <S.TextButton onClick={closeModal}>Cancel</S.TextButton>
        <Modal.Title className="preset-head-title">New template</Modal.Title>
        <S.TextButton
          onClick={() => {
            formRef.current?.requestSubmit();
          }}
        >
          Close & Save
        </S.TextButton>
      </Modal.Header>

      <Modal.Body>
        <FormProvider {...methods}>
          <Form
            ref={formRef}
            onSubmit={methods.handleSubmit((data) => {
              const { isSetup, ...payload } = data;
              handleSave(payload, isSetup);
            })}
          >
            <Row xs={12}>
              <Combo
                xs={6}
                required
                disabled={isEdit}
                label="Template type"
                fieldId="certificateType"
                options={templates?.map((e) => ({
                  key: e.certificateName as string,
                  value: e.certificateName ?? '',
                }))}
              />
              <TextField
                xs={6}
                required
                label="Template name"
                isCertificate={false}
                fieldId="name"
              />
              <input type="hidden" name="isSetup" />
            </Row>
            <Row xs={12} className="button-wrapper">
              <S.SetButton
                type="button"
                className="button-primary"
                onClick={() => {
                  methods.setValue('isSetup', true);
                  formRef.current?.requestSubmit();
                }}
              >
                Configure
              </S.SetButton>
            </Row>
          </Form>
        </FormProvider>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPreset;
