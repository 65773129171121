import styled from 'styled-components';

export const NoAuthContainer = styled.div`
  display: flex;
  width: 100vw;
  background-color: #fafafc;
  overflow: auto;
  height: 100vh;
  justify-content: center;
`;

export const NoAuthFormContainer = styled.div<{ minWidth?: string }>`
  display: flex;
  width: ${({ minWidth }) => minWidth ?? '357px'};
  flex-shrink: 0;
`;
