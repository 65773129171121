import styled from 'styled-components';

export const LinkButton = styled.button`
  border: 0;
  background-color: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline: 12px;
  gap: 10px;
  height: 44px;
`;

export const LinkButtonLabel = styled.span`
  color: #5488be;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;

  :hover {
    color: #3a6b9e;
  }

  :active {
    color: #133e6b;
  }

  :disabled {
    color: #c1c1c1;
  }
`;
