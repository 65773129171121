import React, { FC } from 'react';

import * as S from './styles';

export type MenuNavItemProps = {
  selectedButton: boolean;
  onClick: () => Promise<void> | void;
  errorButton?: boolean;
  label?: string;
  value?: string;
};

const MenuNavItem: FC<MenuNavItemProps> = ({
  selectedButton,
  onClick,
  errorButton,
  label,
  value,
}) => {
  return (
    <S.MenuNavItemButton
      selectedButton={selectedButton}
      errorButton={errorButton}
      onClick={onClick}
      value={value}
    >
      {label}
    </S.MenuNavItemButton>
  );
};

export default MenuNavItem;
