import { CSSProperties, FC } from 'react';

import LogoutIcon from 'assets/images/iconset/logout.svg';

import LinkButton from 'components/LinkButton';

import * as S from './styles';
export type MenuNavProps = {
  className?: string;
  style?: CSSProperties;
  logoutAction: () => void;
};

const MenuNav: FC<MenuNavProps> = ({
  style,
  children,
  className,
  logoutAction,
}) => {
  return (
    <S.NavContainer>
      <S.NavListUl className={className} style={style}>
        {children}
      </S.NavListUl>
      <S.NavLogoutContainer>
        <LinkButton
          label="Logout"
          icon={LogoutIcon}
          handleAction={logoutAction}
        />
      </S.NavLogoutContainer>
    </S.NavContainer>
  );
};

export default MenuNav;
