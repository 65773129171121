import styled from 'styled-components';

export const ScrollContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: auto;
  justify-content: center;
  padding-bottom: 25px;
`;

export const HomeGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-template-rows: 0fr 1fr 1fr;
  gap: 30px 24px;
  grid-template-areas:
    'subHeader subHeader'
    'counters recentDraft'
    'lastIssued recentDraft';

  overflow: hidden;
  width: 100%;
  height: max-content;
  max-width: 1389px;
  padding: 0px 70px 40px 70px;
`;

export const SubHeaderGrid = styled.div`
  grid-area: subHeader;
`;

export const CountersGrid = styled.div`
  grid-area: counters;
`;

export const LastIssuedGrid = styled.div`
  grid-area: lastIssued;
`;

export const RecentDraftsGrid = styled.div`
  grid-area: recentDraft;
`;

export const SubHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 52px 10px;

  span {
    color: #0a121b;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    span {
      font-size: 20px;
      font-weight: 400;
    }
  }
`;

export const CountCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 100%;
  min-height: 200px;
  min-width: 223px;
  align-items: center;
  justify-content: center;

  span {
    font-style: normal;
    line-height: normal;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  span:nth-child(1) {
    color: #2b3d4f;
    font-size: 20px;
    font-weight: 400;
  }

  span:nth-child(2) {
    color: #272727;
    font-size: 60px;
    font-weight: 700;
  }
`;
