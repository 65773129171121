import React from 'react';
import { useNavigate } from 'react-router-dom';

import UserMenuIcon from 'assets/images/iconset/user.svg';

import * as S from './styles';

const UserMenu: React.FC<{ picture?: string }> = ({ picture }) => {
  const navigate = useNavigate();

  return (
    <S.UserMenuButton
      onClick={() => {
        navigate('/settings?tab=user-profile');
      }}
    >
      {picture && <img src={picture} alt="user" width={40} height={40} />}
      {!picture && <img src={UserMenuIcon} alt="user" width={24} height={24} />}
    </S.UserMenuButton>
  );
};

export default UserMenu;
