import styled from 'styled-components';

export const DataTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;

  table {
    width: 100%;
    min-width: 480px;
    border-collapse: collapse;
  }
`;

export const DataTableTh = styled.th<{
  paddingLeft: boolean;
  paddingRight: boolean;
}>`
  padding-block: 21px 16px;
  color: #2b3440;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  ${({ paddingLeft, paddingRight }) => {
    return `
    padding-left: ${paddingLeft ? '16px' : '5px'};
    padding-right: ${paddingRight ? '16px' : '5px'};
    `;
  }};
`;

export const DataTableTd = styled.td<{
  stripe: boolean;
  paddingLeft: boolean;
  paddingRight: boolean;
}>`
  text-align: left;
  padding-inline: 16px;
  height: 64px;
  color: #2b3440;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${({ stripe }) => {
    return stripe && `background-color: #FAFAFC`;
  }};
  ${({ paddingLeft, paddingRight }) => {
    return `
    padding-left: ${paddingLeft ? '16px' : '5px'};
    padding-right: ${paddingRight ? '16px' : '5px'};
    `;
  }};
`;
