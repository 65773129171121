import styled from 'styled-components';

export const BackButtonConteiner = styled.div`
  cursor: pointer;
  display: flex;
  height: 44px;
  padding: 0px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  > span {
    color: #5488be;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
