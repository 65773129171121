import { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { ContactUsPayload } from 'store/@types/contact/index';

import { Button, Form, Row } from 'react-bootstrap';

import FormSent from 'assets/images/iconset/send_state.svg';
import { defaultToast } from 'utils/toast';

import { Combo } from 'components/Form/Combo';
import { TextField } from 'components/Form/TextField';
import SplashScreen from 'components/root/SplashScreen';
import SecondaryButton from 'components/SecondaryButton';

import { defaultValues, schema } from '../../../pages/Public/Contact/yupSchema';
import { ReduxProps } from '.';
import * as S from './styles';

const ModalContactUs: FC<ReduxProps> = ({
  contact: reduxContact,
  thunkFetchTopics,
  thunkSendContact,
  clearError,
  onClose,
}) => {
  const [successOperation, setSuccessOperation] = useState(false);
  const { error, helpTopics, loading } = reduxContact;

  useEffect(() => {
    thunkFetchTopics();
  }, []);

  useEffect(() => {
    if (error) {
      defaultToast(
        'E',
        'An error occurred while sending your message. Please try again later.'
      );
      clearError();
    }
  }, [error]);

  // React hook form instance
  const methods = useForm<ContactUsPayload>({
    mode: 'onTouched',
    resolver: yupResolver(schema({ description: 500 })),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const onSubmit = async (payload: ContactUsPayload) => {
    thunkSendContact(payload, () => setSuccessOperation(true));
  };

  return (
    <S.Modal show={true} backdrop="static">
      {loading && <SplashScreen />}
      {!successOperation && (
        <Form
          id="user-register"
          onSubmit={methods.handleSubmit((data) => {
            onSubmit(data);
          })}
        >
          <S.ModalBodyContainer className="modal-body">
            <S.ContactTitle>Contact us</S.ContactTitle>
            <S.ContactSubTitle>
              Fill out the form below to get in touch with us.
            </S.ContactSubTitle>
            <FormProvider {...methods}>
              <S.ContactFormContainer>
                <Row>
                  <Combo
                    xs={12}
                    fieldId="topic"
                    options={helpTopics?.map((e) => ({
                      key: e._id,
                      value: e.name,
                    }))}
                    label="Topic"
                  />
                  <TextField xs={12} fieldId="name" label="Name" />
                  <TextField xs={12} fieldId="email" label="Email address" />
                  <TextField
                    xs={12}
                    fieldId="description"
                    label="Describe your issue below"
                    maxLength={500}
                  />
                </Row>
              </S.ContactFormContainer>
            </FormProvider>
          </S.ModalBodyContainer>
          <S.ModalFooterContainer className="modal-footer">
            <SecondaryButton label="Cancel" handleAction={onClose} />
            <Button
              className="button-primary"
              type="submit"
              style={{ minWidth: 0 }}
            >
              Submit
            </Button>
          </S.ModalFooterContainer>
        </Form>
      )}

      {successOperation && (
        <>
          <div className="modal-body">
            <S.ContactTitle>Form sent successfully</S.ContactTitle>

            <S.ContactSuccessContainer>
              <img
                src={FormSent}
                style={{
                  width: '598px',
                  height: '275px',
                  marginTop: '24px',
                  marginBottom: '44px',
                }}
                alt="formSentImage"
              />
              Thank you for contacting us. <br /> We&apos;ll get back to you as
              soon as possible.
              <br />
              <br />
              <div style={{ maxWidth: 377 }}>
                If you need further help, please reach out to us at
              </div>
              <a href="mailto:help@fenixcertify.com">help@fenixcertify.com</a>
            </S.ContactSuccessContainer>
          </div>
          <div className="modal-footer py-3">
            <Button
              className="button-primary float-right"
              type="button"
              onClick={onClose}
            >
              Back to settings
            </Button>
          </div>
        </>
      )}
    </S.Modal>
  );
};

export default ModalContactUs;
