import { Link, useLocation } from 'react-router-dom';
import { RoleEnum } from 'store/@types/user';

import TabCertificatesIcon from 'assets/images/iconset/tab_certificates.svg';
import TabCertificatesActiveIcon from 'assets/images/iconset/tab_certificates_active.svg';
import TabCustomersIcon from 'assets/images/iconset/tab_customers.svg';
import TabCustomersActiveIcon from 'assets/images/iconset/tab_customers_active.svg';
import TabHomeIcon from 'assets/images/iconset/tab_home.svg';
import TabHomeActiveIcon from 'assets/images/iconset/tab_home_active.svg';
import useAuth from 'hooks/useAuth';

import * as S from './styles';

export const MenuMain = () => {
  const location = useLocation();
  const { currentRole } = useAuth();
  const canShowCustomer = [RoleEnum.SOLO, RoleEnum.LEAD].includes(currentRole);

  // Menu tab is active
  function isActive(route: Array<string>): string {
    return route.indexOf(location.pathname) > -1 ? 'active' : '';
  }

  return (
    <S.MenuList>
      <S.MenuListItem className={isActive(['/home'])}>
        <Link to="/home">
          <img
            src={isActive(['/home']) ? TabHomeActiveIcon : TabHomeIcon}
            alt="home icon"
          />
          <span>Home</span>
        </Link>
      </S.MenuListItem>
      <S.MenuListItem className={isActive(['/certificates'])}>
        <Link to="/certificates">
          <img
            src={
              isActive(['/certificates'])
                ? TabCertificatesActiveIcon
                : TabCertificatesIcon
            }
            alt="home icon"
          />
          <span>Certificates</span>
        </Link>
      </S.MenuListItem>
      {canShowCustomer && (
        <S.MenuListItem className={isActive(['/customers'])}>
          <Link to="/customers">
            <img
              src={
                isActive(['/customers'])
                  ? TabCustomersActiveIcon
                  : TabCustomersIcon
              }
              alt="certificate icon"
            />
            <span>Customers</span>
          </Link>
        </S.MenuListItem>
      )}
    </S.MenuList>
  );
};
