import React, { useState } from 'react';

import Modal from 'react-bootstrap/Modal';

import { RangeNumberInput } from 'components/RangeNumberInput';

interface ModalConfirmProps {
  title: string;
  text: string | React.ReactNode;
  confirmText?: string;
  actionConfirm: (ways: number) => void;
  cancelText?: string;
  initWays?: number;
  actionCancel: () => void;
}

const CircuitWaysModal: React.FC<ModalConfirmProps> = ({
  title,
  text,
  initWays,
  actionCancel,
  actionConfirm,
  confirmText,
  cancelText,
}) => {
  const [ways, setWays] = useState<number>(initWays ?? 0);

  return (
    <Modal
      show
      onHide={actionCancel}
      backdrop="static"
      className="modal-md num-ways-circuits-modal"
    >
      <Modal.Header className="modal-default-header d-flex">
        <Modal.Title
          className="modal-header-left-action"
          onClick={actionCancel}
        >
          {cancelText}
        </Modal.Title>
        <Modal.Title className="modal-title">{title}</Modal.Title>
        <Modal.Title
          className="modal-header-right-action"
          onClick={() => {
            actionConfirm(ways);
          }}
        >
          {confirmText}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ marginTop: '20px', marginBottom: '100px' }}>{text}</div>
        <RangeNumberInput
          defaultValue={initWays}
          handleChange={(val) => {
            setWays(val);
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

CircuitWaysModal.defaultProps = {
  confirmText: 'Yes',
  cancelText: 'No',
  initWays: 0,
};

export default CircuitWaysModal;
