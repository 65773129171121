import styled from 'styled-components';

export const CustomSelect = styled.div<{
  icon: string;
  disabled?: boolean;
  height?: string;
  isInvalid?: boolean;
}>`
  display: block;
  width: 100%;
  padding: 10px 35px 10px 12px;
  background: #fff url(${({ icon }) => icon}) no-repeat right 10px center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: normal;
  color: #777;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  ${({ disabled, height, isInvalid }) => {
    return `
    height: ${height ? `${height}px` : '36px'};
    opacity: ${disabled ? '0.6' : '1'};
    cursor: ${disabled ? 'initial' : 'pointer'};
    border: 1px solid ${isInvalid ? '#F04438' : '#dadfe3'};
    `;
  }};
  :hover:not(:disabled, [disabled]) {
    background-color: #f9fbfc;
  }
`;

export const Dropdown = styled.div<{ top: number }>`
  width: min-content;
  position: relative;
  overflow-x: hidden;
  min-width: 250px;
  max-width: 675px;
  max-height: 220px;
  top: ${({ top }) => (top ? `${Math.trunc(top)}px` : '0px')};
  z-index: 2;
  overflow-y: auto;
  box-shadow: 0px 4px 13.1px -8px #7fa8bc;
  background-color: #ffffff;
  border-radius: 11px;
`;

export const DropdownItem = styled.p`
  width: 100%;
  height: auto;
  display: flex;
  cursor: pointer;
  padding: 12px 25px;
  margin: 0;
  color: #272727;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  letter-spacing: normal;
  &:hover {
    background-color: #5488be;
    color: #ffffff;
  }
`;

export const DropdownOverlay = styled.div`
  z-index: 1;
  position: fixed;
  inset: 0;
`;
