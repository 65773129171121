import styled from 'styled-components';

export const EmptyStateContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-shrink: 0;
  border-radius: 11px;
  background: #f9fbfc;
  padding-block: 15px;

  > p {
    margin-top: 30px;
    text-align: center;
    color: #8087a4;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;
