import { FC } from 'react';

import btnRemove from 'assets/images/iconset/table_delete.svg';

import { CarouselItemProps } from 'components/Carousel';

import * as S from './styles';

const PresetCard: FC<CarouselItemProps> = ({
  onClick,
  onDeleteClick,
  title,
  topTitle,
  highlight,
}) => {
  return (
    <S.PresetCard highlightCard={highlight}>
      <S.PresetDeleteIcon
        src={btnRemove}
        alt="Delete templete"
        onClick={onDeleteClick}
      />
      <S.Container justifyContent="center" onClick={onClick} cursor="pointer">
        <S.CertificateType>{title}</S.CertificateType>
        <S.PresetName>{topTitle}</S.PresetName>
      </S.Container>
    </S.PresetCard>
  );
};

export default PresetCard;
