import React, { FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  CertificatePresetPayload,
  CertificateSettings,
  RoleEnum,
} from 'store/@types/user';

import { Button, Col, Form, Row } from 'react-bootstrap';

import { apiSetCertificateSettings } from 'api/business';
import { apiListCertTemplatesTypes } from 'api/certificates';
import {
  apiDeleteCertificatePreset,
  apiGetCertificatePresets,
  apiSetCertificatePreset,
  apiUpdateCertificatePreset,
} from 'api/user';
import AddItemIcon from 'assets/images/iconset/add.svg';
import AddWhiteItemIcon from 'assets/images/iconset/add_white.svg';
import useAuth from 'hooks/useAuth';
import useCerts from 'hooks/useCerts';
import { CertificatePreset } from 'interfaces/CertificatePreset';
import { CertTemplate } from 'interfaces/CertificateTemplate';
import { defaultToast } from 'utils/toast';

import Carousel from 'components/Carousel';
import { FormLabel, TextField } from 'components/Form';
import ColorPicker from 'components/Form/ColorPicker';
import ModalConfirm from 'components/modals/ModalConfirm';
import ModalPreset from 'components/modals/ModalPreset';
import SecondaryButton from 'components/SecondaryButton';

import * as S from './styles';

interface CertificateInformationProps {
  setIsLoading: (isLoading: boolean) => void;
  handleCertificateFormReset: (data: CertificateSettings) => void;
}

const CertificateInformation: FC<CertificateInformationProps> = ({
  setIsLoading,
  handleCertificateFormReset,
}) => {
  const methodsCertificate = useFormContext();
  const [query] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [currentPreset, setCurrentPreset] = useState<CertificatePreset>();
  const { user, currentRole, currentBusiness, reload, loadMe } = useAuth();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [presets, setPresets] = useState<CertificatePreset[]>([]);
  const [templates, setTemplates] = useState<CertTemplate[]>([]);
  const [showPresetModal, setShowPresetModal] = useState(false);
  const { clearAll } = useCerts();

  const duplicatedId = query.get('duplicated');
  const canEditCustomNumeration = currentRole === RoleEnum.SOLO;
  const canEditPresets = [RoleEnum.SOLO, RoleEnum.LEAD].includes(currentRole);

  const loadPresets = () => {
    if (canEditPresets) {
      setIsLoading(true);
      apiGetCertificatePresets()
        .then((data) => {
          data.reverse();
          setPresets(data);
        })
        .catch(() => {
          defaultToast('E');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const saveCertificateSettings = (values: CertificateSettings) => {
    if (!user) return;
    setIsLoading(true);
    apiSetCertificateSettings(currentBusiness, {
      ...values,
      startNum: Number(values.startNum),
    })
      .then((businessData) => {
        if (businessData.certificateSettings) {
          handleCertificateFormReset(businessData.certificateSettings);
        }
      })
      .catch((error) => {
        if (error.statusCode === 400) {
          defaultToast('E', error?.data?.message);
        }
      })
      .finally(() => {
        reload({ reloadBusiness: true });
        setIsLoading(false);
      });
  };

  const redirectToSetup = (preset: CertificatePreset) => {
    // Update user context with preset changes
    loadMe(true);

    const template = templates.find(
      (temp) => temp.certificateName === preset.certificateType
    );
    if (template) {
      navigate(`/manage-template/${template?._id}/${preset.data}`);
    }
  };

  const createPreset = (
    payload: CertificatePresetPayload,
    isSetup: boolean
  ) => {
    setIsLoading(true);
    apiSetCertificatePreset(payload)
      .then((preset) => {
        defaultToast('S', 'Your template was created successfully');
        if (isSetup) {
          redirectToSetup(preset);
        } else {
          loadPresets();
          setShowPresetModal(false);
          setIsLoading(false);
        }
      })
      .catch(() => {
        defaultToast('E');
        setIsLoading(false);
      });
  };

  const updatePreset = (
    payload: CertificatePresetPayload,
    isSetup: boolean,
    presetId: string
  ) => {
    setIsLoading(true);
    apiUpdateCertificatePreset(presetId, payload)
      .then((preset) => {
        if (isSetup) {
          redirectToSetup(preset);
        } else {
          defaultToast('S');
          loadPresets();
          setShowPresetModal(false);
          setIsLoading(false);
        }
      })
      .catch(() => {
        defaultToast('E');
        setIsLoading(false);
      });
  };

  const deletePreset = (presetId: string) => {
    setIsLoading(true);
    apiDeleteCertificatePreset(presetId)
      .then(() => {
        defaultToast('S', 'Your template was deleted successfully');
        loadPresets();
        setShowDeleteConfirmation(false);
      })
      .catch(() => {
        defaultToast('E');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    clearAll();
    loadPresets();
    apiListCertTemplatesTypes()
      .then((data) => {
        setTemplates(data);
      })
      .catch(() => {
        defaultToast('E', 'Error on load certificate templates');
      });
  }, []);

  return (
    <S.SettingsContainer>
      <S.SettingsHeader>
        <span>Templates</span>
        <SecondaryButton
          label="Add new"
          icon={AddItemIcon}
          iconHover={AddWhiteItemIcon}
          handleAction={() => {
            setCurrentPreset(undefined);
            setShowPresetModal(true);
          }}
        />
      </S.SettingsHeader>
      <Form
        onSubmit={methodsCertificate.handleSubmit((data) => {
          saveCertificateSettings(data);
        })}
        style={{ marginTop: 16 }}
      >
        <Row>
          <Col xs={12}>
            {canEditPresets && (
              <Carousel
                cardSize="preset"
                payload={presets?.map((item) => ({
                  id: item._id,
                  highlight: duplicatedId === item._id,
                  onClick: () => {
                    setCurrentPreset(item);
                    setShowPresetModal(true);
                  },
                  onDeleteClick: () => {
                    setCurrentPreset(item);
                    setShowDeleteConfirmation(true);
                  },
                  title: item.certificateType,
                  topTitle: item.name,
                }))}
              />
            )}
            <Row sm={1}>
              <FormLabel
                xs={10}
                label={t('certificate_settings_sub_section_numeration')}
              />
            </Row>
            <Row sm={1} lg={2}>
              <TextField
                xs={5}
                disabled={!canEditCustomNumeration}
                label={t('certificate_settings_input_prefix') as string}
                fieldId="prefix"
              />
              <TextField
                xs={5}
                disabled={!canEditCustomNumeration}
                type="number"
                label={t('certificate_settings_input_numeration') as string}
                fieldId="startNum"
              />
            </Row>
            <Row>
              <FormLabel
                xs={10}
                className="mt-0"
                label={t('certificate_settings_sub_section_print_color_code')}
              />
              <ColorPicker
                xs={5}
                disabledField={!canEditCustomNumeration}
                fieldId="color"
                colorOptions={['#FF0000', '#9C00FF', '#4FA3A1', '#0088FF']}
              />
            </Row>
          </Col>
        </Row>
        {(canEditPresets || canEditCustomNumeration) && (
          <Row sm={1} lg={1}>
            <Col xs={2}>
              <Button
                className="button-primary float-right"
                type="submit"
                disabled={
                  !methodsCertificate.formState.isDirty ||
                  Object.keys(methodsCertificate.formState.errors).length > 0
                }
              >
                Save changes
              </Button>
            </Col>
          </Row>
        )}
      </Form>
      {showPresetModal && (
        <ModalPreset
          templates={templates}
          currentPreset={currentPreset}
          closeModal={() => {
            setShowPresetModal(false);
          }}
          handleSave={(payload, isSetup) => {
            if (currentPreset) {
              updatePreset(payload, isSetup, currentPreset._id);
            } else {
              createPreset(payload, isSetup);
            }
          }}
        />
      )}
      {showDeleteConfirmation && (
        <ModalConfirm
          title="Delete template?"
          text={'Are you sure you want to delete this template?'}
          confirmText="Cancel"
          actionConfirm={() => {
            setShowDeleteConfirmation(false);
          }}
          cancelText="Delete"
          actionCancel={() => {
            if (currentPreset) {
              deletePreset(currentPreset?._id);
            }
          }}
        />
      )}
    </S.SettingsContainer>
  );
};

export default CertificateInformation;
