import * as yup from 'yup';

import { emailValidator, errors } from 'utils/formUtils';

export const schema = yup.object().shape({
  email: emailValidator.required(errors.email.required),
});

export const defaultValues = {
  email: '',
};
