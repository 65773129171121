import React from 'react';

import EmptyStateTable from 'components/EmptyStateTable';

import * as S from './styles';

interface DataTableHeader {
  value: string;
}

interface DataTableColumn {
  index: number;
  value: string | React.ReactNode;
  width: number | string;
}

export interface DataTableRow {
  index: number;
  columns: DataTableColumn[];
}

interface DataTableOptions {
  stripe: boolean;
}

interface DataTableProps {
  headers: DataTableHeader[];
  emptyStateLabel?: string;
  options?: DataTableOptions;
  rows?: DataTableRow[];
}

const DataTable: React.FC<DataTableProps> = ({
  headers,
  rows,
  options,
  emptyStateLabel,
}) => {
  const isStripe = Boolean(options?.stripe ?? true);

  return (
    <S.DataTableWrapper>
      <table>
        <thead>
          <tr>
            {headers.map((header, index) => (
              <S.DataTableTh
                key={header.value}
                paddingLeft={index === 0}
                paddingRight={index === headers.length - 1}
              >
                {header.value}
              </S.DataTableTh>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows?.map((row, index) => (
            <tr key={`row-${row.index}`}>
              {row.columns.map((column, columnIndex) => (
                <S.DataTableTd
                  key={`column-${column.index}`}
                  paddingLeft={columnIndex === 0}
                  paddingRight={columnIndex === row.columns.length - 1}
                  style={{ width: column.width }}
                  stripe={isStripe ? index % 2 === 0 : false}
                >
                  {column.value}
                </S.DataTableTd>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {rows?.length === 0 && (
        <EmptyStateTable
          styles={{
            flexDirection: 'column',
          }}
          label={emptyStateLabel}
        />
      )}
    </S.DataTableWrapper>
  );
};

export default DataTable;
