import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { IssuedCertData } from 'interfaces/IssuedCertificateData';
import {
  certTemplateIds,
  getSiteAddressByCertData,
} from 'utils/certificates/helpers';
import { formatDateUtil } from 'utils/dateUtils';

import CardDefault from 'components/CardDefault';
import CardItem from 'components/CardDefault/CardItem';
import CardTitle from 'components/CardDefault/CardTitle';
import EmptyState from 'components/EmptyState';

import * as S from './styles';

interface IssuedListProps {
  items: IssuedCertData[];
}

const IssuedList: FC<IssuedListProps> = ({ items }) => {
  const navigate = useNavigate();

  return (
    <CardDefault>
      <S.Container>
        <CardTitle>Last issued</CardTitle>
        <S.ItemContainer>
          {items.length === 0 && (
            <EmptyState
              imageWidth={87}
              imageHeight={96}
              styles={{
                flexDirection: 'column',
              }}
            />
          )}
          {items.slice(0, 7).map((item) => {
            const { street, city, postalCode } =
              item.customer?.headquartersAddress ?? {};
            return (
              <CardItem key={item._id}>
                <S.CardItemContainer
                  onClick={() => {
                    navigate(
                      `/manage-certificate/${
                        certTemplateIds[item.template?.certificateName ?? '']
                      }/${item._id}`,
                      { state: { issued: true } }
                    );
                  }}
                >
                  <span style={{ width: '20%' }}>
                    {item.template?.certificateName ?? ''}
                  </span>
                  <S.CustomerColumn style={{ width: '20%' }}>
                    <span>{item.customer?.name ?? ''}</span>
                  </S.CustomerColumn>
                  <span
                    style={{ width: '40%' }}
                  >{`${street}, ${city}, ${postalCode}`}</span>
                  <span style={{ width: '20%' }}>
                    {formatDateUtil({
                      dateString: String(item.issuedDate),
                      short: true,
                      fullYear: true,
                    })}
                  </span>
                </S.CardItemContainer>
              </CardItem>
            );
          })}
        </S.ItemContainer>
      </S.Container>
    </CardDefault>
  );
};

export default IssuedList;
