import styled from 'styled-components';

export const OnboardingContainerCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const OnboardingContainerRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const OnboardingTitle = styled.div`
  color: #272727;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 50px;
`;

export const OnboardingSubTitle = styled.div`
  color: #2b3d4f;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-block: 16px;
  white-space: pre-wrap;
`;
