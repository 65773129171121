import { bindActionCreators, Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RootState } from 'store/@types';
import { logout } from 'store/user/thunks';

import DefaultComponent from './Settings';

const mapStateToProps = ({ user }: RootState) => ({ user });
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ logout }, dispatch);

export type ReduxProps = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DefaultComponent);
