import { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  CertificateSettings,
  RoleEnum,
  SettingsBusinessInfo,
  SettingsBusinessInfoPayload,
  SettingsUserInfo,
  SettingsUserSignatureInfo,
} from 'store/@types/user';

import useAuth from 'hooks/useAuth';
import { SettingsNavOptions } from 'interfaces/Settings';
import { defaultToast } from 'utils/toast';

import MenuBack from 'components/Menu/MenuBack';
import MenuNav from 'components/Menu/MenuNav';
import MenuNavItem from 'components/Menu/MenuNav/MenuNavItem';
import MenuNavItemTitle from 'components/Menu/MenuNav/MenuNavItemTitle';
import MainLayout from 'components/root/MainLayout';
import SplashScreen from 'components/root/SplashScreen';

import { ReduxProps } from '.';
import AboutThis from './AboutThis';
import BusinessInformation from './BusinessInformation';
import CertificateInformation from './CertificateInformation';
import SettingsCard from './SettingsCard/SettingsCard';
import SignatureAndRole from './SignatureAndRole';
import * as S from './styles';
import Subscription from './Subscription';
import WorkEquipment from './WorkEquipment';
import YourInformation from './YourInformation';
import {
  BusinessSchema,
  BusinessSchemaDV,
  CertificateSettingsDV,
  CertificateSettingsSchema,
  SignatureAndRoleSchema,
  SignatureAndRoleSchemaDV,
  YourAccountSchema,
  YourAccountSchemaDV,
} from './yupSchema';

const Settings: FC<ReduxProps> = ({ logout }) => {
  const {
    user,
    userSignatures,
    userHasRequiredInfoFilled,
    business,
    reload,
    currentRole,
  } = useAuth();

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQueryParams] = useSearchParams();
  const [verifyState, setVerifyState] = useState<{ verify: boolean }>();
  const { state } = useLocation();
  const tab = query.get('tab');
  const {
    missUserInfo,
    missUserSignatureAnRoleInfo,
    missBusinessInfo,
    missEquimpentInfo,
  } = userHasRequiredInfoFilled();

  // Access roles
  const hasSubscriptionAccess = currentRole === RoleEnum.SOLO;

  const navOptions: SettingsNavOptions[] = [
    { label: 'USER DETAILS', isTitle: true, show: true },
    { label: 'Profile', valueTab: 'user-profile', show: true },
    { label: 'Signature & Role', valueTab: 'user-signature', show: true },
    {
      label: 'Test equipments',
      valueTab: 'test-equipments',
      show: true,
    },
    { label: 'ORGANISATION DETAILS', isTitle: true, show: true },
    {
      label: 'Profile',
      valueTab: 'business-profile',
      show: true,
    },
    { label: 'APP MANAGEMENT', isTitle: true, show: true },
    {
      label: 'Subscription',
      valueTab: 'app-subscription',
      show: hasSubscriptionAccess,
    },
    {
      label: 'Certificate settings',
      valueTab: 'app-certificate',
      show: true,
    },
    {
      label: 'Support',
      valueTab: 'app-support',
      show: true,
    },
  ];

  useEffect(() => {
    setVerifyState(state);
    reload({ reloadBusiness: true });
    if (!tab) {
      setQueryParams('tab=user-profile');
    }
  }, []);

  // React hook form instance
  const methodsYourAccount = useForm<SettingsUserInfo>({
    mode: 'onTouched',
    resolver: yupResolver(YourAccountSchema),
    defaultValues: YourAccountSchemaDV,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const handleYourInfoFormReset = () => {
    if (!user) return;
    methodsYourAccount.reset({
      ...methodsYourAccount.watch(),
      picture: user.picture,
      name: user.name,
      email: user.email,
    });
  };

  // React hook form instance
  const methodsSignatureAndRole = useForm<SettingsUserSignatureInfo>({
    mode: 'onTouched',
    resolver: yupResolver(SignatureAndRoleSchema),
    defaultValues: SignatureAndRoleSchemaDV,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const handleSignatureAndRoleFormReset = () => {
    if (!user) return;
    methodsSignatureAndRole.reset({
      ...methodsSignatureAndRole.watch(),
      position: user?.position ?? '',
    });
  };

  // React hook form instance
  const methodsBusiness = useForm<SettingsBusinessInfoPayload>({
    mode: 'onTouched',
    resolver: yupResolver(BusinessSchema),
    defaultValues: BusinessSchemaDV,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const handleBusinessFormReset = (data: SettingsBusinessInfo) => {
    if (!data) return;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { team, __v, _id, ...businessInfo } = data;

    methodsBusiness.reset({
      ...businessInfo,
      logoPhoto: businessInfo.logoPhoto ?? '',
      schemeLogo: businessInfo.schemeLogo ?? '',
      phoneNumber: businessInfo ? businessInfo.phoneNumber : '',
      companyNumber: businessInfo ? businessInfo.companyNumber : '',
      fax: businessInfo ? businessInfo.fax : '',
    });

    if (verifyState && missBusinessInfo) {
      methodsBusiness.trigger();
    }
  };

  // React hook form instance
  const methodsCertificate = useForm<CertificateSettings>({
    mode: 'onTouched',
    resolver: yupResolver(CertificateSettingsSchema),
    defaultValues: CertificateSettingsDV,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const handleCertificateFormReset = (data: CertificateSettings) => {
    if (!data) return;
    const { prefix, color, startNum } = data;

    methodsCertificate.reset({
      prefix: prefix ?? '',
      startNum,
      color: color ?? '',
    });
  };

  const requiredInfoVerification = (value?: string) => {
    if (verifyState && value) {
      if (value === 'user-profile')
        return Object.keys(methodsYourAccount.formState.errors).length > 0;
      if (value === 'user-signature')
        return Object.keys(methodsSignatureAndRole.formState.errors).length > 0;
      if (value === 'business-profile')
        return Object.keys(methodsBusiness.formState.errors).length > 0;
      if (value === 'test-equipments') return missEquimpentInfo;
    }
    return false;
  };

  useEffect(() => {
    if (user) {
      handleYourInfoFormReset();
      handleSignatureAndRoleFormReset();
    }
  }, [user]);

  useEffect(() => {
    handleBusinessFormReset(business as SettingsBusinessInfo);
    if (business?.certificateSettings) {
      handleCertificateFormReset(business.certificateSettings);
    }
  }, [business]);

  useEffect(() => {
    if (!userSignatures) return;
    const defaultOne = userSignatures.find((s) => s.default);

    if (!defaultOne) return;
    methodsSignatureAndRole.setValue('presetSignature', defaultOne, {
      shouldDirty: false,
    });
  }, [userSignatures]);

  useEffect(() => {
    if (verifyState) {
      if (missUserInfo) {
        methodsYourAccount.trigger();
      }
      if (missUserSignatureAnRoleInfo) {
        methodsSignatureAndRole.trigger();
      }
    }
  }, [verifyState, user]);

  return (
    <>
      {isLoading && <SplashScreen />}
      <MainLayout pageTitle="Settings" hideHeader>
        <MenuBack
          menuTitle="Settings"
          handleBack={() => {
            navigate('/home');
          }}
        />
        <S.MainContainer>
          <S.MenuContainer>
            <MenuNav logoutAction={logout}>
              {navOptions
                .filter(({ show }) => show)
                .map((navOption) => {
                  if (navOption.isTitle) {
                    return (
                      <MenuNavItemTitle key={navOption.label}>
                        {navOption.label}
                      </MenuNavItemTitle>
                    );
                  }
                  return (
                    <MenuNavItem
                      key={navOption.valueTab}
                      label={navOption.label}
                      selectedButton={navOption.valueTab === tab}
                      errorButton={requiredInfoVerification(navOption.valueTab)}
                      onClick={() => {
                        setQueryParams(`tab=${navOption.valueTab}`);
                      }}
                    />
                  );
                })}
            </MenuNav>
          </S.MenuContainer>
          <S.FormContainer>
            {/* User profine */}
            <SettingsCard title={'Profile'} show={tab === 'user-profile'}>
              <FormProvider {...methodsYourAccount}>
                <YourInformation setIsLoading={(v) => setIsLoading(v)} />
              </FormProvider>
            </SettingsCard>

            {/* User signature and role */}
            <SettingsCard
              title={'Signature & Role'}
              show={tab === 'user-signature'}
            >
              <FormProvider {...methodsSignatureAndRole}>
                <SignatureAndRole setIsLoading={(v) => setIsLoading(v)} />
              </FormProvider>
            </SettingsCard>

            {/* User test equipment */}
            <SettingsCard
              title={'Test equipment'}
              show={tab === 'test-equipments'}
            >
              <WorkEquipment
                setIsLoading={(v) => setIsLoading(v)}
                noEquipsError={
                  verifyState?.verify === true && missEquimpentInfo
                }
              />
            </SettingsCard>

            {/* Business profile */}
            <SettingsCard title={'Profile'} show={tab === 'business-profile'}>
              <FormProvider {...methodsBusiness}>
                <BusinessInformation
                  setIsLoading={(v) => setIsLoading(v)}
                  handleBusinessFormReset={(a) => {
                    handleBusinessFormReset(a);
                    defaultToast('S');
                  }}
                />
              </FormProvider>
            </SettingsCard>

            {/* App subscription */}
            {hasSubscriptionAccess && (
              <SettingsCard
                title={'Subscription'}
                show={tab === 'app-subscription'}
              >
                <Subscription setIsLoading={(v) => setIsLoading(v)} />
              </SettingsCard>
            )}

            {/* App certificate */}
            <SettingsCard
              title={'Certificate settings'}
              show={tab === 'app-certificate'}
            >
              <FormProvider {...methodsCertificate}>
                <CertificateInformation
                  setIsLoading={(v) => setIsLoading(v)}
                  handleCertificateFormReset={(a) => {
                    handleCertificateFormReset(a);
                    defaultToast('S');
                  }}
                />
              </FormProvider>
            </SettingsCard>

            {/* App suport */}
            <SettingsCard title={'Support'} show={tab === 'app-support'}>
              <AboutThis />
            </SettingsCard>
          </S.FormContainer>
        </S.MainContainer>
      </MainLayout>
    </>
  );
};

export default Settings;
