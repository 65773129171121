import styled from 'styled-components';

export const UserMenuButton = styled.button`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
  min-height: 40px;
  border-radius: 20px;
  background-color: #f3f4f8;

  img {
    border-radius: 20px;
    object-fit: cover;
  }
`;
