import styled from 'styled-components';

export const MenuBackContainer = styled.div`
  width: 100%;
  height: 69px !important;
  padding: 0 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #5488be4d;
  background: #fff;
`;

export const MenuBackTitle = styled.div`
  color: #133e6b;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 85px;
`;
