import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/esm/Button';

import { apiSendPasswordRecovery } from 'api/user';
import LogoIcon from 'assets/images/symbol.svg';
import withHelmet from 'hooks/withHelmet';
import { defaultToast } from 'utils/toast';

import { TextField } from 'components/Form';
import LinkButton from 'components/LinkButton';
import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';
import SecondaryButton from 'components/SecondaryButton';

import * as S from '../styles';
import { defaultValues, schema } from './yupSchema';

interface RecoverPWData {
  email: string;
  password: string;
  passwordConfirm: string;
}

function PasswordRecovery() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [successOperation, setSuccessOperation] = useState(false);

  // React hook form instance
  const methods = useForm<RecoverPWData>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const onSubmit = async (payload: RecoverPWData) => {
    try {
      await apiSendPasswordRecovery(payload);
      setSuccessOperation(true);
      setIsLoading(false);
    } catch (err) {
      defaultToast('E');
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <SplashScreen />}
      <NoAuthBasePage minWidth="377px">
        {!successOperation && (
          <S.PublicContainerCol style={{ minHeight: 661 }}>
            <img src={LogoIcon} alt="logo" width={34} height={34} />
            <S.PublicTitle>Forgot your password?</S.PublicTitle>
            <S.PublicSubTitle>
              Enter the email address associated with your Fenix account and we
              will send password reset instructions.
            </S.PublicSubTitle>
            <FormProvider {...methods}>
              <Form
                id=""
                style={{ width: '100%' }}
                onSubmit={methods.handleSubmit((data) => {
                  setIsLoading(true);
                  onSubmit(data);
                })}
              >
                <Row>
                  <TextField
                    xs={12}
                    fieldId="email"
                    label="Email"
                    placeholder="Insert your email address"
                  />
                </Row>
                <Button
                  style={{ width: '100%', marginTop: 0 }}
                  className="button-primary"
                  type="submit"
                >
                  Reset password
                </Button>
                <hr style={{ background: '#B4CAE1' }} />
                <SecondaryButton
                  label="Back"
                  style={{ width: '100%' }}
                  center
                  handleAction={() => {
                    navigate('/login');
                  }}
                />
                <S.PublicContainerRow style={{ marginTop: 16 }}>
                  Need help?&nbsp;
                  <LinkButton
                    label="Contact us"
                    style={{ padding: 0, height: 'auto' }}
                    handleAction={() => {
                      navigate('/contact');
                    }}
                  />
                </S.PublicContainerRow>
              </Form>
            </FormProvider>
          </S.PublicContainerCol>
        )}
        {successOperation && (
          <S.PublicContainerCol style={{ minHeight: 661 }}>
            <img src={LogoIcon} alt="logo" width={34} height={34} />
            <S.PublicTitle>Password reset instructions</S.PublicTitle>

            <S.PublicSubTitle>
              <span> We have sent an email to &nbsp;</span>
              <strong>{methods.getValues('email')}</strong>
              <span>
                &nbsp;with instructions on how to reset your password.
              </span>
            </S.PublicSubTitle>

            <S.PublicSubTitle>
              Check your inbox and follow the link to reset your password. If
              you do not see the email in your inbox, check your spam or junk
              folder.
            </S.PublicSubTitle>

            <Button
              style={{ width: '100%', marginTop: 32 }}
              className="button-primary"
              onClick={() => {
                navigate('/login');
              }}
            >
              Back to login
            </Button>
            <hr style={{ background: '#B4CAE1' }} />
            <S.PublicContainerRow>
              Need help?&nbsp;
              <LinkButton
                label="Contact us"
                style={{ padding: 0, height: 'auto' }}
                handleAction={() => {
                  navigate('/contact');
                }}
              />
            </S.PublicContainerRow>
          </S.PublicContainerCol>
        )}
      </NoAuthBasePage>
    </>
  );
}

export default withHelmet(PasswordRecovery);
