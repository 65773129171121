import styled from 'styled-components';

export const SubHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 46px;
  gap: 10px;

  span {
    color: #0a121b;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    span {
      font-size: 20px;
      font-weight: 400;
    }
  }
`;

export const GridContainer = styled.div`
  display: grid;
  width: 100%;
  max-width: 1300px;
  grid-template-columns: 1fr;
  grid-template-rows: 0fr 3fr;
  gap: 20px 0px;
  grid-template-areas:
    'header'
    'table';
`;

export const GridHeader = styled.div`
  display: grid;
  grid-area: header;
`;

export const GridTable = styled.div`
  display: grid;
  grid-area: table;
  min-height: 480px;
`;
