/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import React from 'react';
import { Helmet } from 'react-helmet';

const withHelmet =
  (Component: React.FC<any>, color = '#2B3D4F') =>
  (props: any) => {
    return (
      <>
        <Helmet>
          <meta name="theme-color" content={color} />
        </Helmet>
        <Component {...props} />
      </>
    );
  };

export default withHelmet;
