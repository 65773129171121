import styled from 'styled-components';

export const Dropdown = styled.div`
  background-color: #ffffff;
  border-radius: 12px;
  width: 675px;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.3);
  z-index: 10;
  margin-top: -30px;
`;

export const DropdownItem = styled.p`
  width: full;
  height: auto;
  display: flex;
  cursor: pointer;
  padding: 12px 25px;
  color: #272727;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  &:hover {
    background-color: var(--color-primary);
    color: #ffffff;
  }
`;
export const DropdownOverlay = styled.div`
  z-index: 1;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
