import styled from 'styled-components';

export const UploadPhotoLabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  color: var(--color-primary);
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  text-align: center;
  position: absolute;
  top: 114px;
  width: calc(100% - 15px);

  > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  span:nth-child(1) {
    color: #133e6b;
  }
  span:nth-child(2) {
    color: #b4cae1;
  }
`;
