import { FC, useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ContactUsPayload } from 'store/@types/contact/index';

import { Button, Col, Form, Row } from 'react-bootstrap';

import FormSent from 'assets/images/iconset/send_state.svg';
import LogoIcon from 'assets/images/symbol.svg';
import withHelmet from 'hooks/withHelmet';
import { defaultToast } from 'utils/toast';

import { Combo } from 'components/Form/Combo';
import { TextField } from 'components/Form/TextField';
import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';
import SecondaryButton from 'components/SecondaryButton';

import * as S from '../styles';
import { ReduxProps } from '.';
import { defaultValues, schema } from './yupSchema';

const ContactPage: FC<ReduxProps> = ({
  contact: reduxContact,
  thunkFetchTopics,
  thunkSendContact,
  clearError,
}) => {
  const [successOperation, setSuccessOperation] = useState(false);
  const navigate = useNavigate();

  const { error, helpTopics, loading } = reduxContact;

  useEffect(() => {
    thunkFetchTopics();
  }, []);

  useEffect(() => {
    if (error) {
      defaultToast(
        'E',
        'An error occurred while sending your message. Please try again later.'
      );
      clearError();
    }
  }, [error]);

  // React hook form instance
  const methods = useForm<ContactUsPayload>({
    mode: 'onTouched',
    resolver: yupResolver(schema({ description: 500 })),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const onSubmit = async (payload: ContactUsPayload) => {
    thunkSendContact(payload, () => setSuccessOperation(true));
  };

  return (
    <>
      {loading && <SplashScreen />}
      <NoAuthBasePage>
        <S.PublicContainerCol style={{ minHeight: 770 }}>
          {!successOperation && (
            <FormProvider {...methods}>
              <img src={LogoIcon} alt="logo" width={34} height={34} />
              <Form
                style={{ display: 'block' }}
                id="contact-us"
                onSubmit={methods.handleSubmit((data) => {
                  onSubmit(data);
                })}
              >
                <S.PublicTitle>Contact us</S.PublicTitle>
                <S.PublicSubTitle>
                  Fill out the form below to get in touch with us.
                </S.PublicSubTitle>
                <Row>
                  <Combo
                    xs={12}
                    fieldId="topic"
                    options={helpTopics?.map((e) => ({
                      key: e._id,
                      value: e.name,
                    }))}
                    label="Topic"
                  />
                  <TextField xs={12} fieldId="name" label="Name" />
                  <TextField xs={12} fieldId="email" label="Email address" />
                  <TextField
                    xs={12}
                    isTextArea
                    fieldId="description"
                    label="Describe your issue below"
                    maxLength={500}
                    height={'80px'}
                  />
                  <Col xs={12}>
                    <Button
                      className="button-primary"
                      type="submit"
                      style={{ width: '100%' }}
                    >
                      Submit
                    </Button>
                  </Col>
                  <Col xs={12}>
                    <SecondaryButton
                      label="Back"
                      center
                      handleAction={() => {
                        navigate('/login');
                      }}
                      style={{ width: '100%', marginTop: 16 }}
                    />
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          )}

          {successOperation && (
            <>
              <img src={LogoIcon} alt="logo" width={34} height={34} />
              <S.PublicTitle>Form sent successfully</S.PublicTitle>

              <S.PublicContainerCol style={{ textAlign: 'center' }}>
                <img
                  src={FormSent}
                  style={{
                    height: '275px',
                    marginTop: '24px',
                    marginBottom: '44px',
                  }}
                  alt="formSentImage"
                />
                Thank you for contacting us. <br /> We&apos;ll get back to you
                as soon as possible.
                <br />
                <br />
                <div style={{ maxWidth: 377 }}>
                  If you need further help, please reach out to us at
                </div>
                <a href="mailto:help@fenixcertify.com">help@fenixcertify.com</a>
              </S.PublicContainerCol>
              <Button
                style={{ width: '100%', marginTop: 42 }}
                className="button-primary"
                type="button"
                onClick={() => {
                  navigate('/login');
                }}
              >
                Go to login
              </Button>
            </>
          )}
        </S.PublicContainerCol>
      </NoAuthBasePage>
    </>
  );
};

export default withHelmet(ContactPage);
