import axios, { AxiosError } from 'axios';
import {
  AUTH_REFRESH_TOKEN_COOKIE_NAME,
  AUTH_TOKEN_COOKIE_NAME,
} from 'constants/app.constants';
import Cookies from 'js-cookie';

import { envConfig } from 'config/config';
import { getCookie } from 'utils/cookies';
import { getLSWorkspaceBusiness } from 'utils/workspace.util';

import { AuthTokenError } from './errors/AuthTokenError';

type FailedRequestQueue = {
  onSuccess(token: string): void;
  onFailure(err: AxiosError): void;
};

type ISession = {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  refresh_expires_in: number;
};

let isRefreshing = false;
let failedRequestQueue: FailedRequestQueue[] = [];

export interface ApiError {
  statusCode: number;
  error: string;
  data: unknown;
}

const api = axios.create({
  baseURL: envConfig.REACT_APP_BACKEND_URL,
});

api.interceptors.request.use(async (config) => {
  if (config.headers) {
    config.headers['source-client'] = 'app';

    // Get current business for all requests
    const currentBusiness = getLSWorkspaceBusiness();
    if (currentBusiness) config.headers['business-id'] = currentBusiness;
  }

  const token = getCookie(AUTH_TOKEN_COOKIE_NAME);

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      const refreshToken = getCookie(AUTH_REFRESH_TOKEN_COOKIE_NAME);

      if (refreshToken) {
        const originalConfig = error.config;

        if (!isRefreshing) {
          isRefreshing = true;

          api
            .put<ISession>('/auth', {
              refreshToken,
            })
            .then(({ data }) => {
              const {
                access_token,
                expires_in,
                refresh_token,
                refresh_expires_in,
              } = data;

              Cookies.set(AUTH_TOKEN_COOKIE_NAME, access_token, {
                expires: expires_in,
              });
              Cookies.set(AUTH_REFRESH_TOKEN_COOKIE_NAME, refresh_token, {
                expires: refresh_expires_in,
              });

              api.defaults.headers.common[
                'Authorization'
              ] = `Bearer ${access_token}`;

              failedRequestQueue.forEach((request) =>
                request.onSuccess(access_token)
              );
              failedRequestQueue = [];
            })
            .catch((err) => {
              failedRequestQueue.forEach((request) => request.onFailure(err));
              failedRequestQueue = [];
              logout();
            })
            .finally(() => {
              isRefreshing = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequestQueue.push({
            onSuccess: (token: string) => {
              if (originalConfig.headers)
                originalConfig.headers['Authorization'] = `Bearer ${token}`;

              resolve(api(originalConfig));
            },
            onFailure: (err: AxiosError) => {
              reject(err);
            },
          });
        });
      } else {
        logout();
        return Promise.reject(new AuthTokenError());
      }
    }

    if (
      error.response?.status === 400 &&
      error.response?.data?.error === 'MissingHeaderException'
    ) {
      logout();
    }

    return Promise.reject(error);
  }
);

const logout = () => {
  Cookies.remove(AUTH_TOKEN_COOKIE_NAME);
  Cookies.remove(AUTH_REFRESH_TOKEN_COOKIE_NAME);
  if (window.location.pathname !== '/login') {
    window.location.href = '/login';
  }
};

export default api;
