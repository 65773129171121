import React, { useEffect } from 'react';
import { APP_NAME } from 'constants/app.constants';

import LogoIcon from 'assets/images/logo/logo_color.png';
import useAuth from 'hooks/useAuth';
import withHelmet from 'hooks/withHelmet';

import { MenuMain } from 'components/Menu/MenuMain';

import { ReduxProps } from '.';
import * as S from './styles';
import UserMenu from './UserMenu';

import './styles.css';

interface MainLayoutProps extends ReduxProps {
  pageTitle: string;
  hideHeader?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  pageTitle,
  children,
  hideHeader,
}) => {
  const { user, loadMe } = useAuth();

  useEffect(() => {
    document.title = `${APP_NAME} - ${pageTitle}`;
  }, [pageTitle]);

  useEffect(() => {
    if (!user) {
      loadMe();
    }
  }, [user]);

  return (
    <S.Main>
      {!hideHeader && (
        <S.Header>
          <S.HeaderLogo src={LogoIcon} />
          <MenuMain />
          <UserMenu picture={user?.picture} />
        </S.Header>
      )}
      <S.Content>{children}</S.Content>
    </S.Main>
  );
};

export default withHelmet(MainLayout, '#FFFFFF');
