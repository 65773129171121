import React, { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { RoleEnum, SettingsBusinessInfo } from 'store/@types/user';

import { Button, Col, Form, Row } from 'react-bootstrap';

import { apiDeleteBusinessImage, apiSetBusinessImage } from 'api/business';
import useAuth from 'hooks/useAuth';
import { Business } from 'interfaces/Business';
import api from 'services/api';
import { defaultToast } from 'utils/toast';

import { TextField, UploadPhoto } from 'components/Form';

interface BusinessInformationProps {
  setIsLoading: (isLoading: boolean) => void;
  handleBusinessFormReset: (data: SettingsBusinessInfo) => void;
}

const BusinessInformation: FC<BusinessInformationProps> = ({
  setIsLoading,
  handleBusinessFormReset,
}) => {
  const { user, currentBusiness, currentRole, reload } = useAuth();
  const methodsBusiness = useFormContext();
  const canEdit = currentRole === RoleEnum.SOLO;

  function saveBusiness(values: Omit<Business, 'logoPhoto' | 'schemeLogo'>) {
    if (!user) return;

    if (currentBusiness) {
      api
        .put(`/business/${currentBusiness}`, values)
        .then(({ data }) => handleBusinessFormReset(data))
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          reload({ reloadBusiness: true, reloadWorkspaces: true });
          setIsLoading(false);
        });
    } else {
      api
        .post('business', values)
        .then(() => {
          defaultToast('S');
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          reload({ reloadBusiness: true, reloadWorkspaces: true });
          setIsLoading(false);
        });
    }
  }

  return (
    <Form
      id="settings-business-info"
      onSubmit={methodsBusiness.handleSubmit((data) => {
        setIsLoading(true);
        saveBusiness(data);
      })}
      style={{
        width: '100%',
        maxWidth: 1110,
      }}
    >
      <Row sm={1} lg={2}>
        <TextField
          xs={3}
          required
          label="Business name"
          fieldId="organizationName"
        />
        <TextField
          xs={3}
          required
          label="Registration number"
          fieldId="registrationNumber"
        />
      </Row>
      <Row sm={1} lg={2}>
        <TextField
          xs={3}
          required
          label="Address"
          fieldId="organizationAddress"
        />
        <TextField
          xs={3}
          required
          label="Postcode"
          fieldId="organizationPostal"
        />
      </Row>
      <Row sm={1} lg={2}>
        <TextField xs={3} required label="Email" fieldId="organizationEmail" />
      </Row>
      <Row sm={1} lg={2}>
        <UploadPhoto
          xs={3}
          height="240px"
          inputSize={3}
          fieldId="logoPhoto"
          label="Title"
          onload={(file, base64Data) => {
            apiSetBusinessImage(currentBusiness, {
              logoPhoto: file,
            });
            methodsBusiness.setValue('logoPhoto', base64Data, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            });
          }}
          onDelete={() => {
            apiDeleteBusinessImage(currentBusiness, 'logoPhoto');
          }}
        />
        <UploadPhoto
          xs={3}
          inputSize={3}
          height="240px"
          fieldId="schemeLogo"
          label="Scheme / Trade association logo"
          onload={(file, base64Data) => {
            apiSetBusinessImage(currentBusiness, {
              schemeLogo: file,
            });
            methodsBusiness.setValue('schemeLogo', base64Data, {
              shouldValidate: true,
              shouldDirty: true,
              shouldTouch: true,
            });
          }}
          onDelete={() => {
            apiDeleteBusinessImage(currentBusiness, 'schemeLogo');
          }}
        />
      </Row>
      {canEdit && (
        <Row xs={1} md={2}>
          <Col md={12} lg={12}>
            <Button
              className="button-primary float-right"
              type="submit"
              disabled={
                !methodsBusiness.formState.isDirty ||
                Object.keys(methodsBusiness.formState.errors).length > 0
              }
            >
              Save changes
            </Button>
          </Col>
        </Row>
      )}
      {/*
      <TextField xs={3} required label="Email" fieldId="organizationEmail" />
      <TextField xs={3} label="Phone" fieldId="phoneNumber" />
      <TextField xs={3} label="Fax" fieldId="fax" />
     */}
    </Form>
  );
};

export default BusinessInformation;
