import { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { SettingsUserSignatureInfo } from 'store/@types/user';

import { Button, Col, Form, Row } from 'react-bootstrap';

import { FieldType } from 'enum/FieldType.enum';
import useAuth from 'hooks/useAuth';
import api from 'services/api';
import { defaultToast } from 'utils/toast';

import { Signature, TextField } from 'components/Form';

interface SignatureAndRoleProps {
  setIsLoading: (isLoading: boolean) => void;
}

const SignatureAndRole: FC<SignatureAndRoleProps> = ({ setIsLoading }) => {
  const methodsSignatureAndRole = useFormContext<SettingsUserSignatureInfo>();
  const { user, loadMe, reload } = useAuth();

  const updateSignature = useCallback(() => {
    methodsSignatureAndRole.trigger();
    const data = methodsSignatureAndRole.getValues('presetSignature');
    setIsLoading(true);
    api
      .put(`signature/${data._id}`, { default: true })
      .then(() => {
        defaultToast('S');
        reload({ reloadSignatures: true });
      })
      .catch(() => {
        defaultToast(
          'E',
          'We’re having trouble setting your default signature. Please try again later.'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  function saveUser(
    values: Omit<SettingsUserSignatureInfo, 'presetSignature'>
  ) {
    if (user?._id) {
      setIsLoading(true);
      api
        .patch(`/user/me`, values)
        .then(() => {
          defaultToast('S');
        })
        .finally(() => {
          loadMe(true);
          setIsLoading(false);
        });
    }
  }

  return (
    <Form
      id="settings-your-account-form"
      onSubmit={methodsSignatureAndRole.handleSubmit((data) => {
        saveUser(data);
      })}
      style={{
        width: '100%',
        maxWidth: 1110,
      }}
      onClick={() => {
        methodsSignatureAndRole.trigger();
      }}
    >
      <Row xs={1} style={{ maxWidth: 357 }}>
        <TextField xs={12} label="Role" fieldId="position" required />
      </Row>
      <Row xs={1} style={{ maxWidth: 357 }}>
        <Signature
          xs={12}
          type="select"
          componentType={FieldType.Signature}
          required
          submitNewSignature
          fieldId="presetSignature"
          label="Pre-set digital signature"
          handleClose={updateSignature}
        />
      </Row>
      <Row xs={1}>
        <Col xs={12}>
          <Button
            style={{ marginTop: 55 }}
            id="settings-signature-role-info-submit"
            className="button-primary float-right"
            type="submit"
            disabled={
              !methodsSignatureAndRole.formState.isDirty ||
              Object.keys(methodsSignatureAndRole.formState.errors).length > 0
            }
          >
            Save changes
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SignatureAndRole;
