import React, { useEffect, useState } from 'react';
import { disabledFieldOnStates } from 'constants/certificate.constants';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { RoleEnum } from 'store/@types/user';

import comboIcon from 'assets/images/iconset/combo-arrow-down.svg';
import useAuth from 'hooks/useAuth';
import useCerts from 'hooks/useCerts';
import { CertTemplateField } from 'interfaces/CertificateTemplate';
import { CircuitsState, SelectedTableIndexes } from 'interfaces/Circuits';
import {
  buildCircuitsState,
  getCircuitTemplateField,
} from 'utils/certificates/circuitsUtils';

import CircuitBodyColumns from './CircuitBodyColumns';
import CircuitHeaderColumns from './CircuitHeaderColumns';
import { FixedTableHeaders, TableHeaders } from './CircuitHeaderColumns/styles';
import SelectItem from './SelectItem';
import {
  Container,
  FixedTableRow,
  Table,
  TableRow,
  TableRowBody,
  TableRowHeader,
} from './styles';

const CircuitTable: React.FC = () => {
  const {
    templateFields,
    certData,
    affectedFields,
    setCertLoading,
    setSelectedCircuitIndexes,
    setCircuitsId,
  } = useCerts();

  const { getValues } = useFormContext();
  const [match] = useSearchParams();
  const [state, setState] = useState<CircuitsState>();
  const { currentRole } = useAuth();

  const fieldDisabled =
    (certData && disabledFieldOnStates.includes(certData?.certificateState)) ||
    currentRole === RoleEnum.MANAGER;

  // FIND ALL NEEDED DATA FOR BUILD THE CIRCUITS TABLE
  useEffect(() => {
    const page = match.get('page');
    if (!page) return;

    const affectedField = Object.entries(affectedFields).find(
      ([key, value]) => key.includes(page) && value.tableStructure?.length
    );
    if (!affectedField) return;
    const [key, value] = affectedField;

    if (value.tableStructure) {
      setState(buildCircuitsState(key, value.tableStructure, getValues(key)));
    }

    setCircuitsId(key);
  }, []);

  useEffect(() => {
    state && setCertLoading(false);
  }, [state]);

  if (!state || !templateFields || !certData || !affectedFields) return null;

  const {
    circuitId,
    tableStructureFields,
    tableStructureValues,
    tableStructHeaders,
    tableColumnStyles,
  } = state;

  // FIND TEMPLATE FIELD BY ROW AND COLUMN
  const getTemplateField = (
    tableIndex: number,
    indexRow: number,
    indexColumn: number
  ): CertTemplateField =>
    getCircuitTemplateField(
      tableStructureFields,
      templateFields,
      circuitId,
      tableIndex,
      indexRow,
      indexColumn
    );

  const selectIndexes = (indexes: SelectedTableIndexes) => {
    setSelectedCircuitIndexes(indexes);
  };

  return (
    <Container>
      <Table>
        <TableRowHeader>
          {/* GENERATE FIXED TABLE HEADER COLUMNS < 2 */}
          <FixedTableHeaders icon={comboIcon}>
            <CircuitHeaderColumns
              tableStructHeaders={tableStructHeaders.slice(0, 2)}
              tableColumnStyles={tableColumnStyles}
            />
          </FixedTableHeaders>

          {/* GENERATE NORMAL TABLE HEADER COLUMNS >= 3 */}
          <TableHeaders>
            <CircuitHeaderColumns
              tableStructHeaders={tableStructHeaders.slice(2)}
              tableColumnStyles={tableColumnStyles}
            />
          </TableHeaders>
        </TableRowHeader>

        {tableStructureValues.map((table) => {
          const tableKey = table.index + '-table> ';
          const rows =
            table.merged && table.mergedRow ? [table.mergedRow] : table.rows;

          return rows.map((row) => {
            const rowKey = tableKey + row.index + '-row> ';
            return (
              <TableRowBody key={rowKey}>
                {/* GENERATE FIXED TABLE BODY COLUMNS < 2 */}
                <FixedTableRow>
                  <SelectItem
                    fixed
                    type="row"
                    tableIndex={table.index}
                    rowIndex={row.index}
                  >
                    <CircuitBodyColumns
                      tableColumnStyles={tableColumnStyles}
                      fieldDisabled={fieldDisabled}
                      getTemplateField={getTemplateField}
                      selectIndexes={selectIndexes}
                      columns={row.columns.slice(0, 2)}
                      tableIndex={table.index}
                      rowIndex={row.index}
                      rowKey={rowKey}
                    />
                  </SelectItem>
                </FixedTableRow>

                {/* GENERATE NORMAL TABLE BODY COLUMNS >= 3 */}
                <TableRow>
                  <SelectItem
                    type="row"
                    tableIndex={table.index}
                    rowIndex={row.index}
                  >
                    <CircuitBodyColumns
                      tableColumnStyles={tableColumnStyles}
                      fieldDisabled={fieldDisabled}
                      getTemplateField={getTemplateField}
                      selectIndexes={selectIndexes}
                      columns={row.columns.slice(2)}
                      tableIndex={table.index}
                      rowIndex={row.index}
                      rowKey={rowKey}
                    />
                  </SelectItem>
                </TableRow>
              </TableRowBody>
            );
          });
        })}
      </Table>
    </Container>
  );
};

export default CircuitTable;
