export const formatDateCustom = (dateString: Date) => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const dateObj = new Date(dateString);

  const month = months[dateObj.getMonth()];
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();

  return `${month} ${day < 10 ? '0' : ''}${day} ${year}`;
};

export const formatDateShort = (dateString?: string | null) => {
  if (dateString) {
    const date = new Date(dateString).toLocaleDateString('pt-PT', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
    });

    return date;
  }
  return '';
};

export const formatDateUtil = (params: {
  dateString?: string | null;
  fullYear?: boolean;
  short?: boolean;
}) => {
  const { dateString, fullYear, short } = params;
  if (dateString) {
    if (short) {
      const date = new Date(dateString).toLocaleDateString('pt-PT', {
        day: '2-digit',
        month: '2-digit',
        year: fullYear ? 'numeric' : '2-digit',
      });

      return date;
    }
  }
  return '';
};
