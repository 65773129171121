import { FC, useState } from 'react';

import EmptyImage from 'assets/images/empty_image.svg';
import AddItemIcon from 'assets/images/iconset/add.svg';
import AddWhiteItemIcon from 'assets/images/iconset/add_white.svg';
import useAuth from 'hooks/useAuth';
import { EquipmentProps } from 'interfaces/Settings';
import api from 'services/api';
import { defaultToast } from 'utils/toast';

import ModalConfirm from 'components/modals/ModalConfirm';
import SecondaryButton from 'components/SecondaryButton';

import ModalWorkEquipment from './ManageWorkEquipment';
import * as S from './styles';
import WorkEquipmentContent from './WorkEquipmentContent';

interface WorkEquipmentProps {
  setIsLoading: (isLoading: boolean) => void;
  noEquipsError: boolean;
}

const WorkEquipment: FC<WorkEquipmentProps> = ({
  setIsLoading,
  noEquipsError,
}) => {
  const { equipmentData, reload } = useAuth();
  const [addWorkEquipModal, setAddWorkEquipModal] = useState(false);
  const [editWorkEquip, setEditWorkEquip] = useState<
    EquipmentProps | undefined
  >(undefined);

  const [showDeleteWorkEquipment, setShowDeleteWorkEquipment] = useState<
    string | undefined
  >(undefined);

  const clearStates = () => {
    // close delete modal
    setShowDeleteWorkEquipment(undefined);

    // close add/edit modal
    setAddWorkEquipModal(false);
    setEditWorkEquip(undefined);
  };

  const deleteEquipment = async () => {
    if (!equipmentData) return;
    setIsLoading(true);

    // clear states
    clearStates();

    await api.delete(`equipment/${showDeleteWorkEquipment}`);

    const equipment = equipmentData?.find(
      (e) => e._id === showDeleteWorkEquipment
    );
    if (equipment?.default && equipmentData) {
      const newDefault = equipmentData.filter(
        (e) => e._id !== showDeleteWorkEquipment
      )[0];

      if (newDefault) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { _id, user, createdAt, updatedAt, __v, ...newVal } = newDefault;
        await api.put(`equipment/${_id}`, {
          ...newVal,
          default: true,
        });
      }
    }
    reload({ reloadEquipments: true });
    setIsLoading(false);
    defaultToast('S');
  };

  return (
    <>
      <S.SettingsContainer>
        <S.EquipmentHeader>
          <span>Equipment list</span>
          <SecondaryButton
            label="Add new"
            icon={AddItemIcon}
            iconHover={AddWhiteItemIcon}
            handleAction={() => {
              setAddWorkEquipModal(true);
            }}
          />
        </S.EquipmentHeader>
        {!equipmentData?.length && (
          <S.EmptyStateContainer isError={noEquipsError}>
            <img src={EmptyImage} alt="no itens" width={79} height={73} />
            <span>No test equipment found...</span>
          </S.EmptyStateContainer>
        )}
        {equipmentData && equipmentData.length > 0 && (
          <WorkEquipmentContent
            deleteEquipment={(id) => {
              setShowDeleteWorkEquipment(id);
            }}
            setEditWorkEquip={(p: EquipmentProps | undefined) =>
              setEditWorkEquip(p)
            }
            equipmentData={equipmentData}
          />
        )}
      </S.SettingsContainer>

      {/* DELETE WORK EQUIPMENT CONFIRMATION MODAL */}
      {showDeleteWorkEquipment && (
        <ModalConfirm
          title="Remove test equipment?"
          text={
            <>
              Are you sure you want to remove this equipment from your list?
              <br />
              <br />
              This action cannot be undone.
            </>
          }
          cancelText="Remove"
          actionCancel={() => {
            deleteEquipment();
          }}
          confirmText="Cancel"
          actionConfirm={() => {
            // close delete modal
            setShowDeleteWorkEquipment(undefined);
          }}
        />
      )}

      {(editWorkEquip || addWorkEquipModal) && (
        <ModalWorkEquipment
          setIsLoading={(v) => setIsLoading(v)}
          isEdit={editWorkEquip}
          equipmentData={equipmentData || []}
          deleteEquipment={(id) => setShowDeleteWorkEquipment(id)}
          resetEquipment={() => {
            // clear states
            clearStates();
            // refresh equipment data
            reload({ reloadEquipments: true });
          }}
          action={() => {
            // clear states
            clearStates();
          }}
        />
      )}
    </>
  );
};

export default WorkEquipment;
