import { CSSProperties, FC } from 'react';

import * as S from './styles';

interface LinkButtonProps {
  handleAction: () => void;
  label?: string;
  icon?: string;
  style?: CSSProperties;
}

const LinkButton: FC<LinkButtonProps> = ({
  style,
  icon,
  label,
  handleAction,
}) => {
  return (
    <S.LinkButton onClick={handleAction} style={style} type="button">
      {icon && <img src={icon} alt={label} width={16} height={16} />}
      {label && <S.LinkButtonLabel>{label}</S.LinkButtonLabel>}
    </S.LinkButton>
  );
};

export default LinkButton;
