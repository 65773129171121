import { FC, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Button, Col, Row } from 'react-bootstrap';

import {
  apiSoloSubscriptionPrices,
  apiSubscriptionManager,
  apiSubscriptionPayment,
} from 'api/payment';
import EmptyImage from 'assets/images/empty_image.svg';
import useAuth from 'hooks/useAuth';
import { StripeSubscriptionPrices } from 'interfaces/Subscription';
import { defaultToast } from 'utils/toast';

import { ModalSubscription } from 'components/modals/ModalSubscription';
import SubscriptionCard from 'components/SubscriptionCard';

import * as S from './styles';

interface SubscriptionProps {
  setIsLoading: (isLoading: boolean) => void;
}

const Subscription: FC<SubscriptionProps> = ({ setIsLoading }) => {
  const [subscriptions, setSubscriptions] = useState<
    StripeSubscriptionPrices[]
  >([]);
  const [showModal, setShowModal] = useState(false);
  const [currentSubscription, setCurrentSubscription] =
    useState<StripeSubscriptionPrices>();
  const { user, loadMe } = useAuth();
  const isNew = !user?.subscription?.priceId;
  const { t } = useTranslation();

  const loadPrices = () => {
    setIsLoading(true);
    apiSoloSubscriptionPrices()
      .then((data) => {
        setSubscriptions(data.web);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateCurrentSubscription = (data: StripeSubscriptionPrices[]) => {
    if (user?.subscription?.priceId) {
      const currentSubscription = data.find(
        (sub) => sub.product === user?.subscription?.type
      );
      if (currentSubscription) {
        setCurrentSubscription(currentSubscription);
      }
    }
  };

  const subscriptionCheckout = (priceId: string, currency: string) => {
    setIsLoading(true);
    const baseCertificateUrl = window.location.pathname;

    apiSubscriptionPayment({
      successUrlPath: `${baseCertificateUrl}?tab=app-subscription&success=true`,
      cancelUrlPath: `${baseCertificateUrl}?tab=app-subscription&success=false`,
      priceId,
      currency,
    })
      .then((data) => {
        if (data.session.url) {
          window.location.href = data.session.url;
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        defaultToast('E');
      });
  };

  const manageSubscription = () => {
    setIsLoading(true);

    apiSubscriptionManager(`settings?tab=app-subscription`)
      .then((data) => {
        if (data.session.url) {
          window.location.href = data.session.url;
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
        defaultToast('E');
      });
  };

  useEffect(() => {
    loadPrices();
    loadMe(true);
  }, []);

  useEffect(() => {
    updateCurrentSubscription(subscriptions);
  }, [subscriptions]);

  return (
    <>
      <div
        style={{
          width: '100%',
        }}
      >
        {!currentSubscription && (
          <S.EmptyStateContainer>
            <img
              src={EmptyImage}
              alt="no subscription"
              width={79}
              height={73}
            />
            <span> You don’t have an active subscription.</span>
            <span>Choose the subscription that better fits your needs.</span>
          </S.EmptyStateContainer>
        )}
        {currentSubscription && (
          <Row>
            <Col xs={12} style={{ maxWidth: 770 }}>
              <S.SubTitle>{t('subscription_active_plan_label')}</S.SubTitle>
              <SubscriptionCard
                subscription={currentSubscription}
                currentUserSubscription={user?.subscription}
                onClick={() => null}
              />
            </Col>
          </Row>
        )}
        <Row xs={1} md={2}>
          <Col md={12} lg={12} style={{ marginTop: 160 }}>
            <Button
              className={clsx('button-primary float-right', {
                hidden:
                  user?.subscription && user?.subscription?.origin !== 'web',
              })}
              onClick={() => {
                if (isNew) {
                  setShowModal(true);
                } else {
                  manageSubscription();
                }
              }}
            >
              {isNew ? 'Subscribe' : 'Manage subscription'}
            </Button>
          </Col>
        </Row>
      </div>
      {showModal && (
        <ModalSubscription
          onCloseModal={() => {
            setShowModal(false);
          }}
          subscriptions={subscriptions}
          isNew={true}
          handleOnSave={(priceId, currency) => {
            subscriptionCheckout(priceId, currency);
          }}
        />
      )}
    </>
  );
};

export default Subscription;
