import React, { createContext, FC, useEffect, useMemo, useState } from 'react';
import { AUTH_TOKEN_COOKIE_NAME } from 'constants/app.constants';
import {
  LoginResponse,
  RoleEnum,
  SettingsUserRequiredInfo,
  User,
  UserAuth,
  UserRole,
} from 'store/@types/user';

import { apiListAllSignatures } from 'api/signature';
import { apiLoadMe } from 'api/user';
import { Business } from 'interfaces/Business';
import { Customer } from 'interfaces/Customer';
import { ReloadParams } from 'interfaces/ReloadParams';
import { EquipmentProps } from 'interfaces/Settings';
import { Signature } from 'interfaces/Signature';
import api from 'services/api';
import { getCookie } from 'utils/cookies';
import { getInitialUserRole, userHasWorkspace } from 'utils/userRoles';
import { verifyUserInfo } from 'utils/verifyUserInfo';
import {
  buildWorkspacesData,
  getLSWorkspaceBusiness,
  getLSWorkspaceRole,
  setLSWorkspaceBusiness,
  setLSWorkspaceRole,
  setLSWorkspaces,
} from 'utils/workspace.util';

import { ReduxProps } from '.';

export const AuthContext = createContext<AuthProps>({} as AuthProps);

const AuthProvider: FC<ReduxProps> = ({ children }) => {
  const [auth, setAuth] = useState<UserAuth | undefined>();
  const [user, setUser] = useState<User | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [business, setBusiness] = useState<Business>();
  const [userSignatures, setUserSignatures] = useState<Signature[]>();
  const [customers, setCustomers] = useState<Customer[]>();
  const [equipmentData, setEquipmentData] = useState<EquipmentProps[]>();

  const loadCustomers = () => {
    if ([RoleEnum.LEAD, RoleEnum.SOLO].includes(getLSWorkspaceRole())) {
      setIsLoading(true);
      api
        .get('/customer')
        .then(({ data }) => {
          setIsLoading(false);
          setCustomers(data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const loadBusiness = () => {
    const currentBusiness = getLSWorkspaceBusiness();
    if (currentBusiness) {
      setIsLoading(true);
      api
        .get(`business/${getLSWorkspaceBusiness()}`)
        .then(({ data }: { data: Business }) => {
          setIsLoading(false);
          setBusiness(data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const loadUserSignatures = () => {
    setIsLoading(true);
    apiListAllSignatures()
      .then((data) => {
        setIsLoading(false);
        setUserSignatures(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const loadEquipments = () => {
    setIsLoading(true);
    api
      .get('equipment')
      .then(({ data }) => {
        setIsLoading(false);
        setEquipmentData(data);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!user) return;
    if (!customers) {
      loadCustomers();
    }
    if (!business) {
      loadBusiness();
    }
    if (!userSignatures) {
      loadUserSignatures();
    }
    if (!equipmentData) {
      loadEquipments();
    }
  }, [user]);

  const loadWorkspacesFromBusiness = (user: User) => {
    setIsLoading(true);
    api
      .get('business')
      .then(({ data }: { data: Business[] }) => {
        const workspaces = buildWorkspacesData(data, user.roles);
        setLSWorkspaces(workspaces);

        // If doesn't exists a business, then reload again
        const currentWorkspaceBusiness = getLSWorkspaceBusiness();
        const existBusiness = workspaces.some(
          (workSpace) => workSpace.businessId === currentWorkspaceBusiness
        );

        if (!existBusiness) {
          loadMe(true).then((loadedUser) => {
            const userRole = getInitialUserRole(loadedUser);
            userRole && updateCurrentWorkSpace(userRole);
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const isAuthenticated = () => {
    const userToken = getCookie(AUTH_TOKEN_COOKIE_NAME) as string;
    return !!userToken;
  };

  const checkRedirect = (page?: string): string | null => {
    if (!user) return null;

    const { onboardingComplete, isEmailValidated, email } = user;

    if (!isEmailValidated) {
      return `/unverified/${auth?.access_token}/${email}`;
    }

    if (!onboardingComplete) {
      return '/onboarding';
    } else {
      if (page && page === 'onboarding') return '/home';
    }
    return null;
  };

  const setLogin = ({ user, ...userAuth }: LoginResponse) => {
    setAuth(userAuth);
    setUser(user);

    const hasWorkspaces = userHasWorkspace(user.roles);

    loadWorkspacesFromBusiness(user);
    if (!hasWorkspaces) {
      const userRole = getInitialUserRole(user);
      userRole && updateCurrentWorkSpace(userRole);
    }
    reload({ reloadAll: true });
  };

  const loadMe = async (ignoreOnboarding = false): Promise<User> => {
    if (user && !ignoreOnboarding) return user;
    const data = await apiLoadMe();

    setUser(data);
    if (auth) {
      setLogin({
        ...auth,
        user: data,
      });
    }
    return data;
  };

  const userHasRequiredInfoFilled = () =>
    verifyUserInfo(user, business, userSignatures, equipmentData);

  const updateCurrentWorkSpace = (userRole: UserRole) => {
    setLSWorkspaceBusiness(userRole.businessId);
    setLSWorkspaceRole(userRole.role);
  };

  const reload = ({
    reloadAll,
    reloadCustomers,
    reloadBusiness,
    reloadSignatures,
    reloadEquipments,
    reloadWorkspaces,
  }: ReloadParams) => {
    if (reloadCustomers || reloadAll) {
      loadCustomers();
    }
    if (reloadBusiness || reloadAll) {
      loadBusiness();
    }
    if (reloadSignatures || reloadAll) {
      loadUserSignatures();
    }
    if (reloadEquipments || reloadAll) {
      loadEquipments();
    }
    if (user && business && (reloadWorkspaces || reloadAll)) {
      loadWorkspacesFromBusiness(user);
    }
  };

  const contextValue = useMemo<AuthProps>(
    () => ({
      auth,
      setLogin,
      user,
      authLoading: isLoading,
      business,
      currentBusiness: getLSWorkspaceBusiness(),
      currentRole: getLSWorkspaceRole(),
      updateCurrentWorkSpace,
      customers,
      userSignatures,
      checkRedirect,
      userHasRequiredInfoFilled,
      equipmentData,
      reload: (params: ReloadParams) => reload(params),
      isAuthenticated: isAuthenticated(),
      loadMe,
    }),
    [auth, user, business, equipmentData, userSignatures, customers]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export interface AuthProps {
  auth: UserAuth | undefined;
  setLogin: (loginResponse: LoginResponse) => void;
  user: User | undefined;
  business: Business | undefined;
  currentBusiness: string;
  currentRole: RoleEnum;
  updateCurrentWorkSpace: (userRole: UserRole) => void;
  customers: Customer[] | undefined;
  userSignatures: Signature[] | undefined;
  checkRedirect: (page?: string) => string | null;
  userHasRequiredInfoFilled: () => SettingsUserRequiredInfo;
  authLoading: boolean;
  isAuthenticated: boolean;
  equipmentData: EquipmentProps[] | undefined;
  loadMe: (ignoreOnboarding?: boolean) => Promise<User>;
  reload: (params: ReloadParams) => void;
}

export default AuthProvider;
