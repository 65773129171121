import styled from 'styled-components';

export const EquipmentItemsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 24px;
  gap: 16px;
`;

export const EquipmentItemCard = styled.div<{ isDefault: boolean }>`
  display: flex;
  width: 100%;
  height: 77px;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 3%;
  border-radius: 10px;
  border: 1px solid ${({ isDefault }) => (isDefault ? '#5488BE' : '#edf2f7')};
  background: #fff;
`;

export const ItemInfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
`;

export const ItemInfoItem = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 4px;
  padding-right: 5px;
  width: ${({ width }) => {
    if (width) {
      return width ?? `auto`;
    }
  }};

  span {
    font-style: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  span:nth-child(1) {
    color: #c1c1c1;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }

  span:nth-child(2) {
    color: #272727;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
  }
`;

export const ItemActionContainer = styled.div`
  display: flex;
  gap: 15%;
  width: 17%;
  max-width: 147px;
`;
