import { FC } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { CertificateState } from 'enum/certificateDataState.enum';
import { CertData } from 'interfaces/CertificateData';
import { formatDateUtil } from 'utils/dateUtils';

import CardDefault from 'components/CardDefault';
import CardItem from 'components/CardDefault/CardItem';
import CardTitle from 'components/CardDefault/CardTitle';
import EmptyState from 'components/EmptyState';

import * as S from './styles';

interface DraftListProps {
  items: CertData[];
}

const DraftList: FC<DraftListProps> = ({ items }) => {
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const duplicatedId = query.get('duplicated');

  return (
    <CardDefault styles={{ maxHeight: 310, height: '100%' }}>
      <S.DraftContainer>
        <CardTitle>Recent drafts</CardTitle>
        <S.DraftItemContainer>
          {items.length === 0 && (
            <EmptyState label="" imageWidth={87} imageHeight={96} />
          )}
          {items
            ?.filter(({ certificateState }) => {
              return certificateState === CertificateState.DRAFT;
            })
            .slice(0, 3)
            .map((item) => {
              return (
                <CardItem key={item._id} highlight={duplicatedId === item._id}>
                  <S.DraftCardItemContainer
                    onClick={() => {
                      navigate(
                        `/manage-certificate/${item.template?._id}/${item._id}`
                      );
                    }}
                  >
                    <S.DraftItem>
                      <span>{item.template?.certificateName}</span>
                      <span>
                        {formatDateUtil({
                          dateString: String(item.updatedAt),
                          short: true,
                        })}
                      </span>
                    </S.DraftItem>
                    <span>{item.customer?.name}</span>
                  </S.DraftCardItemContainer>
                </CardItem>
              );
            })}
        </S.DraftItemContainer>
      </S.DraftContainer>
    </CardDefault>
  );
};

export default DraftList;
