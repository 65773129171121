import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RoleEnum } from 'store/@types/user';

import { Button, Col, Container, Row } from 'react-bootstrap';

import useAuth from 'hooks/useAuth';
import { Customer } from 'interfaces/Customer';
import api from 'services/api';
import { objectToFormData } from 'utils/formUtils';
import { deleteEmptyStrings } from 'utils/index';
import { defaultToast } from 'utils/toast';

import MenuBack from 'components/Menu/MenuBack';
import MainLayout from 'components/root/MainLayout';
import { ContentWrapper } from 'components/root/MainLayout/styles';
import SplashScreen from 'components/root/SplashScreen/index';

import AdditionalContactInfo from './AdditionalContactInfo';
import BillingInfo from './BillingInfo';
import BranchesSecndAddress from './BranchesSecndAddress';
import CustomerInfo from './CustomerInfo';
import DeleteCustomer from './DeleteCustomer';
import MainAddress from './MainAddress';
import { defaultValues, yupSchema } from './yupSchema';

function ManageCustomers() {
  const { reload, currentRole } = useAuth();
  const canEdit = currentRole === RoleEnum.SOLO;
  const navigate = useNavigate();
  const [query] = useSearchParams();
  const [pictureFile, setPictureFile] = useState<File | null>(null);
  const { customerId, templateId, certDataId } = useParams<{
    customerId: string;
    templateId: string;
    certDataId: string;
    home: string;
  }>();
  const isEdit = customerId;

  const [isLoading, setIsLoading] = useState(false);

  // React hook form instance
  const formMethods = useForm<Customer>({
    mode: 'onTouched',
    resolver: yupResolver(yupSchema),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  const locations = useFieldArray<Customer, 'branchAddresses'>({
    control: formMethods.control,
    name: 'branchAddresses',
  });

  useEffect(() => {
    if (isEdit) {
      api
        .get(`customer/${isEdit}`)
        .then(({ data }) => formMethods.reset({ ...data, ogName: data.name }));
    }
  }, [isEdit]);

  const onSubmit = (values: Customer) => {
    setIsLoading(true);
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const {
      _id,
      __v,
      createdAt,
      updatedAt,
      ogName,
      confirmDelete1,
      confirmDelete2,
      picture,
      team,
      business,
      ...rest
    } = values;

    const formData = objectToFormData(deleteEmptyStrings(rest));
    // set the picture file
    if (pictureFile) {
      formData.append('picture', pictureFile);
    }

    if (isEdit) {
      api
        .put(`customer/${isEdit}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          defaultToast('S');
          reload({ reloadCustomers: true });
          navigate('/customers');
          setPictureFile(null);
        })
        .catch(() => {
          defaultToast('E');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      api
        .post('customer', formData)
        .then(({ data }) => {
          setPictureFile(null);
          const customerIdAdded = (data && (data as Customer)._id) || null;
          defaultToast('S');
          reload({ reloadCustomers: true });
          if (templateId && certDataId) {
            navigate(`/manage-certificate/${templateId}/${certDataId}`, {
              state: { customerIdAdded },
            });
            return;
          }
          if (templateId && !certDataId) {
            navigate(`/manage-certificate/${templateId}`, {
              state: { customerIdAdded },
            });
            return;
          }
          navigate('/');
        })
        .catch(() => {
          defaultToast('E');
        })
        .finally(() => setIsLoading(false));
    }
  };

  const deleteCustomer = () => {
    if (!isEdit) return;
    const { _id } = formMethods.watch();
    setIsLoading(true);
    api
      .delete(`/customer/${_id}`)
      .then(() => {
        reload({ reloadCustomers: true });
        defaultToast('S');
        navigate('/customers');
      })
      .catch(() => {
        defaultToast('E');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {isLoading && <SplashScreen />}
      <MainLayout
        pageTitle={isEdit ? 'Customer details' : 'New customer'}
        hideHeader
      >
        <MenuBack
          menuTitle={isEdit ? 'Customer details' : 'Add new customer'}
          handleBack={() => {
            const origin = query.get('origin');

            if (origin === 'home') {
              navigate('/home');
              return;
            }
            if (templateId && certDataId) {
              navigate(`/manage-certificate/${templateId}/${certDataId}`);
              return;
            }
            if (templateId && !certDataId) {
              navigate(`/manage-certificate/${templateId}`);
              return;
            }

            navigate('/customers');
          }}
        />
        <ContentWrapper>
          <Container style={{ minWidth: 700, maxWidth: 1000, marginTop: 20 }}>
            <FormProvider {...formMethods}>
              <form
                id="manage-customer-form"
                onSubmit={formMethods.handleSubmit((data) => {
                  onSubmit(data);
                })}
              >
                <CustomerInfo
                  customerId={customerId}
                  onLoad={(file) => {
                    setPictureFile(file);
                  }}
                />
                <AdditionalContactInfo />
                <MainAddress isEdit={!!isEdit} />
                <BranchesSecndAddress
                  canEdit={canEdit}
                  locations={locations}
                  setValue={(n: string, p: unknown) =>
                    formMethods.setValue(n as any, p, {
                      shouldTouch: true,
                      shouldValidate: true,
                      shouldDirty: true,
                    })
                  }
                />
                <BillingInfo />
                {canEdit && (
                  <Row className="mb-5">
                    <Col xs={12}>
                      <Button
                        className="button-primary float-right"
                        type="submit"
                      >
                        Save changes
                      </Button>
                    </Col>
                  </Row>
                )}
              </form>
            </FormProvider>
            {isEdit && canEdit && (
              <DeleteCustomer
                onDelete={() => {
                  deleteCustomer();
                }}
                ogName={formMethods.getValues('ogName')?.toUpperCase() ?? ''}
              />
            )}
            <Row className="mb-5" />
            <Row className="mb-5" />
          </Container>
        </ContentWrapper>
      </MainLayout>
    </>
  );
}

export default ManageCustomers;
