/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, FC, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { Col, Form, Modal, Row } from 'react-bootstrap';

import { TextField } from 'components/Form';
import Scrollbars from 'components/Scrollbars';

interface PickModalProps {
  modalTitle?: string;
  fieldId: string;
  current?: string;
  otherOption?: boolean;
  modalOptions: { key: string; value: string }[];
  actionCancel: () => void;
  actionConfirm: (text: string) => void;
}

export const PickModal: FC<PickModalProps> = ({
  fieldId,
  otherOption,
  current,
  modalTitle,
  actionCancel,
  actionConfirm,
  modalOptions,
}) => {
  const { getValues, setValue } = useFormContext();

  const isExistingOption = modalOptions.some((e) => e.key === current);
  const isOther = !isExistingOption && current && fieldId + 'otherOptions';
  const [selected, setSelected] = useState(
    (isExistingOption && current) || isOther || ''
  );

  useEffect(() => {
    if (isOther) {
      setValue(fieldId + 'otherOptions', current);
    } else {
      setValue(fieldId + 'otherOptions', '');
    }
  }, []);

  return (
    <Modal
      show
      backdrop="static"
      className="modal-md picker-modal"
      onHide={actionCancel}
    >
      <Modal.Header className="modal-default-header d-flex">
        <Modal.Title
          className="modal-header-left-action"
          onClick={actionCancel}
        >
          Cancel
        </Modal.Title>
        <Modal.Title className="modal-title">{modalTitle}</Modal.Title>
        <Modal.Title
          className="modal-header-right-action"
          onClick={() => {
            const selectedOption =
              getValues(selected) && getValues(selected)?.trim() !== ''
                ? getValues(selected).trim()
                : selected;
            actionConfirm(selectedOption);
          }}
        >
          Save
        </Modal.Title>
      </Modal.Header>
      <Scrollbars height="515px" style={{ marginLeft: 'unset' }}>
        <Modal.Body>
          <Row style={{ marginRight: '-15px' }}>
            {modalOptions.map((option) => {
              return (
                <Col
                  className="radio-box rigth"
                  xs={12}
                  key={'radio-component-option-' + option.key}
                >
                  <Form.Check
                    className="radiobutton right"
                    id={option.key}
                    value={option.key}
                    label={option.value}
                    type={'radio' as any}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setSelected(e.target.value);
                    }}
                    checked={option.key === selected}
                  />
                </Col>
              );
            })}
            {otherOption && (
              <Col className="radio-box rigth" xs={12}>
                <Form.Check
                  className="radiobutton right textfield"
                  id={fieldId + 'otherOptions'}
                  value={fieldId + 'otherOptions'}
                  label={
                    <TextField
                      onlyInput
                      disabled={selected !== fieldId + 'otherOptions'}
                      xs={12}
                      label=""
                      isCertificate={false}
                      fieldId={fieldId + 'otherOptions'}
                    />
                  }
                  type={'radio' as any}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setSelected(e.target.value);
                  }}
                  checked={fieldId + 'otherOptions' === selected}
                />
              </Col>
            )}
          </Row>
        </Modal.Body>
      </Scrollbars>
    </Modal>
  );
};
