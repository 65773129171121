/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeEvent, FC, useState } from 'react';

import { Col, Form, Modal, Row } from 'react-bootstrap';

import Scrollbars from 'components/Scrollbars';

interface PickModalProps {
  modalTitle?: string;
  keyParam?: string;
  otherOption?: boolean;
  modalOptions: { key: string; value: string }[];
  actionCancel: () => void;
  actionConfirm: (text: string[]) => void;
}

const PickModal: FC<PickModalProps> = ({
  keyParam,
  modalTitle,
  actionCancel,
  actionConfirm,
  modalOptions,
}) => {
  const [selected, setSelected] = useState<{ [key: string]: boolean }>({});

  return (
    <Modal
      show
      backdrop="static"
      className="modal-md picker-modal"
      onHide={actionCancel}
    >
      <Modal.Header className="modal-default-header d-flex">
        <Modal.Title
          className="modal-header-left-action"
          onClick={actionCancel}
        >
          Cancel
        </Modal.Title>
        <Modal.Title className="modal-title">{modalTitle}</Modal.Title>
        <Modal.Title
          className="modal-header-right-action"
          onClick={() => {
            actionConfirm(
              Object.keys(selected)
                .map((key) => (selected[key] ? key : null))
                .filter((e) => !!e) as string[]
            );
          }}
        >
          Save
        </Modal.Title>
      </Modal.Header>
      <Scrollbars height="515px" style={{ marginLeft: 'unset' }}>
        <Modal.Body>
          <Row style={{ marginRight: '-15px' }}>
            {modalOptions.map((option) => {
              const optionKey = (option as any)[keyParam as string];
              return (
                <Col
                  className="radio-box rigth"
                  xs={12}
                  key={'radio-component-modal-option-' + optionKey}
                >
                  <Form.Check
                    className="radiobutton right"
                    id={optionKey}
                    value={optionKey}
                    label={option.value}
                    type={'checkbox' as any}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                      setSelected({
                        ...selected,
                        [e.target.value]: !selected[e.target.value],
                      });
                    }}
                    checked={selected[option.value]}
                  />
                </Col>
              );
            })}
          </Row>
        </Modal.Body>
      </Scrollbars>
    </Modal>
  );
};

PickModal.defaultProps = {
  keyParam: 'key',
};
export { PickModal };
