import styled from 'styled-components';

import BootstrapModal from 'react-bootstrap/Modal';

export const Modal = styled(BootstrapModal)`
  .modal-dialog,
  .modal-content {
    width: 638px;
    height: max-content;
  }
  .modal-body {
    padding: 40px;
    width: 638px;
  }
`;

export const ModalBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px !important;
`;

export const ModalFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 357px;
  padding-top: 16px;
`;

export const ModalFooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ccdcec;
  padding: 24px;
`;

export const ModalTitle = styled.div`
  color: #272727;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const ModalSubTitle = styled.div`
  color: #2b3d4f;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
`;
