/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components';

export const ImgCertificateDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const ImgCertificateNotFound = styled.img`
  width: 335px;
  height: 360px;
`;

export const PreviewCertificateSection = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;

export const PreviewCertificateSectionSubTitle = styled.h3`
  padding: 0;
  width: 558px;
  height: 64px;
  font-weight: normal;
  text-align: center;
`;
