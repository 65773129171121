import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { Form, FormControlProps } from 'react-bootstrap';

import { FieldType } from 'enum/FieldType.enum';
import useCerts from 'hooks/useCerts';

import Comments from 'components/Comments';
import {
  FormControlFeedback,
  FormGroupWithErrorSpace,
  FormLabel,
  FormLabelContainer,
} from 'components/Form/styles';

import { getError, TextField } from '..';
import { CommonProps } from '../Types';

interface RadioButtonProps extends FormControlProps, CommonProps {
  isCertificate?: boolean;
  hasTextbox?: boolean;
}

const Checkbox: FC<RadioButtonProps> = ({
  xs,
  fieldId,
  label,
  disabled,
  isCertificate,
  auxLabel,
  hasTextbox,
  autoObservations,
  ...rest
}) => {
  const {
    control,
    getValues,
    setValue,
    setFocus,
    formState: { errors },
  } = useFormContext();

  const { autoSave, handleAutoObservations } = useCerts();

  const error = getError(fieldId, errors);

  const thisFieldId = isCertificate ? fieldId + '.value' : fieldId;
  const thisFieldIdText = isCertificate ? fieldId + '.valueTextbox' : fieldId;

  const handleOnChange = (checked: boolean) => {
    setValue(thisFieldId, checked);
    if (isCertificate) {
      setValue(thisFieldIdText, '', {
        shouldValidate: !checked,
      });
      const newVal = {
        ...getValues(fieldId),
        value: `${checked}`,
        valueTextbox: '',
      };

      autoSave({
        type: FieldType.Checkbox,
        fieldId,
        value: newVal,
        getValues,
        setValue,
      });
      if (autoObservations) {
        handleAutoObservations({
          fieldId,
          autoObservations,
          getValues,
          setValue,
        });
      }
    }
    hasTextbox && setTimeout(() => setFocus(thisFieldIdText), 200);
  };

  return (
    <Controller
      name={thisFieldId}
      control={control}
      render={({ field }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { size, ...others } = rest;
        return (
          <>
            <FormGroupWithErrorSpace
              xs={hasTextbox && xs ? xs / 2 : xs}
              height="auto"
            >
              {label && (
                <FormLabel disabled={disabled}>
                  <FormLabelContainer>
                    {label}
                    <Comments
                      fieldId={fieldId}
                      disabledField={disabled}
                      isCertificate={isCertificate}
                      handleOnChange={(comments) => {
                        autoSave({
                          type: FieldType.Checkbox,
                          fieldId,
                          value: {
                            ...getValues(fieldId),
                            value: `${getValues(thisFieldId)}`,
                            valueTextbox: getValues(thisFieldIdText),
                            comments,
                          },
                          getValues,
                          setValue,
                        });
                      }}
                    />
                  </FormLabelContainer>
                </FormLabel>
              )}
              <Form.Check
                {...field}
                inline
                className="checkbox"
                id={thisFieldId}
                label={auxLabel ?? ''}
                type={'checkbox' as any}
                disabled={disabled}
                isInvalid={Boolean(isCertificate ? error?.value : error)}
                checked={field.value}
                onChange={(e) => {
                  handleOnChange(e.target.checked);
                }}
                {...others}
              />
              <FormControlFeedback type="invalid">
                {error?.message}
              </FormControlFeedback>
            </FormGroupWithErrorSpace>
            {isCertificate && hasTextbox && (
              <TextField
                xs={xs ? xs / 2 : xs}
                label=" "
                isCertificate={false}
                fieldId={thisFieldIdText}
                disabled={disabled ?? !field.value}
                isInvalid={Boolean(error?.valueTextbox)}
                additionalOnBlur={(text: string) => {
                  const newVal = {
                    ...getValues(fieldId),
                    value: 'true',
                    valueTextbox: text,
                  };

                  autoSave({
                    type: FieldType.Checkbox,
                    fieldId,
                    value: newVal,
                    getValues,
                    setValue,
                  });
                  if (autoObservations) {
                    handleAutoObservations({
                      fieldId,
                      autoObservations,
                      getValues,
                      setValue,
                    });
                  }
                }}
              />
            )}
          </>
        );
      }}
    />
  );
};

Checkbox.defaultProps = {
  xs: 12,
};

export { Checkbox };
