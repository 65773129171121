import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { RoleEnum } from 'store/@types/user';

import { FormControlProps } from 'react-bootstrap';

import plusIcon from 'assets/images/iconset/signature_add.svg';
import { CertificateState } from 'enum/certificateDataState.enum';
import { FieldType } from 'enum/FieldType.enum';
import useAuth from 'hooks/useAuth';
import useCerts from 'hooks/useCerts';
import {
  IssuedSignature,
  Signature,
  Signature as SignatureProps,
} from 'interfaces/Signature';

import Comments from 'components/Comments';
import {
  FormControl,
  FormControlFeedback,
  FormGroupWithErrorSpace,
  FormLabel,
  FormLabelContainer,
} from 'components/Form/styles';
import SignatureAdd from 'components/modals/SignatureAdd';
import SignatureList from 'components/modals/SignatureList';

import { getError } from '..';
import { CommonProps } from '../Types';

import './styles.css';

interface SignatureComponentProps extends FormControlProps, CommonProps {
  componentType: FieldType;
  type?: 'new' | 'select' | 'select-with-preview';
  required?: boolean;
  shouldDirty?: boolean;
  handleClose?: () => void;
  submitNewSignature?: boolean;
  handleOnLoadNew?: (file: File) => void;
}

const SignatureComponent: React.FC<SignatureComponentProps> = ({
  xs,
  fieldId,
  label,
  type,
  disabled,
  handleClose,
  submitNewSignature,
  required,
  isCertificate,
  componentType,
  shouldDirty,
  handleOnLoadNew,
}) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const error = getError(fieldId, errors);

  const { userSignatures, reload, currentRole } = useAuth();
  const { autoSave, certData } = useCerts();
  const [currentSigna, setCurrentSignature] = useState<
    SignatureProps | undefined
  >(undefined);

  const currentValue = useWatch({
    control,
    name: fieldId,
  });

  const emptyValue = {
    key: fieldId,
    value: '',
    valueType: FieldType.SignatureArea,
  };

  const updateCurrentSignature = (): { exists?: boolean } => {
    if (currentValue && userSignatures) {
      let currentSignature = userSignatures.find((c) => {
        if (typeof currentValue === 'string') {
          return c._id === currentValue;
        }
        return c._id === currentValue?._id || c._id === currentValue?.value;
      });

      if (currentSignature) {
        setCurrentSignature(currentSignature);
      }

      if (!currentSignature && isCertificate && currentValue?.valueSignature) {
        const issuedSignature = currentValue.valueSignature as IssuedSignature;
        currentSignature = {
          _id: issuedSignature.id,
          description: issuedSignature.description,
          signature: issuedSignature.content,
          default: false,
          user: '',
        };
        setCurrentSignature(currentSignature);
      }

      return { exists: !!currentSignature };
    }
    return {};
  };

  useEffect(() => {
    const { exists } = updateCurrentSignature();

    // Clear the form signature input when no exists an signature
    if (
      window.location.pathname !== '/onboarding' &&
      [RoleEnum.SOLO, RoleEnum.LEAD].includes(currentRole)
    ) {
      if (
        isCertificate &&
        certData?.certificateState === CertificateState.ISSUED
      ) {
        return;
      }
      if (userSignatures?.length === 0 || !exists) {
        saveSignature(undefined, false);
      }
    }
  }, [currentValue, userSignatures]);

  const [addWhenOnSelect, setAddWhenOnSelect] = useState(false);
  const [signatureModal, setSignatureModal] = useState(false);

  const closeModal = (triggerClose = false) => {
    if (addWhenOnSelect) {
      setAddWhenOnSelect(false);
    } else {
      setSignatureModal(false);
      triggerClose && handleClose && handleClose();
    }
  };

  const saveSignature = useCallback(
    (data: Signature | undefined, isNew: boolean) => {
      // When there is no signatures, then clear the form and save it on isCertificate as empty
      if (!data) {
        setValue(fieldId, undefined);
        setCurrentSignature(undefined);
        if (isCertificate) {
          autoSave({
            type: componentType,
            fieldId,
            value: undefined,
            getValues,
            setValue,
          });
        }
        return;
      }

      // Else save signature data normally
      if (isNew) {
        reload({ reloadSignatures: true });
      }

      setCurrentSignature(data);

      if (!isCertificate) {
        setValue(fieldId, data, {
          shouldValidate: true,
          shouldDirty,
          shouldTouch: true,
        });
      }

      if (isCertificate && data._id) {
        setValue(
          fieldId,
          {
            ...emptyValue,
            value: data._id,
          },
          {
            shouldValidate: true,
            shouldDirty,
            shouldTouch: true,
          }
        );
        autoSave({
          type: componentType,
          fieldId,
          value: {
            ...emptyValue,
            value: data._id,
          },
          getValues,
          setValue,
        });
      }
    },
    []
  );

  const setSignature = (defaultSignature: SignatureProps, isNew: boolean) => {
    if (isNew) return;
    saveSignature(defaultSignature, false);
  };

  return (
    <Controller
      name={fieldId}
      control={control}
      render={({ field }) => {
        return (
          <>
            <FormGroupWithErrorSpace
              height={type === 'select-with-preview' ? '230px' : '98px'}
              className="CertificateSignature"
              xs={xs}
            >
              <FormLabel disabled={disabled}>
                <FormLabelContainer>
                  {label}{' '}
                  <Comments
                    fieldId={fieldId}
                    disabledField={disabled}
                    isCertificate={isCertificate}
                    handleOnChange={(comments) => {
                      const signature = getValues(fieldId) as Signature;
                      autoSave({
                        type: componentType,
                        fieldId,
                        value: {
                          ...emptyValue,
                          value: signature._id,
                        },
                        getValues,
                        setValue,
                        comments,
                      });
                    }}
                  />
                </FormLabelContainer>
                {required && (
                  <span className="required float-right">Required</span>
                )}
              </FormLabel>
              <FormControl
                disabled={disabled}
                type="button"
                isInvalid={!!error}
                onClick={() => {
                  setSignatureModal(true);
                }}
                value={
                  currentSigna?.description ||
                  (type === 'new' ? 'Upload signature' : 'Select signature')
                }
              />
              <img
                className={clsx('input_icon_placeholder', {
                  disabled,
                })}
                src={
                  plusIcon
                  // (field.value && selectIcon) ||
                  // (type === 'new' ? plusIcon : selectIcon)
                }
                style={{
                  top: field.value && 40,
                }}
                alt="Add a signature"
                onClick={() => {
                  setSignatureModal(true);
                }}
              />
              <FormControlFeedback type="invalid">
                {error && error?.signature?.message}
              </FormControlFeedback>
              {type === 'select-with-preview' && (
                <>
                  <div
                    className="signature-select-with-preview"
                    style={{
                      backgroundImage: currentSigna?.signature
                        ? `url(${currentSigna?.signature})`
                        : 'none',
                    }}
                    onClick={() => {
                      setSignatureModal(true);
                    }}
                  />
                  {currentSigna && !disabled && !required && (
                    <FormLabel
                      style={{
                        lineHeight: 2,
                        cursor: 'pointer',
                        justifyContent: 'flex-end',
                      }}
                      onClick={() => {
                        autoSave({
                          type: componentType,
                          fieldId,
                          value: emptyValue,
                          getValues,
                          setValue,
                        });
                        setValue(fieldId, emptyValue);
                      }}
                    >
                      reset
                    </FormLabel>
                  )}
                </>
              )}
            </FormGroupWithErrorSpace>

            {((signatureModal && type === 'new') || addWhenOnSelect) && (
              <SignatureAdd
                submitNewSignature={submitNewSignature}
                signatures={userSignatures}
                action={() => {
                  if (addWhenOnSelect) {
                    setAddWhenOnSelect(false);
                  } else {
                    closeModal();
                  }
                }}
                handleSave={(data) => {
                  saveSignature(data as Signature, true);
                  closeModal(true);
                }}
                onLoadNew={(file) => {
                  if (handleOnLoadNew) {
                    handleOnLoadNew(file);
                  }
                }}
              />
            )}

            {signatureModal &&
              !disabled &&
              (type === 'select' || type === 'select-with-preview') && (
                <SignatureList
                  canAdd={type === 'select'}
                  type={type === 'select-with-preview' ? 'pick' : 'manage'}
                  signatures={userSignatures}
                  currentSignature={currentSigna}
                  handleDelete={() => {
                    reload({ reloadSignatures: true });
                  }}
                  addSignature={() => {
                    setAddWhenOnSelect(true);
                  }}
                  closeItself={() => {
                    closeModal();
                  }}
                  handleSave={(data) => {
                    setSignature(data, false);
                    closeModal(true);
                  }}
                />
              )}
          </>
        );
      }}
    />
  );
};

SignatureComponent.defaultProps = {
  type: 'new',
  required: false,
  shouldDirty: false,
  handleClose: undefined,
  submitNewSignature: false,
};

export { SignatureComponent };
