import { CSSProperties, FC } from 'react';

import * as S from './styles';

interface SecondaryButtonProps {
  handleAction: () => void;
  label?: string;
  icon?: string;
  iconHover?: string;
  style?: CSSProperties;
  disabled?: boolean;
  center?: boolean;
}

const SecondaryButton: FC<SecondaryButtonProps> = ({
  style,
  icon,
  iconHover,
  label,
  handleAction,
  ...rest
}) => {
  return (
    <S.SecondaryButton onClick={handleAction} style={style} {...rest}>
      {icon && (
        <img
          className="secondary-buton-image"
          src={icon}
          alt={label}
          width={16}
          height={16}
        />
      )}
      {iconHover && (
        <img
          className="secondary-buton-image-hover"
          src={iconHover}
          alt={label}
          width={16}
          height={16}
        />
      )}
      {label && <S.SecondaryButtonLabel>{label}</S.SecondaryButtonLabel>}
    </S.SecondaryButton>
  );
};

export default SecondaryButton;
