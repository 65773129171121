import styled from 'styled-components';

export const CardContainer = styled.div<{
  isSelected?: boolean;
  isCurrent?: boolean;
}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 11px;

  background: #fff;
  padding: 11px 30px 11px 14px;
  box-shadow: 0px 4px 13.1px -8px #7fa8bc;
  border: 1px solid #fff;
  transition: all 0.4s;

  ${({ isCurrent }) => {
    return (
      !isCurrent &&
      `  :hover {
      border: 1px solid #5488BE;
    }
    `
    );
  }}

  ${({ isSelected }) => {
    return isSelected && `border: 1px solid #5488BE;`;
  }}
  ${({ isCurrent }) => {
    return `cursor: ${isCurrent ? 'auto' : 'pointer'};`;
  }}
`;

export const Container = styled.div`
  width: auto;
  display: flex;
  gap: 23px;
  justify-content: space-between;
  align-items: center;
`;

export const IconContainer = styled.div<{ bgColor: string }>`
  width: 98px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #dadfe3;
  ${({ bgColor }) => {
    return `background-color: ${bgColor}`;
  }}
`;

export const ProductContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: space-between;
  align-items: flex-start;

  span {
    font-size: 20px;
    font-style: normal;
    line-height: normal;
  }

  span:nth-child(1) {
    color: #272727;
    font-weight: 700;
  }
  span:nth-child(2) {
    color: #666;
    font-weight: 400;
  }
`;
