import { FC, useState } from 'react';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { Button, Col, FormControlProps } from 'react-bootstrap';

import { FieldType } from 'enum/FieldType.enum';
import useCerts from 'hooks/useCerts';
import { CertificateDataOperationsTableProps } from 'interfaces/CertificateData';
import { CertTemplateField } from 'interfaces/CertificateTemplate';
import { getMultiplierValue } from 'utils/certificates/affects/functions/getMultiplierValue';
import { CircuitTableValuesGenerator } from 'utils/certificates/circuitsUtils';
import { getBoardInfo, getBoardName } from 'utils/certificates/defaultValues';
import { defaultToast } from 'utils/toast';

import CircuitWaysModal from 'components/modals/CircuitWaysModal';

import { getError } from '..';
import { CommonProps } from '../Types';

import './style.css';

interface OperationsTableProps extends FormControlProps, CommonProps {
  field: CertTemplateField;
}

const OperationsTable: FC<OperationsTableProps> = ({ label, field }) => {
  const [showModalWays, setShowModalWays] = useState(false);
  const [query, setSearchParams] = useSearchParams();
  const prevPage = query.get('prevPage');
  const page = query.get('page');

  const {
    getValues,
    setValue,
    trigger,
    reset,
    formState: { errors },
  } = useFormContext();
  const operationTableEmpty = getError(field.fieldId, errors);
  const hasCircuitErrors = Object.keys(errors).some((e) =>
    e.includes(field.fieldId)
  );
  const error = operationTableEmpty || hasCircuitErrors;

  const {
    defaultValues,
    setCertLoading,
    affectedFields,
    autoSave,
    loadCertificateTemplate,
    setSelectedCircuitIndexes,
    certData,
    clearAll,
  } = useCerts();
  const currentValue = defaultValues[field.fieldId] as [];
  const hasTableValues = currentValue && currentValue.length > 0;

  const {
    auxLabel,
    buttonLabel,
    rowNomenclature = '',
    requiredValues = [],
  } = field;

  const createCircuitOperationsTable = (ways: number) => {
    const phases = getMultiplierValue(
      field.fieldId,
      defaultValues,
      getValues,
      requiredValues
    );

    if (!phases && ways !== 0) {
      const { boardId, boardIndex, boardGridId } = getBoardInfo(field.fieldId);
      const borderName = getBoardName(boardGridId, Number(boardIndex), boardId);
      requiredValues.forEach((item) => {
        trigger(`${borderName}*${item.fieldId}`, { shouldFocus: true });
      });
      setShowModalWays(false);
      defaultToast('E', 'This operation needs one or more fields to be filled');
    }

    const certTableStructs = affectedFields[field.fieldId]?.tableStructure;
    if (ways && phases && certTableStructs?.length) {
      const data: CertificateDataOperationsTableProps[] =
        CircuitTableValuesGenerator({
          certTableStructs,
          numberOfPhases: Number(phases),
          numberOfWays: ways,
          rowNomenclature,
        });

      autoSave({
        type: FieldType.OperationsTable,
        fieldId: field.fieldId,
        value: data,
        getValues,
        setValue,
      });
      reset({});
      clearAll();
      loadCertificateTemplate(String(certData?.template?._id), certData?._id);
      setCertLoading(true);
      setTimeout(() => {
        setSearchParams(`page=${page}&prevPage=${prevPage}&isCircuits=true`);
      }, 100);
    }
  };

  return (
    <>
      {/* DELETE CUSTOMER ACCOUNT CONFIRMATION MODAL */}
      {field.popup && showModalWays && (
        <CircuitWaysModal
          title={field.popup.popupTitle}
          text={field.popup.popupDescription}
          confirmText={field.popup.actionButtonLabel}
          actionConfirm={(ways) => {
            createCircuitOperationsTable(ways);
          }}
          cancelText="Cancel"
          actionCancel={() => {
            setShowModalWays(false);
          }}
        />
      )}
      <Col xs={12}>
        <div
          className={clsx({ 'p-3': !!error })}
          style={{
            maxWidth: '400px',
            ...(!!error && {
              border: '1px solid #F04438',
              borderRadius: 12,
            }),
          }}
        >
          <h4
            className={clsx('FormLabel form-section-label', {
              'form-section-normal-label': true,
            })}
          >
            {label}
          </h4>
          <h5 className={'form-section-aux-label '}>{auxLabel}</h5>
        </div>
      </Col>
      <Col xs={12} className="buttons-right-area">
        <Button
          className="button-primary"
          onClick={() => {
            if (hasTableValues) {
              setCertLoading(true);
              setTimeout(() => {
                setSearchParams(
                  `page=${page}&prevPage=${prevPage}&isCircuits=true`
                );
              }, 100);
            } else {
              setShowModalWays(true);
            }
            setSelectedCircuitIndexes({});
          }}
        >
          {buttonLabel}
        </Button>
      </Col>
    </>
  );
};

OperationsTable.defaultProps = {
  xs: 12,
};

export { OperationsTable };
