import { FC, useEffect, useState } from 'react';
import { roleMapper } from 'constants/roleMapper.constants';
import { RoleEnum, User } from 'store/@types/user';

import { Col, Modal, Row } from 'react-bootstrap';

import searchIcon from 'assets/images/iconset/input_search.svg';
import NoPhotoIcon from 'assets/images/iconset/no_photo.svg';
import useAuth from 'hooks/useAuth';

import Scrollbars from 'components/Scrollbars';

import ModalConfirm from '../ModalConfirm';
import * as S from './styles';

interface UserSelectModalProps {
  modalTitle: string;
  selectedUsers?: string[];
  users: User[];
  actionCancel: () => void;
  actionConfirm: (userIds: string[]) => void;
}

interface SelectUserList {
  id: string;
  selected: boolean;
  picture: string;
  name: string;
  role: string;
}

interface UserCounters {
  added: number;
  removed: number;
}

export const UserSelectModal: FC<UserSelectModalProps> = ({
  modalTitle,
  selectedUsers,
  users,
  actionConfirm,
  actionCancel,
}) => {
  const [selectUserList, setSelectUserList] = useState<SelectUserList[]>([]);
  const { currentBusiness } = useAuth();
  const [userCounters, setUserCounters] = useState<UserCounters>();
  const [searchTerm, setSearchTerm] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showNoEngineersModal, setShowNoEngineersModal] = useState(false);

  useEffect(() => {
    const counters = selectUserList.reduce(
      (acc: UserCounters, selectedUser) => {
        if (selectedUser.selected === false) {
          if (selectedUsers?.includes(selectedUser.id)) {
            acc.removed++;
          }
        } else if (!selectedUsers?.includes(selectedUser.id)) {
          acc.added++;
        }
        return acc;
      },
      { added: 0, removed: 0 }
    );
    setUserCounters(counters);
  }, [selectUserList]);

  useEffect(() => {
    const usersMapped = users.map((user) => {
      const userRole = user.roles.find(
        (role) =>
          role.businessId === currentBusiness &&
          [RoleEnum.ENGINEER, RoleEnum.LEAD].includes(role.role)
      );

      return {
        id: user._id,
        picture: user.picture,
        name: user.name,
        role: userRole ? roleMapper[userRole?.role] : '',
        selected: selectedUsers?.includes(user._id),
      } as SelectUserList;
    });
    setSelectUserList(usersMapped);
  }, []);

  const remmapSelectedUserList = (
    prevUsers: SelectUserList[],
    userId: string
  ) => {
    return prevUsers.map((prevUser) => {
      if (prevUser.id == userId) {
        return {
          ...prevUser,
          selected: prevUser.selected !== true,
        } as SelectUserList;
      }
      return prevUser;
    });
  };

  const getSelectedUserIds = () => {
    return selectUserList.reduce((ids: string[], user) => {
      if (user.selected) {
        ids.push(user.id);
      }
      return ids;
    }, []);
  };

  const handleOnSave = () => {
    if (userCounters?.added === 0 && selectedUsers?.length === 0) {
      setShowNoEngineersModal(true);
      return;
    }
    if (userCounters?.added === 0 && userCounters.removed === 0) {
      actionCancel();
      return;
    }
    setShowConfirmModal(true);
  };

  return (
    <>
      {showNoEngineersModal && (
        <ModalConfirm
          title="No engineers assigned"
          text={
            <>
              Are you sure you want to proceed without setting your team up?
              <br />
              You can add or remove any members later.
            </>
          }
          cancelText="Assign now"
          actionCancel={() => {
            setShowNoEngineersModal(false);
          }}
          confirmText="Proceed"
          actionConfirm={() => {
            actionCancel();
            setShowNoEngineersModal(false);
          }}
        />
      )}
      {showConfirmModal && (
        <ModalConfirm
          title="Confirm team updates?"
          text={
            <>
              {'You are removing'}
              <strong> {userCounters?.removed} </strong>
              {'Engineers from your team, and adding'}
              <strong> {userCounters?.added} </strong>
              {'new members.'}
            </>
          }
          cancelText="Cancel"
          actionCancel={() => {
            setShowConfirmModal(false);
          }}
          confirmText="Update"
          actionConfirm={() => {
            const userIds = getSelectedUserIds();
            actionConfirm(userIds);
            setShowConfirmModal(false);
          }}
        />
      )}
      <Modal
        show
        backdrop="static"
        className="modal-md picker-modal"
        onHide={actionCancel}
      >
        <Modal.Header className="modal-default-header d-flex">
          <Modal.Title
            className="modal-header-left-action"
            onClick={actionCancel}
          >
            Later
          </Modal.Title>
          <Modal.Title className="modal-title">{modalTitle}</Modal.Title>
          <Modal.Title
            className="modal-header-right-action"
            onClick={handleOnSave}
          >
            Save
          </Modal.Title>
        </Modal.Header>
        <Scrollbars height="515px" style={{ marginLeft: 'unset' }}>
          <Modal.Body>
            <S.SearchContainer>
              <img src={searchIcon} alt="search" width={22} height={22} />
              <S.SearchInput
                id="searchBar"
                type="text"
                maxLength={30}
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                }}
              />
            </S.SearchContainer>
            <Row style={{ marginRight: '-15px' }}>
              {selectUserList
                ?.filter((val) => {
                  if (searchTerm === '') return true;
                  return val.name
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());
                })
                .map((user) => {
                  return (
                    <Col
                      className="radio-box rigth"
                      xs={12}
                      key={'radio-component-option-' + user.id}
                    >
                      <S.UserContainer>
                        <S.UserCard>
                          {user.picture && (
                            <S.ImageContainer corner>
                              <img
                                src={user.picture}
                                alt="User"
                                width={50}
                                height={50}
                              />
                            </S.ImageContainer>
                          )}
                          {!user.picture && (
                            <S.ImageContainer>
                              <img
                                src={NoPhotoIcon}
                                alt="User"
                                width={18}
                                height={18}
                              />
                            </S.ImageContainer>
                          )}
                          <S.UserInfo>
                            <span>{user.name}</span>
                            <span>{user.role}</span>
                          </S.UserInfo>
                        </S.UserCard>
                        <S.InputContainer>
                          <S.CheckButton
                            onClick={() => {
                              setSelectUserList((prevUsers) =>
                                remmapSelectedUserList(prevUsers, user.id)
                              );
                            }}
                            checked={user.selected}
                          />
                        </S.InputContainer>
                      </S.UserContainer>
                    </Col>
                  );
                })}
            </Row>
          </Modal.Body>
        </Scrollbars>
      </Modal>
    </>
  );
};
