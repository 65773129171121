import styled from 'styled-components';

export const DropddownWrapper = styled.div`
  display: flex;
  height: 44px;
  flex-direction: column;
  align-items: flex-end;
`;

export const DropddownButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px 10px 16px;
  min-height: 44px;
  color: #fff;
  gap: 10px;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: all 0.3s;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Dropdown = styled.div<{ top: number }>`
  width: min-content;
  position: relative;
  overflow-x: hidden;
  width: 100%;
  max-width: 675px;
  min-height: 325px;
  top: ${({ top }) => (top ? `${Math.trunc(top)}px` : '0px')};
  z-index: 2;
  overflow-y: auto;
  border-radius: 11px;
  background: #fff;
  box-shadow: 0px 4px 13.1px -8px #7fa8bc;
`;

export const DropdownItem = styled.p`
  margin: 0;
  width: 100%;
  height: auto;
  display: flex;
  cursor: pointer;
  padding: 12px 16px;
  color: #2b3d4f;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  letter-spacing: normal;
  transition: all 0.2s;
  &:hover {
    background-color: #5488be;
    color: #ffffff;
  }
`;

export const DropdownOverlay = styled.div`
  z-index: 1;
  position: fixed;
  inset: 0;
`;
