import { FC, useEffect, useState } from 'react';
import { disabledFieldOnStates } from 'constants/certificate.constants';
import { useNavigate, useParams } from 'react-router-dom';
import { RoleEnum, User } from 'store/@types/user';

import { apiGetUserFromBusiness } from 'api/business';
import { apiAssignUserUpdateCertificateData } from 'api/certificates';
import ContextIcon from 'assets/images/iconset/context_menu.svg';
import ConvertCertificateIcon from 'assets/images/iconset/convert-certificate.svg';
import DeleteCertificateIcon from 'assets/images/iconset/delete.svg';
import DuplicateIcon from 'assets/images/iconset/duplicate.svg';
import ApplyPresetIcon from 'assets/images/iconset/preset.svg';
import AssignEngineerIcon from 'assets/images/iconset/users.svg';
import { CertificateState } from 'enum/certificateDataState.enum';
import useAuth from 'hooks/useAuth';
import useCerts from 'hooks/useCerts';
import api from 'services/api';
import { defaultToast } from 'utils/toast';
import { containsRoles } from 'utils/userRoles';

import MenuActionDropdown from 'components/Menu/MenuActionDropdown';
import ModalConfirm from 'components/modals/ModalConfirm';
import ModalPresetSelect from 'components/modals/ModalPresetSelect';
import { UserSelectModal } from 'components/modals/UserSelectModal';

import * as S from './styles';

const CertificateActions: FC = () => {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const [deleteCertificateModal, setDeleteCertificateModal] = useState(false);
  const [isShowAssignEngineerModal, setIsShowAssignEngineerModal] =
    useState(false);

  const [presetModal, setPresetModal] = useState(false);
  const [userBusiness, setUserBusiness] = useState<User[]>([]);
  const { certData, setCertLoading, clearAll, loadCertificateTemplate } =
    useCerts();
  const { currentRole, currentBusiness, user } = useAuth();
  const showMenu =
    !!certData && !disabledFieldOnStates.includes(certData.certificateState);

  const isIssued = certData?.certificateState === CertificateState.ISSUED;
  const canDuplicate = [RoleEnum.LEAD, RoleEnum.SOLO].includes(currentRole);
  const canDelete =
    [RoleEnum.LEAD, RoleEnum.SOLO].includes(currentRole) && !isIssued;
  const canConvert =
    [RoleEnum.LEAD, RoleEnum.SOLO].includes(currentRole) &&
    isIssued &&
    ['EIC', 'EICR'].includes(certData?.template?.certificateName ?? '');
  const canAssignEngineers = currentRole === RoleEnum.LEAD && !isIssued;
  const convertToMap: { [x: string]: string } = {
    EICR: 'EIC',
    EIC: 'EICR',
  };

  const deleteCertificate = () => {
    if (!certData) return;
    setCertLoading(true);
    api
      .delete(`certificate-data/${certData._id}`)
      .then(() => {
        navigate('/home');
        defaultToast('S', 'Your certificate was deleted successfully');
        clearAll();
      })
      .catch(() => defaultToast('E'))
      .finally(() => {
        setCertLoading(false);
      });
  };

  const duplicateWithValues = () => {
    if (!certData) return;
    setCertLoading(true);
    api
      .post('/certificate-data/duplicate', {
        certificateId: `${certData._id}`,
        partial: false,
      })
      .then(({ data }) => {
        clearAll();
        navigate(`/?duplicated=${data._id}`);
        defaultToast('S', 'Your certificate was duplicated successfully');
      })
      .catch(() => defaultToast('E'))
      .finally(() => {
        setCertLoading(false);
      });
  };

  const convertCertificate = () => {
    if (!certData?._id || !certData?.template?.certificateName) return;
    setCertLoading(true);
    api
      .post('/certificate-data/convert', {
        certificateId: `${certData._id}`,
        targetCertificateType:
          convertToMap[certData?.template?.certificateName],
      })
      .then(({ data }) => {
        clearAll();
        navigate(`/?duplicated=${data._id}`);
        defaultToast('S', 'Your certificate was converted successfully');
      })
      .catch(() => defaultToast('E'))
      .finally(() => {
        setCertLoading(false);
      });
  };

  const loadEngineersFromBusiness = () => {
    if (currentBusiness) {
      apiGetUserFromBusiness(currentBusiness).then((businessUsers) => {
        const enterpriseUsers = businessUsers.filter(
          (businessUser) =>
            containsRoles(businessUser.roles, [
              RoleEnum.ENGINEER,
              RoleEnum.LEAD,
            ]) && businessUser._id !== user?._id
        );
        setUserBusiness(enterpriseUsers);
      });
    }
  };

  const assignEngineersToCertificate = (userIds: string[]) => {
    if (!certData?._id || !templateId) return;
    setCertLoading(true);
    apiAssignUserUpdateCertificateData(certData._id, { users: userIds })
      .then(() => {
        defaultToast('S', 'Engineers updated successfully');
        clearAll();
        loadCertificateTemplate(templateId, certData._id);
      })
      .catch(() => {
        defaultToast('E');
      })
      .finally(() => {
        setCertLoading(false);
      });
  };

  useEffect(() => {
    if (canAssignEngineers) {
      loadEngineersFromBusiness();
    }
  }, []);

  return (
    <S.ActionsContainer>
      {canAssignEngineers && (
        <S.AssignEngineerButton
          onClick={() => {
            setIsShowAssignEngineerModal(true);
          }}
        >
          <img
            src={AssignEngineerIcon}
            alt="Assigns engineers"
            width={26}
            height={26}
          />
        </S.AssignEngineerButton>
      )}
      {isShowAssignEngineerModal && (
        <UserSelectModal
          selectedUsers={certData?.assignedEngineers?.flatMap(
            (engineer) => engineer._id
          )}
          users={userBusiness}
          modalTitle="Assign Engineers"
          actionCancel={() => {
            setIsShowAssignEngineerModal(false);
          }}
          actionConfirm={(userIds) => {
            assignEngineersToCertificate(userIds);
            setIsShowAssignEngineerModal(false);
          }}
        />
      )}
      <MenuActionDropdown
        menuIcon={ContextIcon}
        items={[
          {
            icon: ApplyPresetIcon,
            label: 'Apply certificate template',
            onClick: () => setPresetModal(true),
            show: showMenu,
          },
          {
            icon: DuplicateIcon,
            label: 'Duplicate certificate',
            onClick: () => duplicateWithValues(),
            show: canDuplicate,
          },
          {
            icon: ConvertCertificateIcon,
            label: `Convert to ${
              convertToMap[certData?.template?.certificateName ?? '']
            }`,
            onClick: () => convertCertificate(),
            show: canConvert,
          },
          {
            icon: DeleteCertificateIcon,
            label: 'Delete certificate',
            onClick: () => setDeleteCertificateModal(true),
            show: canDelete,
          },
        ]}
      />
      {deleteCertificateModal && (
        <ModalConfirm
          title="Delete certificate?"
          text={
            <>
              Do you want to delete this certificate?
              <br />
              <br />
              This action cannot be undone.
            </>
          }
          cancelText="Delete"
          actionCancel={() => {
            deleteCertificate();
          }}
          confirmText="Cancel"
          actionConfirm={() => {
            // close delete modal
            setDeleteCertificateModal(false);
          }}
        />
      )}
      {presetModal && (
        <ModalPresetSelect
          certificateTypeOnUse={certData?.template?.certificateName ?? ''}
          handleSave={() => setPresetModal(false)}
          setOpenModal={setPresetModal}
        />
      )}
    </S.ActionsContainer>
  );
};

export default CertificateActions;
