import React, { FC } from 'react';

import EmptyImage from 'assets/images/empty_carrousel_image.svg';

import * as S from './styles';

interface EmptyStateProps {
  styles?: React.CSSProperties;
  className?: string;
  imageSrc?: string;
  label?: string;
  imageWidth?: string | number;
  imageHeight?: string | number;
}

const EmptyState: FC<EmptyStateProps> = ({
  styles,
  className,
  imageWidth,
  imageHeight,
  label = 'There are no items to display…',
  imageSrc = EmptyImage,
}) => {
  return (
    <S.EmptyStateContainer style={styles} className={className}>
      <img
        src={imageSrc}
        alt="no items"
        width={imageWidth}
        height={imageHeight}
      />
      {label && <p>{label}</p>}
    </S.EmptyStateContainer>
  );
};

export default EmptyState;
