import React, { FC } from 'react';

import * as S from './styles';

const CardDefault: FC<{ styles?: React.CSSProperties; className?: string }> = ({
  children,
  styles,
  className,
}) => {
  return (
    <S.Card style={styles} className={className}>
      {children}
    </S.Card>
  );
};

export default CardDefault;
