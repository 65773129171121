import styled from 'styled-components';

export const SubTitle = styled.h4`
  color: #272727;
  font-size: 17px;
  font-weight: 600;
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  gap: 8px;
  width: 100%;
  height: 150px;
  flex-shrink: 0;
  border-radius: 11px;
  background: #f9fbfc;

  > span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  span:nth-child(2) {
    margin-top: 8px;
    color: #272727;
    font-size: 17px;
    font-weight: 700;
  }

  span:nth-child(3) {
    color: #666;
  }
`;
