import { FC, Fragment, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import { RoleEnum } from 'store/@types/user';

import { Button, FormControlProps } from 'react-bootstrap';

import addActiveIcon from 'assets/images/iconset/add_comment_active.svg';
import addInactiveIcon from 'assets/images/iconset/add_comment_inactive.svg';
import { CertificateCommentStatus } from 'enum/CertificateCommentStatus.enum';
import useAuth from 'hooks/useAuth';
import {
  CertificateComments,
  CertificateCommentsItem,
} from 'interfaces/CertificateData';
import { getLastComment } from 'utils/certificates/comments';

import { FormControl } from 'components/Form/styles';
import { CommonProps } from 'components/Form/Types';

import {
  CommentContainer,
  CommentIcon,
  CommentIconContent,
  CommentModal,
  CommentModalActions,
  CommentModalInputContent,
  CommentModalOverlay,
} from './styles';

export interface CommentsCardProps extends FormControlProps, CommonProps {
  fieldId: string;
  disabledField: boolean | undefined;
  handleOnChange: (comments?: CertificateComments) => void;
  isCertificate?: boolean;
}

const Comments: FC<CommentsCardProps> = ({
  fieldId,
  disabledField,
  handleOnChange,
  isCertificate,
  ...rest
}) => {
  const { user, currentRole } = useAuth();
  const { control, getValues, setValue } = useFormContext();
  const [showCommentModal, setShowCommentModal] = useState(false);
  const commentFieldId = `${fieldId}comments`;

  const isPending = useMemo(() => {
    if (getValues) {
      const comment = getValues(commentFieldId) as CertificateComments;
      if (comment?.status === CertificateCommentStatus.PENDING) {
        return true;
      }
    }
    return false;
  }, [showCommentModal]);

  const disabled = isPending || disabledField;
  const isInsideBoard =
    fieldId.includes('board') && fieldId.split('field').length > 2;

  const canResolve =
    currentRole === RoleEnum.LEAD && !disabledField && isPending;
  const canDelete =
    [RoleEnum.QS, RoleEnum.ADMIN].includes(currentRole) &&
    !disabledField &&
    !isPending;

  if (
    isInsideBoard ||
    !isCertificate ||
    currentRole === RoleEnum.SOLO ||
    (disabled && !isPending) ||
    (![RoleEnum.QS, RoleEnum.ADMIN].includes(currentRole) && !isPending)
  )
    return null;

  const handleResolve = () => {
    const value = getValues(commentFieldId) as CertificateComments;
    value.status = CertificateCommentStatus.RESOLVED;

    setValue(commentFieldId, value);
    handleOnChange(value);
    setShowCommentModal(false);
  };

  const handleSave = () => {
    const element = document.getElementById(commentFieldId) as HTMLInputElement;
    if (element?.value?.trim() === '') {
      setShowCommentModal(false);
    }

    const newItem: CertificateCommentsItem = {
      id: uuid(),
      comment: element.value,
      userCreated: user?._id ?? '',
      createdAt: new Date(),
    };

    const fieldValue = getValues(commentFieldId) as CertificateComments;
    if (fieldValue) {
      fieldValue.status = CertificateCommentStatus.PENDING;
      fieldValue.items.push(newItem);
      setValue(commentFieldId, fieldValue);
      handleOnChange(fieldValue);
    } else {
      const comment: CertificateComments = {
        status: CertificateCommentStatus.PENDING,
        items: [newItem],
      };
      handleOnChange(comment);
      setValue(commentFieldId, comment);
    }
    setShowCommentModal(false);
  };

  return (
    <CommentContainer>
      <CommentIconContent
        onClick={() => {
          setShowCommentModal(true);
        }}
      >
        <CommentIcon
          src={showCommentModal || isPending ? addActiveIcon : addInactiveIcon}
        />
      </CommentIconContent>
      {showCommentModal && (
        <Fragment>
          <CommentModalOverlay
            onClick={() => {
              setShowCommentModal(false);
            }}
          />
          <CommentModal>
            <CommentModalInputContent disabled={disabled}>
              <Controller
                name={commentFieldId}
                control={control}
                render={({ field }) => {
                  const lastItem = getLastComment(field.value);
                  return (
                    <FormControl
                      id={commentFieldId}
                      as="textarea"
                      defaultValue={isPending ? lastItem?.comment : ''}
                      placeholder={'Type your comment here'}
                      autoFocus
                      {...rest}
                      {...(disabled && { readOnly: true })}
                    />
                  );
                }}
              />
            </CommentModalInputContent>
            {canResolve && (
              <CommentModalActions>
                <span></span>
                <Button
                  className="button-primary resolve"
                  onClick={handleResolve}
                >
                  Resolve comment
                </Button>
              </CommentModalActions>
            )}
            {canDelete && (
              <CommentModalActions>
                <span></span>
                <Button
                  className="button-primary resolve"
                  onClick={handleResolve}
                >
                  Delete comment
                </Button>
              </CommentModalActions>
            )}
            {!isPending && !disabled && (
              <CommentModalActions>
                <Link
                  style={{ color: '#272727' }}
                  to=""
                  onClick={() => {
                    setShowCommentModal(false);
                  }}
                >
                  Cancel
                </Link>
                <Button className="button-primary" onClick={handleSave}>
                  Save changes
                </Button>
              </CommentModalActions>
            )}
          </CommentModal>
        </Fragment>
      )}
    </CommentContainer>
  );
};

export default Comments;
