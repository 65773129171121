import styled from 'styled-components';

export const MenuNavItemTitleLi = styled.li`
  height: 19px;
  margin-top: 32px;
  padding-inline: 14px;
  color: #133e6b;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;
