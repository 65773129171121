import { FC } from 'react';

import DeleteIcon from 'assets/images/iconset/delete.svg';
import EditIcon from 'assets/images/iconset/icon-button-edit2.svg';
import { EquipmentProps } from 'interfaces/Settings';

import TertiaryButton from 'components/TertiaryButton';

import { formatDateUtil } from '../../../../../utils/dateUtils';
import * as S from './styles';
interface WorkEquipmentContentProps {
  equipmentData: EquipmentProps[];
  deleteEquipment: (id: string) => void;
  setEditWorkEquip: (data?: EquipmentProps) => void;
}

const WorkEquipmentContent: FC<WorkEquipmentContentProps> = ({
  equipmentData,
  setEditWorkEquip,
  deleteEquipment,
}) => {
  return (
    <S.EquipmentItemsContainer>
      {equipmentData.map((equipment) => {
        return (
          <S.EquipmentItemCard
            key={equipment._id}
            isDefault={equipment.default}
          >
            <S.ItemInfoContainer>
              <S.ItemInfoItem width="20%">
                <span>Name</span>
                <span>{equipment.name}</span>
              </S.ItemInfoItem>
              <S.ItemInfoItem width="20%">
                <span>Serial number</span>
                <span>{equipment.serialNumber}</span>
              </S.ItemInfoItem>
              <S.ItemInfoItem width="20%">
                <span>Type</span>
                <span>{equipment.type}</span>
              </S.ItemInfoItem>
              <S.ItemInfoItem width="20%">
                <span>Model number</span>
                <span>{equipment.modelNumber}</span>
              </S.ItemInfoItem>
              <S.ItemInfoItem width="20%">
                <span>Next calibration due</span>
                <span>
                  {formatDateUtil({
                    dateString: equipment.calibrationDate,
                    short: true,
                    fullYear: true,
                  })}
                </span>
              </S.ItemInfoItem>
            </S.ItemInfoContainer>

            <S.ItemActionContainer>
              <TertiaryButton
                backgroundColor="#FFEFEF"
                icon={DeleteIcon}
                handleAction={() => {
                  deleteEquipment(equipment._id);
                }}
              />
              <TertiaryButton
                icon={EditIcon}
                handleAction={() => {
                  setEditWorkEquip(equipment);
                }}
              />
            </S.ItemActionContainer>
          </S.EquipmentItemCard>
        );
      })}
    </S.EquipmentItemsContainer>
  );
};

export default WorkEquipmentContent;
