import React, { FC } from 'react';

import BackButton from 'components/root/BackButton';

import * as S from './styles';

interface MenuBackProps {
  menuTitle: string;
  handleBack: () => void;
}

const MenuBack: FC<MenuBackProps> = ({ menuTitle, handleBack, children }) => {
  return (
    <S.MenuBackContainer>
      <BackButton handleBack={handleBack} />
      <S.MenuBackTitle>{menuTitle}</S.MenuBackTitle>
      <S.ActionContainer>{children ?? <span>&nbsp;</span>}</S.ActionContainer>
    </S.MenuBackContainer>
  );
};

export default MenuBack;
