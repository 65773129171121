import React, { FC, useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import Button from 'react-bootstrap/Button';

import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';

import { ReduxProps } from '.';

import './styles.css';

const SendReinvitePage: FC<ReduxProps> = ({
  logout,
  sendReinvite,
  user: reduxUser,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [reinviteSuccess, setReinviteSuccess] = useState(false);
  const { loading } = reduxUser;
  const businessId = searchParams.get('businessId');
  const email = searchParams.get('email')?.replace(' ', '+');

  useEffect(() => {
    if (!email || !businessId) navigate('/login');
  }, [businessId, email, navigate]);

  const handleSendReinvite = () => {
    if (email && businessId) {
      sendReinvite({ email, businessId }, (successResponse?: boolean) => {
        if (successResponse) {
          setReinviteSuccess(successResponse);
        }
      });
    }
  };

  return (
    <>
      {loading && <SplashScreen />}
      {!reinviteSuccess && (
        <NoAuthBasePage>
          <div className="invite-expired-explanation">
            <p>
              The activation link you clicked has expired, so you will need to
              request a new one{'\n'}from your admin.
            </p>
            <p>
              Please tap the button below to request a new link to be sent to
              your email{'\n'}address. The link will be valid for 4 days from
              the moment you receive it.
            </p>
          </div>
          <div className="invite-expired-submit-container">
            <Link className="gray bold" to="/login">
              Back to login screen
            </Link>
            <Button
              className="button-primary float-right"
              type="submit"
              onClick={handleSendReinvite}
            >
              Request new invite
            </Button>
          </div>
        </NoAuthBasePage>
      )}
      {reinviteSuccess && (
        <NoAuthBasePage>
          <div id="invite-expired-explanation">
            <p>Your request has been successfully submitted!</p>
            <p>The admin will send you a new link as soon as possible.</p>
          </div>
          <div className="invite-expired-submit-container">
            <span></span>
            <Button
              className="button-primary float-right mt-5"
              type="submit"
              onClick={() => {
                logout();
                navigate(`/login?email=${email}`);
              }}
            >
              Go back to login
            </Button>
          </div>
        </NoAuthBasePage>
      )}
    </>
  );
};

export default SendReinvitePage;
