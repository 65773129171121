import styled from 'styled-components';

export const MenuList = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  width: 470px;
  gap: 8px;
  justify-content: space-between;
`;

export const MenuListItem = styled.li`
  list-style: none;
  border-radius: 7px;
  transition: all 0.3s;

  :hover {
    border-radius: 7px;
    background: #fafafc;
  }
  a {
    display: inline-flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }

  span {
    color: #8087a4;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &.active {
    background: #f3f4f8;

    span {
      color: #2b3d4f;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
