import React, { FC, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { APP_VERSION } from 'constants/app.constants';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';

import { TextField } from 'components/Form';
import ModalConfirm from 'components/modals/ModalConfirm';

import { defaultValues, yupSchema } from './yupSchema';

interface ModalDeleteUserAccountProps {
  actionDeleteUser: () => void;
  actionCancel: () => void;
  userName: string;
  userEmail: string;
}

const ModalDeleteUserAccount: FC<ModalDeleteUserAccountProps> = ({
  actionCancel,
  actionDeleteUser,
  userName,
  userEmail,
}) => {
  const [showDeleteUserConfirmation, setShowDeleteUserConfirmation] =
    useState(false);

  // React hook form instance
  const formMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(yupSchema(userName, userEmail)),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  return (
    <>
      <Modal show backdrop="static" className="modal-md">
        <Modal.Header className="modal-default-header d-flex">
          <Modal.Title
            className="modal-header-left-action"
            onClick={actionCancel}
          >
            Cancel
          </Modal.Title>
          <Modal.Title className="modal-title">
            {'Delete user account'}
          </Modal.Title>
          <Modal.Title className="modal-header-right-action mr-5"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <FormProvider {...formMethods}>
              <Form
                id="delete-user-account-form"
                onSubmit={formMethods.handleSubmit(() => {
                  setShowDeleteUserConfirmation(true);
                })}
              >
                <Row>
                  <Col xs={12}>
                    <h2 className="section-title">Caution!</h2>
                    <h3 className="section-subtitles">
                      Please advise: this action cannot be undone.
                      <br />
                      Proceed with caution as any changes are irreversible.
                    </h3>
                  </Col>
                  <TextField
                    xs={12}
                    required
                    fieldId="confirmUserEmail"
                    className="red-border-input"
                    label="Enter your account email"
                    placeholder="Enter the account email here."
                  />
                  <TextField
                    xs={12}
                    required
                    fieldId="confirmUserName"
                    className="red-border-input"
                    label="Enter the matching display name to confirm deletion"
                    placeholder="Enter your display name here."
                  />
                  <Col xs={12}>
                    <Button
                      className="button-red float-right mb-4"
                      type="submit"
                    >
                      Delete user account
                    </Button>
                  </Col>
                </Row>
              </Form>
            </FormProvider>
          </Container>
        </Modal.Body>
      </Modal>

      {/* DELETE USER ACCOUNT CONFIRMATION MODAL */}
      {showDeleteUserConfirmation && (
        <ModalConfirm
          title="Delete user account?"
          text={
            <>
              Are you certain you wish to delete this user account?
              <br />
              <br />
              You will lose all access to {APP_VERSION}.
            </>
          }
          confirmText="Cancel"
          actionConfirm={() => {
            setShowDeleteUserConfirmation(false);
          }}
          cancelText="Delete account"
          actionCancel={() => {
            actionDeleteUser();
          }}
        />
      )}
    </>
  );
};
export default ModalDeleteUserAccount;
