import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 16px;
  height: 744px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

export const CardItemContainer = styled.div`
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  border-radius: 8px;
  padding-inline: 16px;
  cursor: pointer;
  transition: all 0.3s;

  :hover {
    background: #f3f4f8;
  }

  > span {
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    color: #2b3d4f;
    font-weight: 400;

    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }

  > span:nth-child(1) {
    color: #272727;
    font-weight: 600;
  }
`;

export const CustomerColumn = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  > img {
    width; 26px;
    height: 26px;
  }

  > span {
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    color: #2B3D4F;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
  }
`;
