import { FC, useRef, useState } from 'react';

import Icon from 'assets/images/iconset/combo-arrow-down.svg';

import * as S from './styles';

export interface DropdownSelectOption {
  value: string;
  label?: string;
}

interface DropdownSelectProps {
  labelPrefix?: string;
  width?: string | number;
  options?: DropdownSelectOption[];
  handleAction: (option: string) => void;
}

const DropdownSelect: FC<DropdownSelectProps> = ({
  options,
  labelPrefix,
  width,
  handleAction,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownTop, setDropdownTop] = useState(0);
  const readOnlyInputRef = useRef<HTMLButtonElement>(null);
  const [selected, setSelected] = useState('All');

  const openDropdow = () => {
    if (readOnlyInputRef.current) {
      const { top } = readOnlyInputRef?.current?.getBoundingClientRect() ?? {};
      setDropdownTop(window.innerHeight - top > 256 ? 5 : -261);
      setDropdownOpen(true);
    }
  };

  return (
    <S.DropddownWrapper>
      {dropdownOpen && (
        <S.DropdownOverlay
          onClick={() => {
            setDropdownOpen(false);
          }}
        />
      )}
      <S.DropddownButton
        icon={Icon}
        style={{ width }}
        disabled={options?.length === 0}
        ref={readOnlyInputRef}
        onClick={() => {
          openDropdow();
        }}
      >
        {labelPrefix && (
          <span>
            {`${labelPrefix}: `}
            <span>{selected}</span>
          </span>
        )}
        {!labelPrefix && <span>{selected}</span>}
        <img src={Icon} alt="open" />
      </S.DropddownButton>

      {dropdownOpen && (
        <S.Dropdown top={dropdownTop}>
          {options?.map((option) => {
            return (
              <S.DropdownItem
                key={'dropdownOption-' + option.value}
                onClick={() => {
                  handleAction(option.value);
                  setSelected(option.label ?? option.value);
                  setDropdownOpen(false);
                }}
              >
                {option.label ?? option.value}
              </S.DropdownItem>
            );
          })}
        </S.Dropdown>
      )}
    </S.DropddownWrapper>
  );
};

export default DropdownSelect;
