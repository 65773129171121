import React, { FC } from 'react';

import * as S from './styles';

const CardDivider: FC<{ styles?: React.CSSProperties; className?: string }> = ({
  styles,
  className,
}) => {
  return <S.CardDivider style={styles} className={className} />;
};

export default CardDivider;
