import styled from 'styled-components';

export const CardItem = styled.div<{ highlight?: boolean }>`
  width: 100%;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #edf2f7;
  background: #fff;

  ${({ highlight }) => {
    if (highlight) {
      return `
      border: 1px solid #7fa8bc;
      box-shadow: 0px 4px 13.1px -8px #7fa8bc;
      `;
    }
  }}
`;
