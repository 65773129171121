import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 354px;
  max-width: 354px;
`;

export const FormContainer = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  overflow: auto;
  margin-bottom: 69px;
  padding: 24px 70px 24px 24px;
`;
