import { FC, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LoginPost, LoginResponse } from 'store/@types/user';

import Button from 'react-bootstrap/esm/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import LogoIcon from 'assets/images/symbol.svg';
import useAuth from 'hooks/useAuth';
import withHelmet from 'hooks/withHelmet';
import { defaultToast } from 'utils/toast';

import { TextField } from 'components/Form/TextField';
import LinkButton from 'components/LinkButton';
import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';

import * as S from '../styles';
import { ReduxProps } from '.';
import { defaultValues, schema } from './yupSchema';

const LoginPage: FC<ReduxProps> = ({ login, logout, user: reduxUser }) => {
  const navigate = useNavigate();
  const { user, setLogin } = useAuth();
  const { loading, error } = reduxUser;
  const [match] = useSearchParams();

  useEffect(() => {
    if (user) {
      navigate('/home');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (error) {
      defaultToast(
        'E',
        'There was a problem logging you in. Please verify your email and password are valid.'
      );
    }
  }, [error]);

  // React hook form instance
  const methods = useForm<LoginPost>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  useEffect(() => {
    const invitedEmail = match.get('email');
    if (invitedEmail) {
      logout();
      methods.setValue('email', invitedEmail);
      methods.setFocus('password');
    }
  }, [match]);

  const onSubmit = (loginPayload: LoginPost) =>
    login(loginPayload, (successResponse?: LoginResponse) => {
      if (successResponse) {
        setLogin(successResponse);
        navigate('/home');
      }
    });

  return (
    <>
      {loading && <SplashScreen />}
      {/* Validation email error */}
      <NoAuthBasePage>
        <S.PublicContainerCol style={{ minHeight: 661 }}>
          <img src={LogoIcon} alt="logo" width={34} height={34} />
          <S.PublicTitle>Welcome to Fenix!</S.PublicTitle>
          <FormProvider {...methods}>
            <Form
              id="user-login"
              onSubmit={methods.handleSubmit((data) => {
                onSubmit(data);
              })}
            >
              <Row style={{ marginTop: 32 }}>
                <TextField xs={12} fieldId="email" label="Email" />
                <TextField
                  xs={12}
                  type="password"
                  fieldId="password"
                  label="Password"
                  height={10}
                />
                <LinkButton
                  label="Forgot your login credentials?"
                  style={{ width: '100%', justifyContent: 'right' }}
                  handleAction={() => {
                    navigate('/password-recovery');
                  }}
                />
              </Row>
              <Button
                style={{ width: '100%', marginTop: 42 }}
                className="button-primary"
                type="submit"
              >
                Login
              </Button>
              <hr style={{ background: '#B4CAE1' }} />
            </Form>
          </FormProvider>
          <S.PublicContainerRow>
            Don’t have an account yet?&nbsp;
            <LinkButton
              label="Sign up now"
              style={{ padding: 0, height: 'auto' }}
              handleAction={() => {
                navigate('/register');
              }}
            />
          </S.PublicContainerRow>
          <S.PublicContainerRow style={{ marginTop: 16 }}>
            Need help?&nbsp;
            <LinkButton
              label="Contact us"
              style={{ padding: 0, height: 'auto' }}
              handleAction={() => {
                navigate('/contact');
              }}
            />
          </S.PublicContainerRow>
        </S.PublicContainerCol>
      </NoAuthBasePage>
    </>
  );
};

export default withHelmet(LoginPage);
