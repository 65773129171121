import { FC, useState } from 'react';
import { APP_NAME, APP_VERSION } from 'constants/app.constants';

import { Button } from 'react-bootstrap';

import LinkButton from 'components/LinkButton';
import ModalContactUs from 'components/modals/ModalContactUs';

import * as S from './styles';

const AboutThis: FC = () => {
  const [showContactUs, setShowContactUs] = useState(false);
  return (
    <S.SettingsContainer>
      <S.AboutTitle>About this app</S.AboutTitle>
      <div className="section-subtitles mt-4 mb-4">
        {APP_NAME} for Web - version {APP_VERSION} <br />
        <br /> © {APP_NAME} Software {new Date().getFullYear()} - All rights
        reserved.
        <br /> All brands, logos and client information is proprietary of each
        own brand, and their usage by {APP_NAME} Software or its clients on this
        digital application cannot constitute any means of trademark violation.
      </div>
      <div className="mt-4 pt-2 d-flex flex-column align-items-start">
        <span className="section-subtitles font-weight-bold text-dark">
          Contact us
        </span>
        <span className="section-subtitles mt-2">
          Do you encounter any issues or wish to provide feedback? Please do not
          hesitate to reach out to us.
        </span>
        {showContactUs && (
          <ModalContactUs
            onClose={() => {
              setShowContactUs(false);
            }}
          />
        )}
      </div>
      <S.SupportFooterContainer>
        <S.ExternalLinksContainer>
          <LinkButton
            label="Terms & Conditions"
            handleAction={() => {
              const url = 'https://fenixcertify.com/terms-and-conditions';
              window.open(url, '_blank', 'noopener,noreferrer');
            }}
          />
          <LinkButton
            label="Privacy Policy"
            handleAction={() => {
              const url = 'https://fenixcertify.com/privacy-policy';
              window.open(url, '_blank', 'noopener,noreferrer');
            }}
          />
        </S.ExternalLinksContainer>
        <Button
          style={{ minWidth: 0 }}
          className="button-primary"
          onClick={() => {
            setShowContactUs(true);
          }}
        >
          Contact us
        </Button>
      </S.SupportFooterContainer>
    </S.SettingsContainer>
  );
};

export default AboutThis;
