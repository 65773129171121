import styled from 'styled-components';

import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/Form';

export const FormGroup = styled(Form.Group).attrs({ as: Col })`
  //   height: 98px;
  margin-bottom: 20px;
  position: relative;
`;
export const FormGroupWithErrorSpace = styled(Form.Group).attrs({ as: Col })`
  height: ${({ height }) => height || '98px'};
  margin-bottom: 20px;
  position: relative;
`;

export const FormLabelContainer = styled.div`
  display: flex;
  gap: 10px;
`;

export const FormLabel = styled(Form.Label)`
  align-items: flex-end;
  text-transform: initial;
  .required {
    color: #5488be;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
  }
  ${({ disabled }) => {
    if (disabled) {
      return `
        color:  #C1C1C1;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      `;
    }
  }};
`;

export const FormAuxLabel = styled(Form.Label)`
  margin-top: 8px;
  margin-bottom: 0;
  color: #272727;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  font-weight: 400;
  white-space: pre-line;
  padding-inline-start: 10px;
`;

export const FormControl = styled(Form.Control)`
  height: ${(props) => props.height || 'auto'};
  font-size: 17px;
  width: 100%;
  color: var(--color-primary);
  border-color: ${({ error }) =>
    error ? '#f04438 !important' : 'transparent'};
`;

export const FormSelect = styled(Form.Select)`
  height: ${({ height }) => height || 'auto'};
  font-size: 17px;
  padding: 6px 34px 6px 12px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  border: ${({ isInvalid }) =>
    isInvalid ? '1px solid #F04438 !important' : '1px solid transparent'};
  :disabled {
    opacity: 0.6;
  }
`;

export const FormControlFeedback = styled(Form.Control.Feedback)``;
