import styled from 'styled-components';

export const PresetCard = styled.div<{ highlightCard?: boolean }>`
  display: flex;
  position: relative;

  border-radius: 14px;
  border: 1px solid #f3f4f8;
  background: #fff;

  width: 354px;
  min-width: 214px;
  height: 110px;

  flex-shrink: 0;
  margin-right: 18px;

  padding 16px;
  ${({ highlightCard }) => {
    if (highlightCard) {
      return `
        border: 1px solid #5488BE;
        border-radius: 12px;
        box-shadow: 0 0 10px 0 #5488BE;`;
    }
  }}
`;

export const Container = styled.div<{
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
  cursor?: 'pointer';
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 11px;
  ${({ justifyContent }) => {
    return justifyContent ? `justify-content: ${justifyContent};` : '';
  }}
  ${({ cursor }) => {
    return cursor ? `cursor: ${cursor};` : '';
  }}
`;

export const CertificateType = styled.span`
  margin: 0;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const PresetDeleteIcon = styled.img`
  width: 24px;
  height: 24px;
  cursor: pointer;
  right: 8px;
  top: 8px;
  position: absolute;
`;

export const PresetName = styled.span`
  display: block;
  margin: 0;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #c1c1c1;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
