import styled from 'styled-components';

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 480px;
  min-width: 250px;
`;

export const CardHeader = styled.div`
  display: flex;
  width: 100%;
  height: 52px;
  padding: 16px;
  border-bottom: 1px solid #ccdcec;
  color: #272727;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const CardBody = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 24px 24px 24px;
`;
