import React, { FC } from 'react';

import * as S from './styles';

const CardTitle: FC<{ styles?: React.CSSProperties; className?: string }> = ({
  children,
  styles,
  className,
}) => {
  return (
    <S.CardTitle style={styles} className={className}>
      {children}
    </S.CardTitle>
  );
};

export default CardTitle;
