import {
  AUTH_REFRESH_TOKEN_COOKIE_NAME,
  AUTH_TOKEN_COOKIE_NAME,
} from 'constants/app.constants';
import { AppThunk } from 'store/@types';
import {
  LoginPost,
  LoginResponse,
  ResetPasswordProps,
  SendReinviteProps,
} from 'store/@types/user';

import { apiLoadUserOnboard, apiSubmitOnboardingData } from 'api/onboarding';
import {
  apiDeleteUser,
  apiLogin,
  apiRecoverPassword,
  apiResendVerifyEmail,
  apiSendReinvite,
} from 'api/user';
import { ApiError } from 'services/api';
import { removeCookie, setCookie } from 'utils/cookies';
import { newExpireDate, verifyAuthRoles } from 'utils/loginUtils';
import { prepareSubmitPayload } from 'utils/userOnboard';

import { setError, setLoading, setLogin, setOnBoarding } from './actions';

export const login =
  (
    payload: LoginPost,
    success: (successResponse?: LoginResponse) => void
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      const loginResponse = await apiLogin(payload);
      const { user, ...UserAuth } = loginResponse;
      const { access_token, refresh_token, expires_in, refresh_expires_in } =
        UserAuth;

      const hasAuthRoles = verifyAuthRoles(user.roles);
      if (!hasAuthRoles) {
        throw new Error('noAccessRoles');
      }

      const tokenExpireDate = newExpireDate(expires_in);
      const refreshTokenExpireDate = newExpireDate(refresh_expires_in);

      setCookie(AUTH_TOKEN_COOKIE_NAME, access_token, tokenExpireDate);
      setCookie(
        AUTH_REFRESH_TOKEN_COOKIE_NAME,
        refresh_token,
        refreshTokenExpireDate
      );

      dispatch(setLogin(loginResponse));
      success(loginResponse);
    } catch (error: unknown) {
      dispatch(setError(error));
    }
  };

export const logout =
  (redirect?: boolean): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      removeCookie(AUTH_TOKEN_COOKIE_NAME);
      removeCookie(AUTH_REFRESH_TOKEN_COOKIE_NAME);
      dispatch(setOnBoarding(null));
      dispatch(setLogin(null));
      if (redirect) {
        window.location.href = '/login';
      }
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const resendVerifyEmail =
  (
    token: string,
    handle: (successResponse?: boolean, errorResponse?: ApiError) => void
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      await apiResendVerifyEmail(token);

      dispatch(setLoading(false));
      handle(true);
    } catch (error) {
      dispatch(setError(error));
      handle(undefined, error as ApiError);
    }
  };

export const fetchOnboarding =
  (onBoardingType: string): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      const onBoarding = await apiLoadUserOnboard(onBoardingType);

      dispatch(setOnBoarding(onBoarding));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const submitOnboarding =
  (
    onBoardingType: string,
    payload: any,
    handle: (successResponse?: boolean, errorResponse?: ApiError) => void
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      const submitPayload = prepareSubmitPayload(payload);
      await apiSubmitOnboardingData(onBoardingType, submitPayload);
      handle(undefined, undefined);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error));
      handle(undefined, error as ApiError);
    }
  };

export const recoverPassword =
  (
    resetPasswordProps: ResetPasswordProps,
    handle: (successResponse?: boolean, errorResponse?: ApiError) => void
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      await apiRecoverPassword(resetPasswordProps);

      dispatch(setLoading(false));
      handle(true);
    } catch (error) {
      dispatch(setError(error));
      handle(undefined, error as ApiError);
    }
  };

export const sendReinvite =
  (
    sendReinviteProps: SendReinviteProps,
    handle: (successResponse?: boolean, errorResponse?: ApiError) => void
  ): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      await apiSendReinvite(sendReinviteProps);

      dispatch(setLoading(false));
      handle(true);
    } catch (error) {
      dispatch(setError(error));
      handle(undefined, error as ApiError);
    }
  };

export const deleteUserAndLogout =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      await apiDeleteUser();
      dispatch(logout(true));
    } catch (error) {
      dispatch(setError(error));
    }
  };
