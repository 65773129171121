import Lottie from 'react-lottie';

import * as loadingAnimationData from 'assets/images/logo/loading-lottie.json';

import './styles.css';

function SplashScreen() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div id="splash-screen">
      <Lottie options={defaultOptions} height={66} width={66} />
    </div>
  );
}

export default SplashScreen;
