import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'react-bootstrap';

import LogoIcon from 'assets/images/symbol.svg';
import withHelmet from 'hooks/withHelmet';
import { ApiError } from 'services/api';
import { defaultToast, notifications, toast } from 'utils/toast';

import LinkButton from 'components/LinkButton';
import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';
import SecondaryButton from 'components/SecondaryButton';

import * as S from '../styles';
import { ReduxProps } from '.';

const VerifyExpiredPage: FC<ReduxProps> = ({
  logout,
  user: reduxUser,
  resendVerifyEmail,
}) => {
  const navigate = useNavigate();
  const [successOperation, setSuccessOperation] = useState(false);
  const { token } = useParams();
  const { loading } = reduxUser;

  const onSubmit = async () => {
    if (token) {
      resendVerifyEmail(token, (success?: boolean, error?: ApiError) => {
        success && setSuccessOperation(true);
        error && defaultToast('E');
      });
    }
  };

  useEffect(() => {
    if (successOperation) {
      toast('The email was sent successfully', {
        ...notifications.success,
        toastId: 'Contact',
      });
    }
  }, [successOperation]);

  return (
    <>
      {loading && <SplashScreen />}
      <NoAuthBasePage>
        {!successOperation && (
          <S.PublicContainerCol style={{ minHeight: 661 }}>
            <img src={LogoIcon} alt="logo" width={34} height={34} />
            <S.PublicTitle>The link has expired</S.PublicTitle>

            <S.PublicSubTitle>
              It appears that the link you used to verify your Fenix account has
              expired.
            </S.PublicSubTitle>

            <S.PublicSubTitle>
              To receive a new verification link, press the button below:
            </S.PublicSubTitle>
            <Button
              style={{ width: '100%', marginTop: 26 }}
              className="button-primary"
              onClick={() => {
                onSubmit();
              }}
            >
              Resend link
            </Button>
            <hr style={{ background: '#B4CAE1' }} />
            <SecondaryButton
              label="Go to login"
              style={{ width: '100%' }}
              center
              handleAction={() => {
                logout(true);
              }}
            />
            <S.PublicContainerRow style={{ marginTop: 16 }}>
              Need help?&nbsp;
              <LinkButton
                label="Contact us"
                style={{ padding: 0, height: 'auto' }}
                handleAction={() => {
                  navigate('/contact');
                }}
              />
            </S.PublicContainerRow>
          </S.PublicContainerCol>
        )}
        {successOperation && (
          <S.PublicContainerCol style={{ minHeight: 661 }}>
            <img src={LogoIcon} alt="logo" width={34} height={34} />
            <S.PublicTitle>Verify your account</S.PublicTitle>
            <S.PublicSubTitle>
              Your account has been created successfully, but it is pending
              activation.
            </S.PublicSubTitle>

            <S.PublicSubTitle>
              If you don&apos;t receive anything in the next 10 minutes, please
              be sure to check your spam folder, as some servers can filter
              automated responses messages.
            </S.PublicSubTitle>

            <Button
              style={{ width: '100%', marginTop: 16 }}
              className="button-primary"
              onClick={() => {
                logout(true);
              }}
            >
              Login
            </Button>
            <hr style={{ background: '#B4CAE1' }} />
            <S.PublicContainerRow>
              Need help?&nbsp;
              <LinkButton
                label="Contact us"
                style={{ padding: 0, height: 'auto' }}
                handleAction={() => {
                  navigate('/contact');
                }}
              />
            </S.PublicContainerRow>
          </S.PublicContainerCol>
        )}
      </NoAuthBasePage>
    </>
  );
};

export default withHelmet(VerifyExpiredPage);
