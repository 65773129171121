import styled from 'styled-components';

export const SecondaryButton = styled.button<{ center?: boolean }>`
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: ${({ center }) => (center ? 'center' : 'space-between')};
  padding-inline: 16px;
  gap: 10px;
  height: 44px;
  min-height: 24px;
  max-height: 64px;
  background-color: unset;
  border-radius: 8px;
  border: 1px solid #133e6b;
  opacity: 1;
  background: #fff;
  transition: all 0.3s;

  .secondary-buton-image {
    display: block;
  }
  .secondary-buton-image-hover {
    display: none;
  }

  :hover {
    border: 0;
    border-radius: 8px;
    background: #dadfe3;
    border: 1px solid #dadfe3;
  }

  :active {
    background: #2b3d4f;
    border: 1px solid #2b3d4f;
    > span {
      color: #ffffff;
    }

    .secondary-buton-image {
      display: none;
    }
    .secondary-buton-image-hover {
      display: block;
    }
  }

  :disabled {
    background: unset;
    border: 0;
    > span {
      color: #c1c1c1;
    }
  }
`;

export const SecondaryButtonLabel = styled.span`
  color: #2b3d4f;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
