import { CSSProperties, FC } from 'react';

import * as S from './styles';

export type TabProps = {
  className?: string;
  style?: CSSProperties;
};

const Tab: FC<TabProps> = ({ children, className, style }) => {
  return (
    <S.TabContainer className={className} style={style}>
      {children}
    </S.TabContainer>
  );
};

export default Tab;
