import React, { FC } from 'react';

import * as S from './styles';

const CardItem: FC<{
  styles?: React.CSSProperties;
  className?: string;
  highlight?: boolean;
}> = ({ children, styles, className, highlight }) => {
  return (
    <S.CardItem style={styles} className={className} highlight={highlight}>
      {children}
    </S.CardItem>
  );
};

export default CardItem;
