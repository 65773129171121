import React, { FC, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';

import { Button, Col, Form, Row } from 'react-bootstrap';

import { TextField } from 'components/Form';
import ModalConfirm from 'components/modals/ModalConfirm';

import { defaultValues, yupSchema } from './yupSchema';

interface DeleteCustomerProps {
  ogName: string;
  onDelete: () => void;
}

const DeleteCustomer: FC<DeleteCustomerProps> = ({ ogName, onDelete }) => {
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [locked, setLocked] = useState(true);
  // React hook form instance
  const formMethods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(yupSchema(ogName)),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  return (
    <>
      <FormProvider {...formMethods}>
        <Form
          id="delete-customer-form"
          onSubmit={formMethods.handleSubmit(() => {
            setShowDeleteConfirmation(true);
          })}
        >
          <Row>
            <Col xs={12}>
              <hr />
            </Col>
          </Row>
          <Row className="mr-5">
            <Col xs={12}>
              <h2 className="section-title">Delete customer</h2>
              <h3 className="section-subtitles">
                This section is locked for security reasons. Please proceed with
                caution as any changes cannot be undone
              </h3>
            </Col>
            {!locked && (
              <>
                <TextField
                  xs={6}
                  required
                  fieldId="customerName"
                  className="red-border-input"
                  label="Delete customer"
                  placeholder="Enter the customer name here, in caps, to delete."
                />
                <TextField
                  xs={6}
                  required
                  className="red-border-input"
                  fieldId="customerNameConfirmation"
                  label="&nbsp;"
                  placeholder="Repeat the customer name here, in caps, to delete."
                />
              </>
            )}
          </Row>
          <Row className="mb-5">
            <Col xs={12}>
              {locked ? (
                <Button
                  type="button"
                  className="button-primary float-right"
                  onClick={(e) => {
                    e.preventDefault();
                    setLocked(false);
                  }}
                >
                  Unlock customer deletion
                </Button>
              ) : (
                <Button className="button-red float-right" type="submit">
                  Delete customer
                </Button>
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={12}></Col>
          </Row>
        </Form>
      </FormProvider>

      {/* DELETE CUSTOMER ACCOUNT CONFIRMATION MODAL */}
      {showDeleteConfirmation && (
        <ModalConfirm
          title="Delete customer?"
          text={'Are you certain you wish to delete this customer?'}
          confirmText="Cancel"
          actionConfirm={() => {
            setShowDeleteConfirmation(false);
          }}
          cancelText="Delete customer"
          actionCancel={onDelete}
        />
      )}
    </>
  );
};

export default DeleteCustomer;
