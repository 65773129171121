import styled from 'styled-components';

import BootstrapModal from 'react-bootstrap/Modal';

export const Modal = styled(BootstrapModal)`
  .modal-dialog,
  .modal-content {
    width: 638px;
    height: max-content;
  }
  .modal-body {
    width: 638px;
  }
`;

export const ModalHeader = styled(BootstrapModal.Header)`
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding-block: 15px;
  border-bottom: 1px solid #ccdcec;

  button {
    margin: 0;
    border: 0;
    background-color: unset;
    position: absolute;
    left: 24px;
    color: #666;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  h1 {
    margin: 0;
    font-size: 1.5rem;
    color: #5488be;
  }
`;
export const ModalBody = styled(BootstrapModal.Body)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-center;
  border: none;
  padding: 24px;
`;

export const Subtitle = styled.p`
  color: #666;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const SubscriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-top: 30px;
  margin-bottom: 10px;
`;

export const ModalFooter = styled(BootstrapModal.Footer)`
  align-content: flex-end;
  border: none;
`;
