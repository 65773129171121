import styled from 'styled-components';

export const Main = styled.main`
  width: 100vw;
  display: flex;
  flex-direction: column;
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0;
  background: #f9fbfc;
  overflow: hidden;
  height: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  max-width: none;
  padding-bottom: 25px;
  padding-inline: 20px;

  @media only screen and (min-width: 992px) {
    padding-inline: 70px;
  }
}
`;

export const Header = styled.header`
  width: 100%;
  min-height: 70px;
  display: flex;
  flex-shrink: 0;
  gap: 10px;
  padding-inline: 70px;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #5488be4d;
  background: #fff;
`;

export const HeaderLogo = styled.img`
  width: 129px;
  height: 47px;
`;

export const HeaderWorkspaceCointainer = styled.div`
  display: flex;
  padding: 20px 40px 0px 0px;
`;

export const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderUserMenuContent = styled.div`
  width: 343px;
  height: 153px;
  top: 80px;
  right: 30px;
  padding: 20px;
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  border-radius: 12px;
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.2);

  background: #fdfeff;

  z-index: 999;
`;
