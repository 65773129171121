import styled from 'styled-components';

export const ButtonClose = styled.img`
  align-self: flex-end;
  cursor: pointer;
  margin-top: 16px;
  margin-bottom: 9px;
`;

export const QRCode = styled.img`
  width: 173px;
  height: 171px;
  align-self: center;
  margin-top: 60px;
  margin-bottom: 126px;
`;

export const Subtitle = styled.p`
  color: #272727;
  font-size: 16px;
  line-height: 19px;
  width: 565px;
  text-align: left;
  padding-top: 3px;
  padding-left: 3px;
`;
