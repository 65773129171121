import styled from 'styled-components';

export const TabContainer = styled.div`
  display: flex;
  height: 30px;
  flex-shrink: 0;
  width: 100%;
  gap: 42px;
  padding-left: 42px;
  border-bottom: 1px solid #f3f4f8;
`;
