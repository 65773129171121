import React from 'react';
import { OnboardingPage } from 'store/@types/user';

import Button from 'react-bootstrap/esm/Button';

import SecondaryButton from 'components/SecondaryButton';

import * as S from '../styles';

interface TextImageProps {
  page: OnboardingPage;
  nextStep: () => void;
  prevStep: () => void;
}

const TextImage: React.FC<TextImageProps> = ({ page, prevStep, nextStep }) => {
  const {
    title,
    subtitle,
    description,
    primaryButton,
    secondaryButton,
    image,
  } = page;
  return (
    <>
      {title && <S.OnboardingTitle>{title}</S.OnboardingTitle>}
      {subtitle && <S.OnboardingSubTitle>{subtitle}</S.OnboardingSubTitle>}
      <S.OnboardingContainerCol style={{ textAlign: 'center' }}>
        {image && <img width={357} src={image} alt={title} />}
        {description && description}
      </S.OnboardingContainerCol>
      {secondaryButton && (
        <SecondaryButton
          handleAction={prevStep}
          label={secondaryButton}
          center
        />
      )}
      {primaryButton && (
        <Button
          style={{ width: '100%', marginTop: 42 }}
          className="button-primary"
          onClick={nextStep}
        >
          {primaryButton}
        </Button>
      )}
    </>
  );
};

export default TextImage;
