import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import EditButtonIcon2 from 'assets/images/iconset/icon-button-edit2.svg';
import { CertData } from 'interfaces/CertificateData';
import { formatDateUtil } from 'utils/dateUtils';

import DataTable, { DataTableRow } from 'components/DataTable';
import TertiaryButton from 'components/TertiaryButton';

interface NonIssuedCertificatesTableProps {
  certificates?: CertData[];
}

const NonIssuedCertificatesTable: FC<NonIssuedCertificatesTableProps> = ({
  certificates,
}) => {
  const navigate = useNavigate();

  return (
    <DataTable
      headers={[
        { value: 'Certificate' },
        { value: 'Customer' },
        { value: 'Address' },
        { value: 'Last modified' },
        { value: '' },
      ]}
      rows={certificates?.flatMap((certificate, index): DataTableRow[] => {
        const { street, city, postalCode } =
          certificate?.customer?.headquartersAddress ?? {};

        const mainAddress = `${street}, ${city}, ${postalCode}`;
        return [
          {
            index,
            columns: [
              {
                index: 0,
                value: certificate.template?.certificateName,
                width: '15%',
              },
              {
                index: 1,
                value: certificate.customer?.name,
                width: '30%',
              },
              {
                index: 2,
                value: mainAddress,
                width: '30%',
              },
              {
                index: 3,
                value: formatDateUtil({
                  short: true,
                  dateString: String(certificate.updatedAt),
                  fullYear: true,
                }),
                width: '20%',
              },
              {
                index: 4,
                value: (
                  <TertiaryButton
                    icon={EditButtonIcon2}
                    handleAction={() => {
                      navigate(
                        `/manage-certificate/${certificate.template?._id}/${certificate._id}`
                      );
                    }}
                  />
                ),
                width: '5%',
              },
            ],
          },
        ];
      })}
    />
  );
};

export default NonIssuedCertificatesTable;
