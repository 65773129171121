import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { ResetPasswordProps } from 'store/@types/user';

import { Button, Form, Row } from 'react-bootstrap';

import { apiRecoverPassword } from 'api/user';
import LogoIcon from 'assets/images/symbol.svg';
import withHelmet from 'hooks/withHelmet';
import { defaultToast } from 'utils/toast';

import { TextField } from 'components/Form';
import LinkButton from 'components/LinkButton';
import NoAuthBasePage from 'components/root/NoAuthBasePage';
import SplashScreen from 'components/root/SplashScreen';
import SecondaryButton from 'components/SecondaryButton';

import * as S from '../styles';
import { defaultValues, schema } from './yupSchema';

function ResetPassword() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [successOperation, setSuccessOperation] = useState(false);

  const { search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);

  // React hook form instance
  const methods = useForm<ResetPasswordProps>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    defaultValues,
    reValidateMode: 'onChange',
    shouldUnregister: false,
  });

  useEffect(() => {
    const token = query.get('token');
    if (!token) navigate('/login');
  }, [query, navigate]);

  const onSubmit = async (payload: ResetPasswordProps) => {
    try {
      await apiRecoverPassword(payload);
      setSuccessOperation(true);
      setIsLoading(false);
    } catch (err) {
      defaultToast('E');
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <SplashScreen />}
      <NoAuthBasePage>
        {!successOperation && (
          <S.PublicContainerCol style={{ minHeight: 661 }}>
            <img src={LogoIcon} alt="logo" width={34} height={34} />
            <S.PublicTitle>Set new password</S.PublicTitle>
            <S.PublicSubTitle>
              Enter the new password for your Fenix account.
            </S.PublicSubTitle>
            <FormProvider {...methods}>
              <Form
                id="reset-pw"
                onSubmit={methods.handleSubmit((data) => {
                  setIsLoading(true);
                  onSubmit({ ...data, token: query.get('token') ?? '' });
                })}
              >
                <Row>
                  <TextField
                    xs={12}
                    type="password"
                    fieldId="newPassword"
                    label="Password"
                  />
                  <TextField
                    xs={12}
                    type="password"
                    fieldId="passwordConfirm"
                    label="Confirm password"
                  />
                </Row>
                <Button
                  style={{ width: '100%', marginTop: 0 }}
                  className="button-primary"
                  type="submit"
                >
                  Set new password
                </Button>
                <hr style={{ background: '#B4CAE1' }} />
                <SecondaryButton
                  label="Back to login"
                  style={{ width: '100%' }}
                  center
                  handleAction={() => {
                    navigate('/login');
                  }}
                />
                <S.PublicContainerRow style={{ marginTop: 16 }}>
                  Need help?&nbsp;
                  <LinkButton
                    label="Contact us"
                    style={{ padding: 0, height: 'auto' }}
                    handleAction={() => {
                      navigate('/contact');
                    }}
                  />
                </S.PublicContainerRow>
              </Form>
            </FormProvider>
          </S.PublicContainerCol>
        )}
        {successOperation && (
          <S.PublicContainerCol style={{ minHeight: 661 }}>
            <img src={LogoIcon} alt="logo" width={34} height={34} />
            <S.PublicTitle>New password set</S.PublicTitle>

            <S.PublicSubTitle>
              You can now login with your new password.
            </S.PublicSubTitle>

            <Button
              style={{ width: '100%', marginTop: 59 }}
              className="button-primary"
              onClick={() => {
                navigate('/login');
              }}
            >
              Go to login
            </Button>
            <hr style={{ background: '#B4CAE1' }} />
            <S.PublicContainerRow>
              Need help?&nbsp;
              <LinkButton
                label="Contact us"
                style={{ padding: 0, height: 'auto' }}
                handleAction={() => {
                  navigate('/contact');
                }}
              />
            </S.PublicContainerRow>
          </S.PublicContainerCol>
        )}
      </NoAuthBasePage>
    </>
  );
}

export default withHelmet(ResetPassword);
