import styled from 'styled-components';

export const ColorSelectWrapper = styled.div<{ disabled?: boolean }>`
  ${({ disabled }) => disabled && 'opacity: 0.3;'}
  display: flex;
`;

export const ColorSelectBox = styled.div<{ selected?: boolean }>`
  ${({ selected }) => {
    if (selected) {
      return `
        background-color: #ffffff;
        border: 1px solid #B4CAE1;
        border-radius: 50px;
        box-shadow: 0px 4px 13.1px -8px #7FA8BC;
        `;
    }
  }}

  min-width: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const ColorSelectBody = styled.div<{ backgroundColor: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 100%;
  width: 30px;
  height: 30px;
`;
