import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'react-bootstrap/esm/Button';

import MissingImg from 'assets/images/logo/image_missing_information.png';

import SecondaryButton from 'components/SecondaryButton';

import * as S from './styles';

const MissingInfoModal: FC<{ close: () => void }> = ({ close }) => {
  const navigate = useNavigate();
  return (
    <S.Modal show={true} backdrop="static">
      <S.ModalBodyContainer className="modal-body">
        <S.ModalTitle>
          We need some information before you can issue certificates
        </S.ModalTitle>
        <S.ModalSubTitle>
          In order to issue and sign certificates you need to complete your
          profile information. Head over to the Settings and enter the required
          data to proceed.
        </S.ModalSubTitle>
        <img
          style={{ marginBlock: 40 }}
          src={MissingImg}
          alt="onboarding-open-img"
          width={319}
          height={316}
        />
      </S.ModalBodyContainer>
      <S.ModalFooterContainer className="modal-footer">
        <SecondaryButton label="Update later" handleAction={close} />
        <Button
          className="button-primary"
          type="button"
          style={{ minWidth: 0 }}
          onClick={() => {
            navigate('/settings', {
              state: { verify: true },
            });
          }}
        >
          Update my profile
        </Button>
      </S.ModalFooterContainer>
    </S.Modal>
  );
};

export default MissingInfoModal;
