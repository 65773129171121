import { FC, useMemo } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldValues,
  useFormContext,
} from 'react-hook-form';

import { FormControlProps } from 'react-bootstrap';

import { FieldType } from 'enum/FieldType.enum';
import useCerts from 'hooks/useCerts';
import { FieldAffects } from 'interfaces/CertificateTemplate';

import Comments from 'components/Comments';
import {
  FormControl,
  FormControlFeedback,
  FormGroupWithErrorSpace,
  FormLabel,
  FormLabelContainer,
} from 'components/Form/styles';

import { getError } from '..';
import { CommonProps } from '../Types';

interface TextFieldProps extends FormControlProps, CommonProps {
  placeholder?: string;
  maxLength?: number;
  decimal?: boolean;
  required?: boolean;
  onlyInput?: boolean;
  affects?: FieldAffects[];
  isTextArea?: boolean;
  hasError?: unknown;
  additionalOnBlur?: (text: string) => void;
  [x: string]: unknown;
}

const TextField: FC<TextFieldProps> = ({
  xs,
  fieldId,
  label,
  type,
  decimal,
  disabled,
  placeholder,
  maxLength,
  onlyInput,
  affects,
  required,
  inputImage,
  isCertificate,
  value,
  hasError,
  additionalOnBlur,
  autoObservations,
  isTextArea,
  ...rest
}) => {
  const {
    control,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const error = getError(fieldId, errors) || hasError;
  const { autoSave, affectedFields, handleAffects, handleAutoObservations } =
    useCerts();

  const disabledField = useMemo(() => {
    return affectedFields && affectedFields[fieldId]
      ? affectedFields[fieldId].disabled
      : disabled;
  }, [affectedFields, disabled]);

  const currentValue: string = maxLength ? watch(fieldId) : null;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const removibles = ['e', 'E', '+', '-'];
    if (!decimal) {
      removibles.push(...[',', '.']);
    }

    return (
      type === 'number' &&
      removibles.includes(event.key) &&
      event.preventDefault()
    );
  };

  const handleSave = (newValue: string) => {
    if (isCertificate) {
      if (affects) {
        handleAffects({
          fieldId,
          type: FieldType.Textbox,
          affects,
          newVal: newValue,
          getValues,
          setValue,
        });
      }
      if (autoObservations) {
        handleAutoObservations({
          fieldId,
          autoObservations,
          getValues,
          setValue,
        });
      }
      autoSave({
        type: isTextArea ? FieldType.Textarea : FieldType.Textbox,
        fieldId,
        value: newValue,
        getValues,
        setValue,
      });
    }
  };

  const handleOnBlur = (
    e: React.ChangeEvent<HTMLSelectElement>,
    field: ControllerRenderProps<FieldValues, string>
  ) => {
    const newValue = e.target.value;

    field.onBlur();
    if (additionalOnBlur) {
      additionalOnBlur(newValue);
    }
    handleSave(newValue);
  };

  const maxLengthProps = {
    ...(maxLength && {
      maxLength,
      as: 'textarea',
      height: '100px',
      error: Boolean(error),
    }),
  };

  if (onlyInput) {
    return (
      <Controller
        name={fieldId}
        control={control}
        render={({ field }) => (
          <FormControl
            {...field}
            value={value ?? field.value}
            {...maxLengthProps}
            type={type}
            disabled={disabledField}
            isInvalid={Boolean(error)}
            placeholder={placeholder}
            onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
              handleOnBlur(e, field);
            }}
            onKeyDown={handleKeyDown}
            {...rest}
          />
        )}
      />
    );
  }

  return (
    <FormGroupWithErrorSpace xs={xs} {...(maxLength && { height: '144px' })}>
      {label && (
        <FormLabel disabled={disabled}>
          <FormLabelContainer>
            {label}{' '}
            <Comments
              fieldId={fieldId}
              disabledField={disabled}
              isCertificate={isCertificate}
              handleOnChange={(comments) => {
                autoSave({
                  type: isTextArea ? FieldType.Textarea : FieldType.Textbox,
                  fieldId,
                  value: getValues(fieldId),
                  getValues,
                  setValue,
                  comments,
                });
              }}
            />
          </FormLabelContainer>
          {required && <span className="required float-right">Required</span>}
        </FormLabel>
      )}
      {fieldId === 'null' ? (
        <FormControl
          value={value}
          {...maxLengthProps}
          disabled={disabledField}
          isInvalid={Boolean(error)}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          {...rest}
        />
      ) : (
        <Controller
          name={fieldId}
          control={control}
          render={({ field }) => (
            <FormControl
              {...field}
              value={value ?? field.value}
              {...maxLengthProps}
              type={type}
              disabled={disabledField}
              isInvalid={Boolean(error)}
              placeholder={placeholder}
              onBlur={(e: React.ChangeEvent<HTMLSelectElement>) => {
                handleOnBlur(e, field);
              }}
              onKeyDown={handleKeyDown}
              {...rest}
            />
          )}
        />
      )}
      {inputImage}
      <FormControlFeedback type="invalid">
        <span style={{ width: 'calc(100% - 145px)' }}>{error?.message}</span>
        {maxLength && (
          <span className="textAreaChars" style={{ width: '145px' }}>
            ({currentValue?.length || '0'}/{maxLength} characters)
          </span>
        )}
      </FormControlFeedback>
    </FormGroupWithErrorSpace>
  );
};

TextField.defaultProps = {
  xs: 12,
};

export { TextField };
