import { FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { FormControlProps } from 'react-bootstrap';

import { CertificateState } from 'enum/certificateDataState.enum';
import useCerts from 'hooks/useCerts';
import { BoardGridCard } from 'interfaces/CertificateData';
import { getBoardName } from 'utils/certificates/defaultValues';
import { isPreset } from 'utils/certificates/presetUtils';

import { CommonProps } from '../Types';
import BoardCard from './BoardGridCard';

interface FormLabelProps extends FormControlProps, CommonProps {
  [x: string]: unknown;
}

export const BoardGrid: FC<FormLabelProps> = ({ fieldId }) => {
  const { control, reset } = useFormContext();
  const {
    createNewBoard,
    selectedPage,
    setSelectedPage,
    clearAll,
    loadCertificateBoards,
    certTemplate,
    certData,
  } = useCerts();

  const navigate = useNavigate();

  const currentValue: BoardGridCard[] = useWatch({
    control,
    name: fieldId,
  });

  const handleNewBoard = async () => {
    createNewBoard(fieldId, reset, (newBoardId: string, index: number) => {
      const newBoardName = getBoardName(fieldId, index, newBoardId);

      setSelectedPage(newBoardName);
      navigate(
        `${window.location.pathname}?page=${newBoardName}&prevPage=${selectedPage}`,
        {
          replace: true,
        }
      );
    });
  };

  const handleBoardGridAction = (boardName: string, boardId: string) => {
    if (
      !isPreset() &&
      certTemplate &&
      certData &&
      certData?.certificateState !== CertificateState.ISSUED
    ) {
      loadCertificateBoards(
        certData,
        fieldId, //Board grid id
        // onLoad then clear all
        () => {
          clearAll({ skipFormChecked: true });
          reset({});
        },
        boardId // board id
      );
    }
    setSelectedPage(boardName);
    navigate(
      `${window.location.pathname}?page=${boardName}&prevPage=${selectedPage}`,
      {
        replace: true,
      }
    );
    return null;
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: 40,
        flexWrap: 'wrap',
        paddingLeft: 20,
        marginBottom: 40,
      }}
    >
      {currentValue?.map((card, index) => {
        const boardName = getBoardName(fieldId, index, card.id);
        return (
          <BoardCard
            key={card.id}
            boardName={boardName}
            fieldId={fieldId}
            boardItem={card}
            onClick={() => {
              handleBoardGridAction(boardName, card.id);
            }}
          />
        );
      })}

      <BoardCard
        addCard
        fieldId={fieldId}
        boardItem={null}
        onClick={handleNewBoard}
        boardName=""
      />
    </div>
  );
};
