import { FC, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PhotoPayload } from 'store/@types/user';

import { FormControlProps } from 'react-bootstrap';

import deleteImg from 'assets/images/iconset/ios_closemodal.svg';
import NoPhoto from 'assets/images/iconset/no_photo.svg';
import UploadPhotoIcon from 'assets/images/iconset/upload-photo.svg';

import * as FormStyles from 'components/Form/styles';
import LinkButton from 'components/LinkButton';

import { getError } from '..';
import { CommonProps } from '../Types';
import * as S from './styles';

import './styles.css';

interface TextFieldProps extends Omit<FormControlProps, 'value'>, CommonProps {
  placeholder?: string;
  height?: string;
  required?: boolean;
  value?: PhotoPayload;
  inputSize?: number;
  onDelete?: (file: File | null, base64Data: string | null) => void;
  onload: (file: File, base64Data: string) => void;
  [x: string]: unknown;
}

const UploadPhoto: FC<TextFieldProps> = ({
  xs,
  fieldId,
  label,
  disabled,
  placeholder,
  auxLabel,
  required,
  inputSize,
  onDelete,
  onload,
  height,
  ...rest
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [base64Data, setBase64Data] = useState<string | null>(null);
  const formRef = useRef<HTMLInputElement>(null);

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const error = getError(fieldId, errors);

  const removePhotoLabelProps = {
    onClick: () => {
      setValue(fieldId, '', {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setFile(null);
      setBase64Data(null);
      if (onDelete) {
        onDelete(file, base64Data);
      }
    },
  };

  const addPhotoLabelProps = {
    onClick: () => {
      document.getElementById(fieldId + 'input')?.click();
    },
  };

  useEffect(() => {
    if (file) {
      // THIS IS A FIX FOR THE IMAGE COMPONENT ON SAFARI
      // WHEN THE INPUT TYPE[FILE] CHANGES STATE, ON SAFARI IT SHOWS AN
      // SMALL ICON FILE THAT CANNOT BE REMOVED, SO WE RESET THE FORM AND IT WORKS
      (formRef?.current as any)?.form?.reset();
    }
  }, [file]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files !== null) {
      const file = e.currentTarget.files[0];
      const reader = new FileReader();

      reader.onload = (upload) => {
        const base64 = upload.target?.result;
        if (base64 && typeof base64 === 'string') {
          setBase64Data(base64);
          setFile(file);
          onload(file, base64);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const getBackground = (
    photoPosition: string,
    photoSize: string,
    value: any
  ) => {
    let backgroundUrl = '';

    if (inputSize === 1) {
      backgroundUrl = base64Data ?? (value || NoPhoto);
    }

    if (inputSize === 2) {
      backgroundUrl = NoPhoto;
    }

    if (inputSize === 3) {
      backgroundUrl = base64Data ?? (value || UploadPhotoIcon);
    }

    return `#F9FBFC url('${backgroundUrl}') ${photoPosition}/${photoSize} no-repeat`;
  };

  return (
    <Controller
      name={fieldId}
      control={control}
      render={({ field }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { value, onChange, ...newField } = field;

        let photoSize = 'cover';
        let photoPosition = 'center';

        if (inputSize === 2) {
          photoSize = '20px 20px';
          photoPosition = 'calc(100% - 18px) center';
        }

        if (!value && !file) {
          if (inputSize === 1) {
            photoSize = '20px 20px';
          } else if (inputSize === 2) {
            photoSize = '20px 20px';
          } else if (inputSize === 3) {
            photoSize = '35px 35px';
            photoPosition = 'center 46px';
          } else {
            photoSize = '50% 50%';
          }
        }

        return (
          <FormStyles.FormGroupWithErrorSpace xs={xs} height={height}>
            {!!label && (
              <FormStyles.FormLabel>
                {label}{' '}
                {required && (
                  <span className="required float-right">Required</span>
                )}
              </FormStyles.FormLabel>
            )}
            {inputSize === 1 && (
              <div className="tag-file">
                {(file || value) && (
                  <LinkButton
                    handleAction={removePhotoLabelProps.onClick}
                    label="Remove profile photo"
                  />
                )}
                {!value && !file && (
                  <LinkButton
                    handleAction={addPhotoLabelProps.onClick}
                    label="Upload profile photo"
                  />
                )}
              </div>
            )}
            {inputSize === 2 && (
              <>
                {!value && !file && (
                  <p
                    className="size-2-upload-label size-2-upload-label-placeholder"
                    onClick={() => {
                      if (formRef.current) {
                        formRef.current.click();
                      }
                    }}
                  >
                    Select a file (recommended size: 220 x 34)
                  </p>
                )}
                {(file || value) && (
                  <p className="size-2-upload-label size-2-upload-label-filled">
                    {file ? file.name : value}
                  </p>
                )}
              </>
            )}
            {inputSize === 3 && (
              <>
                {(file || value) && (
                  <span
                    className="big-input-upload-remove-label"
                    {...removePhotoLabelProps}
                    style={{ backgroundImage: `url(${deleteImg})`, zIndex: 1 }}
                  />
                )}
                {!value && !file && (
                  <S.UploadPhotoLabelContainer
                    onClick={addPhotoLabelProps.onClick}
                  >
                    <span>Click to upload</span>
                    <span>PNG or JPG (max. 400x400px)</span>
                  </S.UploadPhotoLabelContainer>
                )}
              </>
            )}
            <FormStyles.FormControl
              {...newField}
              type="file"
              disabled={disabled}
              ref={formRef}
              id={fieldId + 'input'}
              className={'photo-upload-' + inputSize}
              draggable="false"
              capture="user"
              style={{
                cursor: base64Data || value ? 'default' : 'pointer',
                background: getBackground(photoPosition, photoSize, value),
              }}
              isInvalid={Boolean(error)}
              placeholder={placeholder}
              accept=".jpg, .jpeg, .png"
              onChange={handleOnChange}
              {...rest}
            />
            {auxLabel && (
              <FormStyles.FormLabel>{auxLabel}</FormStyles.FormLabel>
            )}
            <FormStyles.FormControlFeedback type="invalid">
              {error?.message}
            </FormStyles.FormControlFeedback>
          </FormStyles.FormGroupWithErrorSpace>
        );
      }}
    />
  );
};

UploadPhoto.defaultProps = {
  xs: 12,
  inputSize: 1,
};

export { UploadPhoto };
