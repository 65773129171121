import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoleEnum } from 'store/@types/user';

import { apiListDashboardsInfo } from 'api/home';
import useAuth from 'hooks/useAuth';
import useCerts from 'hooks/useCerts';
import { CertData } from 'interfaces/CertificateData';
import { CertTemplate } from 'interfaces/CertificateTemplate';
import { DashBoardCounters } from 'interfaces/DashBoard';
import { IssuedCertData } from 'interfaces/IssuedCertificateData';
import { sortCertificateDataByLastUpdated } from 'utils/certificates/helpers';

import CardDefault from 'components/CardDefault';
import CardDivider from 'components/CardDefault/CardDivider';
import DropdownButton from 'components/DropdownButton';
import MissingInfoModal from 'components/modals/MissingInfoModal';
import ModalCustomer from 'components/modals/ModalCustomers';
import MainLayout from 'components/root/MainLayout';
import SplashScreen from 'components/root/SplashScreen';

import DraftList from './DraftList';
import IssuedList from './IssuedList';
import * as S from './styles';

const Home: FC = () => {
  const navigate = useNavigate();

  const {
    authLoading,
    userHasRequiredInfoFilled,
    currentBusiness,
    currentRole,
    reload,
    user,
  } = useAuth();
  const { clearAll } = useCerts();
  const { t } = useTranslation();

  const [showCustomerModal, setShowCustomerModal] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState<CertTemplate>();
  const [showVerify, setShowVerify] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [recentCerts, setRecentCerts] = useState<CertData[]>([]);
  const [issuedCerts, setIssuedCerts] = useState<IssuedCertData[]>([]);
  const [templates, setTemplates] = useState<CertTemplate[]>([]);
  const [counters, setCounters] = useState<DashBoardCounters>();
  const canCreate = [RoleEnum.SOLO, RoleEnum.LEAD].includes(currentRole);

  useEffect(() => {
    if (!currentBusiness) return;
    setIsLoading(true);
    apiListDashboardsInfo()
      .then(
        ({
          certificateData,
          certificateTemplate,
          counters,
          issuedCertificateData,
        }) => {
          certificateData.sort(sortCertificateDataByLastUpdated);
          setRecentCerts(certificateData);
          setTemplates(certificateTemplate);
          setCounters(counters);
          issuedCertificateData.reverse();
          setIssuedCerts(issuedCertificateData);
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentBusiness]);

  useEffect(() => {
    clearAll();
  }, [currentBusiness]);

  useEffect(() => {
    reload({ reloadWorkspaces: true });
  }, []);

  const newTemplate = (template: CertTemplate, customerId: string) => {
    setIsLoading(false);
    navigate(`/manage-certificate/${template._id}`, {
      state: { customerId },
    });
  };

  const createButtonAction = (templateName: string) => {
    const selectedTemplate = templates.find(
      (temp) => temp.certificateName === templateName
    );
    const { hasAllRequiredInfo } = userHasRequiredInfoFilled();

    if (hasAllRequiredInfo && selectedTemplate) {
      setCurrentTemplate(selectedTemplate);
      setShowCustomerModal(true);
    } else {
      setShowVerify(true);
    }
  };

  return (
    <>
      {(authLoading || isLoading) && <SplashScreen />}
      {showVerify && <MissingInfoModal close={() => setShowVerify(false)} />}
      <MainLayout pageTitle={t('dashboard_title')}>
        <S.ScrollContainer>
          <S.HomeGrid>
            <S.SubHeaderGrid className="subHeader">
              <S.SubHeaderContainer>
                <span>
                  Welcome, <span>{user?.name ?? ''}</span>
                </span>
                {canCreate && (
                  <DropdownButton
                    handleAction={(option) => {
                      createButtonAction(option);
                    }}
                    label={'New certificate'}
                    options={templates.flatMap((t) => t.certificateName ?? '')}
                  />
                )}
              </S.SubHeaderContainer>
            </S.SubHeaderGrid>
            <S.CountersGrid className="counters">
              <CardDefault>
                <S.CountCard>
                  <span>{t('dashboard_issued_certificates')}</span>
                  <span>{counters?.issued ?? 0}</span>
                </S.CountCard>
                {currentRole === RoleEnum.LEAD && (
                  <>
                    <CardDivider />
                    <S.CountCard>
                      <span>{t('dashboard_in_review_certificates')}</span>
                      <span>
                        {(counters?.['in-review'] ?? 0) +
                          (counters?.['pending-review'] ?? 0)}
                      </span>
                    </S.CountCard>
                  </>
                )}
                <CardDivider />
                <S.CountCard>
                  <span>{t('dashboard_draft_certificates')}</span>
                  <span>{counters?.draft ?? 0}</span>
                </S.CountCard>
              </CardDefault>
            </S.CountersGrid>
            <S.LastIssuedGrid className="lastIssued">
              <DraftList items={recentCerts} />
            </S.LastIssuedGrid>
            <S.RecentDraftsGrid className="recentDraft">
              <IssuedList items={issuedCerts} />
            </S.RecentDraftsGrid>
          </S.HomeGrid>
        </S.ScrollContainer>
        {showCustomerModal && currentTemplate && (
          <ModalCustomer
            onDashboard={currentTemplate._id}
            setOpenModal={setShowCustomerModal}
            handleSave={(customerId) => {
              newTemplate(currentTemplate, customerId);
            }}
          />
        )}
      </MainLayout>
    </>
  );
};

export default Home;
