import { FC, useRef, useState } from 'react';

import Icon from 'assets/images/iconset/dropdow_icon.svg';

import * as S from './styles';

interface DropdownButtonProps {
  label: string;
  options: string[];
  handleAction: (option: string) => void;
}

const DropdownButton: FC<DropdownButtonProps> = ({
  options,
  label,
  handleAction,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownTop, setDropdownTop] = useState(0);
  const readOnlyInputRef = useRef<HTMLButtonElement>(null);

  const openDropdow = () => {
    if (readOnlyInputRef.current) {
      const { top } = readOnlyInputRef?.current?.getBoundingClientRect() ?? {};
      setDropdownTop(window.innerHeight - top > 256 ? 5 : -261);
      setDropdownOpen(true);
    }
  };

  return (
    <S.DropddownWrapper>
      {dropdownOpen && (
        <S.DropdownOverlay
          onClick={() => {
            setDropdownOpen(false);
          }}
        />
      )}
      <S.DropddownButton
        className="button-primary"
        disabled={options.length === 0}
        ref={readOnlyInputRef}
        onClick={() => {
          openDropdow();
        }}
      >
        {label}
        <img src={Icon} alt="open" />
      </S.DropddownButton>

      {dropdownOpen && (
        <S.Dropdown top={dropdownTop}>
          {options?.map((option) => {
            return (
              <S.DropdownItem
                key={'dropdownOption-' + option}
                onClick={() => {
                  handleAction(option);
                  setDropdownOpen(false);
                }}
              >
                {option}
              </S.DropdownItem>
            );
          })}
        </S.Dropdown>
      )}
    </S.DropddownWrapper>
  );
};

export default DropdownButton;
